import React, { useState, useEffect, useRef } from "react";
import { Line } from 'react-chartjs-2';
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  CategoryScale,
  Chart,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js';

Chart.register(
  CategoryScale,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
);

const lineChartOptions = {
  maintainAspectRatio: false,
  hover: {
    intersect: true
  },
  plugins: {
    legend: {
      display: true,
    },
    tooltip: {},
    filler: {
      propagate: false
    }
  },
  scales: {
    x: {
      grid: {
        color: "rgba(0,0,0,0.0)"
      }
    },
    y: {
      ticks: {
        stepSize: 1000
      },
      display: true,
      borderDash: [3, 3],
      grid: {
        color: "rgba(0,0,0,0.0)"
      }
    }
  }
};

const AppChart = ({ title, data }) => {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    if (!chartRef.current || !data) return;

    const ctx = chartRef.current.ctx;
    const gradient = ctx.createLinearGradient(0, 0, 0, 225);
    gradient.addColorStop(0, "rgba(215, 227, 244, 1)");
    gradient.addColorStop(1, "rgba(215, 227, 244, 0)");

    const maxDays = data
      .map(m => m.length)
      .reduce((prev, cur) => Math.max(prev, cur), 0);

    const labels = [];
    for (let i = 0; i <= maxDays; i++) {
      labels.push(String(i + 1));
    }

    const _chartData = {
      labels,
      datasets: [{
        label: "This Month",
        fill: true,
        backgroundColor: gradient,
        borderColor: "#3B7DDD",
        data: data[2].map(({ value }) => String(value))
      }, {
        label: "Last Month",
        fill: true,
        backgroundColor: gradient,
        borderColor: "#28A745",
        data: data[1].map(({ value }) => String(value))
      }, {
        label: "2 Months Ago",
        fill: true,
        backgroundColor: gradient,
        borderColor: "#6C757D",
        data: data[0].map(({ value }) => String(value))
      }]
    };

    setChartData(_chartData);

  }, [chartRef.current, data]);

  return (
    <Grid container spacing={3}>
      <Grid item>
        <Typography variant="h4">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Line
          ref={chartRef}
          options={lineChartOptions}
          data={chartData}
        />
      </Grid>
    </Grid>
  );
}

export default AppChart;