import React from 'react';
export default {
  application: {
    offer: {
      amount: {
        conditional: (value) => {
          return (( value < 15000) || value > 1500000)
        },
        errorMessage: `Please enter a value between $10,000 and $1,500,0000`
      }
    }
  },
  disclosure: {
    text: (
        <>
          <p>
            IMPORTANT! In order to comply with California Law SB1235 these Offers of Financing are
            provided to you for discussion purposes ONLY and cannot be communicated in writing to the
            California Merchant unless two (2) or more financing options are sent together and at the
            same time or if the loan amount is greater than $500,000.  If you send a written
            communication to a California Merchant that contains a single offer of financing without
            also sending our system generated Disclosure document (by selecting ‘Generate Disclosure’),
            IOU Financial will be required to discontinue doing business with you. Once the Merchant has
            selected their preferred option, you must ensure that they receive a Disclosure document by
            selecting ‘Generate Disclosure’ beneath the selected option.
          </p>
          
          <p> I have read and understand the requirements outlined above. </p>
        </>
      )
  }
}