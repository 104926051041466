import React, { useEffect, useState } from "react";
import { Box, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Carousel from 'react-material-ui-carousel';
import { useGetAPI } from "../../../../shared/useAPI";
import InfoModal, { defaultStyles } from "../modals/InfoModal";

const CAROUSEL_INDICATOR_HEIGHT = 21 /* indicator height */ + 10 /* top margin */;

const styles = (theme) => ({
  paper: {
    ...defaultStyles(theme).paper,
    height: "80%",
  },
})
const useStyles = makeStyles(styles)

export const SampleDocumentsModalLink = () => {
  const classes = useStyles()
  const [open, setOpen] = useState(false);

  /* Need to set height properly to prevent carousel from scrolling vertically */
  const [carouselHeight, setCarouselHeight] = useState(0);

  const { data: documents, callAPI } = useGetAPI('/api/internal/v1/mapping/sample_documents')

  useEffect(() => {
    if (!documents) {
      callAPI()
    }
  }, [documents])

  const onOpen = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setOpen(true)
  }

  const onClose = () => setOpen(false)

  return (
    <>
      {!!documents?.length &&
        (<Link variant="caption" onClick={onOpen}>view sample documents</Link>)}
      <InfoModal title="Sample Quick Submit Applications" open={open} onClose={onClose} classes={classes}>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <div ref={(el) => { if (el) setCarouselHeight(el.clientHeight) }} style={{ flexGrow: 1 }}>
            {!documents?.length ? "No sample documents available" : (!!carouselHeight &&
              <Carousel
                animation="slide"
                autoPlay={false}
                navButtonsAlwaysVisible={true}
                cycleNavigation={false}
                swipe={false /* disabling swipe to fix an unexpected image slide in issue */}>

                {documents.map((src, index) => (
                  <Box
                    key={index}
                    sx={{
                      backgroundImage: `url('${src}')`,
                      backgroundPosition: 'center center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'contain',
                      width: '100%',
                      height: `${carouselHeight - CAROUSEL_INDICATOR_HEIGHT}px`,
                    }}
                    title={`Sample Application ${index + 1}`}
                  />
                ))}
              </Carousel>
            )}
          </div>
        </div>
      </InfoModal>
    </>
  )
}
