import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  LinearProgress,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4, 4),
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

export default function ProcessingApplicationModal() {
  const classes = useStyles();

  return (
    <Dialog
      classes={classes}
      open={true}
      fullWidth={true}
      maxWidth="md"
      aria-describedby="processing-application-modal"
    >
      <DialogContent>
        <DialogContentText id="processing-application-modal">
          Please allow up to 2 minutes for your credit application to be submitted
          <br /><br />
        </DialogContentText>
        <LinearProgress />
      </DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>
  );
};
