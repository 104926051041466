export default {
  "SubmissionError(551)": {
    error: "Your application cannot be submitted at this time."
  },
  "SubmissionError(552)": {
    error: "Your application cannot be submitted at this time.",
  },
  "CannotChangeConflictingSubmission": {
    error: "There is a conflicting application on the platform."
  },
  "ConflictSubmissionUpdateError": {
    error: "There is a conflicting application on the platform."
  },
  "PreQualificationError": {
    error: "The pre-qualification failed.",
    summary: "Failed Merchant Pre-Qualification"
  },
  "GuarantorPreQualificationError": {
    error: "The pre-qualification for the primary guarantor has failed.",
    summary: "Failed Guarantor Pre-Qualification"
  },
  "CreditPullError": {
    error: "The pre-screen has failed.",
    summary: "Credit Report Error"
  },
  "PreScreenDecisionRejected": {
    error: "There was a problem with the credit pull.",
    summary: "Failed Credit Pre-Screen"
  },
  "CreditPullRequestTooLongError": {
    error: "The credit request timed-out.",
    summary: "Credit Report Error"
  },
  "CompanyBlocked": {
    error: "Your application cannot be submitted at this time."
  },
  "NotAllowed": {
    error: "Your application could not be submitted at this time."
  },
  "CreditPreScreenFailed": {
    error: "Your application cannot be submitted because this merchant does not meet IOU Financial’s minimum credit requirement.",
    summary: "Failed Credit Pre-Screen"
  },
  "CreditFileLocked": {
    error: "Your application cannot be submitted because the credit file is locked.",
    summary: "Locked Credit File"
  },
  "CreditReportNotFound": {
    error: "Your application cannot be submitted because the credit report cannot be checked at this time.",
    summary: "Credit Report Error"
  },
  "GatewayTimeout": {
    error: "Your application request has timed out. The application may have been submitted successfully. Please check the Dashboard in 10 minutes.",
    summary: "System Timeout"
  },
  "SubmissionTimeout": {
    error: "Your application request has timed out. The application may have been submitted successfully. Please check the Dashboard in 10 minutes.",
    summary: "System Timeout"
  }, 
  "EINMatchSSN": {
    error: "NOTE: The EIN entered matches the SSN of a guarantor."
  },
  "SSNMatchEIN": {
    error: "NOTE: The SSN entered is the same as the company EIN."
  },
  "check-loan-above-amount": {
    summary: "Loan Amount Above State Minimum"
  },
  "check-loan-below-amount": {
    summary: "Loan Amount Below State Minimum"
  },
  "check-credit-conflict": {
    summary: "Credit Report Error"
  },
  "check-conflicts": {
    summary: "Conflicting Application"
  },
  "check-flagged-merchant": {
    summary: "Flagged Merchant"
  },
  "check-guarantors": {
    summary: "Flagged Guarantor"
  },
  "check-guarantors-ownership": {
    summary: "Invalid Ownership Percentage"
  },
  "check-prohibited-states": {
    summary: "Merchant In Prohibited State"
  },
  "check-sole-proprietor": {
    summary: "Sole Proprietor Error"
  },
  "check-ssn": {
    summary: "Invalid SSN"
  },
  "check-zipcodes": {
    summary: "Invalid Zip Code"
  },
  "check-valid-loan-conditions": {
    summary: "Invalid Amount for 24 Month Term"
  },
  "check-valid-term": {
    summary: "Invalid Term"
  },
  "check-valid-age": {
    summary: "Invalid Age"
  },
  "default": {
    error: "An unexpected error occurred while submitting your application.",
    summary: "Unexpected Error"
  },
}