import { useCallback, useState } from 'react';
import { fetchRecommendations as fetchRecommendations } from '../smartyAddressAutocomplete';
import { useNewApplicationStore } from '../../../../shared/NewApplication';

function compact(arr) {
  return arr.filter((x) => x);
}

function buildRecommendationsPart(recommendations, currentAddress, type) {
  if (!recommendations.length) return;
  return { type, currentAddress, recommendations };
}

function buildAddressLineFromAddressValues(values) {
  const { civic, street, unit, city, province, zip } = values;
  return compact([
    civic,
    street,
    unit,
    compact([city, province]).join(', '),
    zip
  ]).join(' ')
}

async function buildRecommendations(values) {
  let suggestions = (await fetchRecommendations(values)) || [];
  let currentAddress;
  if (suggestions.length) {
    currentAddress = buildAddressLineFromAddressValues(values);
    suggestions = suggestions.filter(
      ({ description }) => !matchAddress(description, currentAddress)
    );
  }
  return { suggestions, currentAddress };
}

function normalizeAddress(addr) {
  return addr.replaceAll(/ (apt|dept|ste|ave|blvd|cir|ct|dr|hwy|ln|pkwy|sq|st|trl)\. /gi, " $1 ");
}

function matchAddress(addr1, addr2) {
  return normalizeAddress(addr1) === normalizeAddress(addr2);
}

export function useAddressRecommendation() {
  const [recommendations, setRecommendations] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setInitialValues } = useNewApplicationStore();

  const getAddressRecommendations = useCallback(async (values) => {
    setLoading(true);
    setRecommendations([]);

    const { company_address, user: { owners_attributes } } = values;
    try {
      const {
        suggestions: companySuggestions,
        currentAddress
      } = await buildRecommendations(company_address);

      if (companySuggestions.length) {
        setRecommendations([
          buildRecommendationsPart(companySuggestions, currentAddress, 'company')
        ]);
      }

      if (!owners_attributes?.length) return;

      const promises = owners_attributes.map(
        async ({ address_attributes }, index) => {
          if (!address_attributes?.street) return;

          const { suggestions: userSuggestions, currentAddress } = await buildRecommendations(address_attributes);
          if (!userSuggestions.length) return;

          return {
            ...buildRecommendationsPart(userSuggestions, currentAddress, 'user'),
            index
          };
        }
      );

      const userRecos = compact(await Promise.all(promises));
      if (userRecos.length) {
        setRecommendations((recos) => [...recos, ...userRecos]);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const clearRecommendations = useCallback(() => {
    setRecommendations([]);
  }, []);

  const updateAddress = useCallback((type, address, index) => {
    if (type === 'company') {
      setInitialValues((values) => ({ ...values, company_address: address }));
    } else if (type === 'user') {
      setInitialValues((values) => {
        let newValues = values;
        const { user } = values;
        if (index < user.owners_attributes.length) {
          user.owners_attributes[index].address_attributes = address;
          newValues = { ...values, user };
        }
        return newValues;
      });
    }
  }, [setInitialValues]);

  return {
    getAddressRecommendations,
    recommendations,
    loading,
    clearRecommendations,
    updateAddress,
  }
}