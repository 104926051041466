import { useEffect, useState } from 'react';

export default function useDebouncedStateEffect(effect, delay) {
  const [value, setValue] = useState();

  useEffect(
    () => {
      const handler = setTimeout(() => {
        effect(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay]
  );

  return setValue;
}