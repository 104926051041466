import React, { useCallback, useState } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';

export default function SearchComponent({ onSearch }) {
  const [search, setSearch] = useState('');
  const _onSearch = useCallback(debounce(onSearch, 500), [onSearch]);

  return (
    <TextField
      placeholder="Tax ID"
      inputProps={{ 'aria-label': 'search' }}
      InputProps={{
        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
      }}
      value={search}
      onChange={(e) => {
        setSearch(e.target.value);
        _onSearch(e.target.value);
      }}
    />
  );
}