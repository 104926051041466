import React from "react";
import { IconButton, InputAdornment } from "@mui/material";
import { WarningOutlined } from "@mui/icons-material";
import { FieldMaskWrapper } from "./FieldHelpers";
import { useNewApplicationStore } from "../../../../shared/NewApplication";

export default function EINTextField({
  values,
  handleChange,
  handleFocus,
  handleBlur,
  appError,
}) {
  const { setConflictResult } = useNewApplicationStore();
  let InputProps = undefined;
  if (appError) {
    InputProps = {
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="View issue with application"
            edge="end"
            disableRipple={true}
            onClick={() => setConflictResult({ error: appError })}
          >
            <WarningOutlined color="error" />
          </IconButton>
        </InputAdornment>
      )
    }
  }

  return (
    <FieldMaskWrapper
      type="tel"
      id="companyCpr"
      formKey="company.cpr"
      inputMask="99-9999999"
      placeholder="Company Tax ID"
      values={values}
      label="Business Tax ID (EIN)"
      changeHandler={handleChange}
      blurHandler={handleBlur}
      focusHandler={(event) => {
        handleFocus(event);
        event.target.select();
      }}
      InputProps={InputProps}
      required
    />
  )
}