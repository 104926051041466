import { useEffect, useState } from 'react'
import { useGetAPI } from '../../../../../shared/useAPI'
import OfferModel from "../../../../../models/Offer"

export default function useOfferDetails(offerId) {
  const [offer, setOffer] = useState();
  const [showDisclosure, setShowDisclosure] = useState(false);
  const { callAPI: fetchOffer } = useGetAPI(`/api/internal/v1/offers/${offerId}`)
  const {
    data: conditionsData,
    loading: loadingConditions,
    callAPI: fetchConditions
  } = useGetAPI(`/api/internal/v1/offers/${offerId}/conditions`)
  const {
    data: documentsData,
    loading: loadingDocuments,
    callAPI: fetchDocuments
  } = useGetAPI(`/api/internal/v1/offers/${offerId}/documents`)

  useEffect(() => {
    fetchOffer().
      then((resp) => {
        const offerModel = new OfferModel(resp.data.offer)
        setOffer(offerModel)
        if (offerModel.showDisclosure) {
          const summary = offerModel.summary;
          setShowDisclosure( 
            summary && !summary.closedStatus &&
            !summary.renewalStatus && (!!offerModel.is_express || !!offerModel.underwriting_complete_time)
          )
        }
      })
    fetchConditions()
    fetchDocuments()
  }, [])

  return {
    offer,
    showDisclosure,
    setShowDisclosure,
    conditionsData,
    loadingConditions,
    documentsData,
    loadingDocuments
  }
}