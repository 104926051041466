import React from "react";
import InfoModalLink from "./InfoModaLink";

const GuarantorInformationLinkModal = () =>
  <InfoModalLink title="Guarantor Information">
    <p>The guarantor pledges to cover the loan with their own assets.</p>
    <p>For any loan application, the guarantor must have at least 80% ownership of the business. Usually, the merchant is the business owner, and by default is also the guarantor.</p>
    <p>For businesses that are owned by more than one person, there can be more than one guarantor. To satisfy the guarantor requirement, the indicated guarantors list must add up to at least 80% ownership of the business.</p>
  </InfoModalLink>

export default GuarantorInformationLinkModal;
