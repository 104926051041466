import React from "react"
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { Delete } from "@mui/icons-material";

function formatFileSize(bytes, decimalPoint) {
  if (bytes === 0) return "0 Bytes";
  const k = 1000;
  const dm = decimalPoint || 2;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

const FileListItem = ({
  file,
  index,
  removeFile,
  showIcon = true
}) => (
  <ListItem>
    {showIcon && (
      <ListItemAvatar>
        <Avatar>
          <Typography>{file.name}</Typography>
        </Avatar>
      </ListItemAvatar>
    )}
    <ListItemText
      primary={file.name}
      secondary={formatFileSize(file.size, 0)}
      sx={{ overflowWrap: "anywhere" }}
      />
    <IconButton edge="end" onClick={() => removeFile(index)} size="large">
      <Delete />
    </IconButton>
  </ListItem>
);

export default FileListItem
