import React from "react";
import { format as _timeAgo } from "timeago.js";
import {
  Box,
  Paper,
  Skeleton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

const OfferConditions = ({ loading, conditions }) => {
  return loading ? (<SectionSkeleton />) : (
    <Box mb={3}>
      <Paper elevation={0} className="border-custom">
        <Box sx={{ px: 7, pt: 2, pb: 5 }}>
          <Typography
            variant="h2"
            sx={{
              marginTop: 1,
              marginBottom: 1,
            }}
          >Conditions</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="bold secondary">Title</TableCell>
                  <TableCell className="bold secondary">Body</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!!conditions?.length && conditions.map(({ id, title, body }) => (
                  <TableRow key={id}>
                    <TableCell>{title}</TableCell>
                    <TableCell>{body}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </Box>
  )
}

const SectionSkeleton = () => {
  return (
    <Box>
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
      <Skeleton />
    </Box>
  );
}

export default OfferConditions;