import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { DateTime } from 'luxon';
import { useGetAPI } from '../../../../shared/useAPI';
import AppChart from './AppChart';
import { Title } from "../../styled";

const Scorecard = () => {
  const [numAppsSubmitted, setNumAppsSubmitted] = useState(null);
  const [amtAppsSubmitted, setAmtAppsSubmitted] = useState(null);
  const [numAppsOffered, setNumAppsOffered] = useState(null);
  const [amtAppsOffered, setAmtAppsOffered] = useState(null);
  const [numAppsClosed, setNumAppsClosed] = useState(null);
  const [amtAppsClosed, setAmtAppsClosed] = useState(null);
  const { callAPI } = useGetAPI("/api/internal/v1/scorecard/metrics");

  useEffect(async () => {
    let dt = DateTime.now().minus({ months: 2 }).startOf('month');
    let start_date = dt.toISODate();
    let end_date = dt.endOf('month').toISODate();
    let resp = await callAPI({ params: { start_date, end_date } });
    const metrics = [resp.data.brokerScorecardMetrics];

    dt = DateTime.now().minus({ months: 1 }).startOf('month');
    start_date = dt.toISODate();
    end_date = dt.endOf('month').toISODate();
    resp = await callAPI({ params: { start_date, end_date } });
    metrics.push(resp.data.brokerScorecardMetrics);

    start_date = DateTime.now().startOf('month').toISODate();
    end_date = DateTime.now().toISODate();
    resp = await callAPI({ params: { start_date, end_date } });
    metrics.push(resp.data.brokerScorecardMetrics);

    setNumAppsSubmitted(metrics.map(m => m['numAppsSubmittedDays']));
    setAmtAppsSubmitted(metrics.map(m => m['amtAppsSubmittedDays']));
    setNumAppsOffered(metrics.map(m => m['numAppsOfferedDays']));
    setAmtAppsOffered(metrics.map(m => m['amtAppsOfferedDays']));
    setNumAppsClosed(metrics.map(m => m['numAppsClosedDays']));
    setAmtAppsClosed(metrics.map(m => m['amtAppsClosedDays']));
  }, []);

  return (
    <Grid container spacing={5}>
      <Grid container item xs={12} md={12} spacing={5}>
        <Grid container item xs={12} justifyContent="space-between">
          <Grid item>
            <Title gutterBottom>Broker Scorecard</Title>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={6}>
          <AppChart title="# Submitted" data={numAppsSubmitted} />
        </Grid>
        <Grid container item xs={12} md={6}>
          <AppChart title="$ Submitted" data={amtAppsSubmitted} />
        </Grid>
        <Grid container item xs={12} md={6}>
          <AppChart title="# Offered" data={numAppsOffered} />
        </Grid>
        <Grid container item xs={12} md={6}>
          <AppChart title="$ Offered" data={amtAppsOffered} />
        </Grid>
        <Grid container item xs={12} md={6}>
          <AppChart title="# Closed" data={numAppsClosed} />
        </Grid>
        <Grid container item xs={12} md={6}>
          <AppChart title="$ Closed" data={amtAppsClosed} />
        </Grid>

      </Grid>
    </Grid>
  );
};

export default Scorecard;
