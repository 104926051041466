import { Box, Grid, Tab } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Typography from "@mui/material/Typography";
import React, { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import { DateTime } from "luxon";
import DashboardMetrics from "./DashboardMetrics";
import AccountExecutiveInfo from "../../shared/AccountExecutiveInfo/AccountExecutiveInfo";
import ApplicationsList from "./ApplicationsList";
import SavedApplicationsList from "./SavedApplicationsList";
import DealsInPlay from "./DealsInPlay";
import ExpressDeals from "./ExpressDeals";
import Alert from '@mui/material/Alert';
import { useAuth } from "../../../../context/context";
import { Title } from "../../styled";
import ahoy from "ahoy.js";

const useStyles = makeStyles((theme) => ({
  fullWidth: { width: '100%' },
  section: {
    marginBottom: '1.8em',
  },
  tabPanelRoot: {
    paddingLeft: 0,
    paddingRight: 0,
  }
}));

const Dashboard = () => {
  const defaultDateRangeRef = useRef([]);
  const [dateRange, setDateRange] = useState([]);
  const [metric, setMetric] = useState("express");
  const [tab, setTab] = useState("deals");
  const appListRef = useRef();
  const classes = useStyles();
  const { user } = useAuth();

  useEffect(() => {
    try {
      const initEndDate = DateTime.now().toISODate();
      const initStartDate = DateTime.now().startOf('month').toISODate();
      const range = [initStartDate, initEndDate];
      defaultDateRangeRef.current = range;
      setDateRange(range);
    } finally {
      ahoy.track("Broker Dashboard Visit", user);
    }
  }, []);

  const handleChange = (_, newValue) => setTab(newValue);

  const setMetricAndResetDateRange = (metric) => {
    setMetric(metric);
    setDateRange(defaultDateRangeRef.current);
    const listEl = appListRef.current;
    if (listEl) {
      listEl.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  };

  return (

    <Grid container direction="column">

      <Grid item className={classes.section}>
        <Alert severity="info" className={classes.alert}>
          Data displayed may be delayed by a few minutes
        </Alert>
      </Grid>

      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Title mb="2">My Loan Dashboard</Title>
            <Box mt={1} mb={2}>
              <Typography variant="h2" className="secondary bold italic">
                Welcome, {user.name}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <AccountExecutiveInfo />
          </Grid>
        </Grid>
      </Grid>

      <Grid item className={classes.section}>
        <DashboardMetrics dateRange={defaultDateRangeRef.current} onMetricSelected={setMetricAndResetDateRange} />
      </Grid>

      <Grid item className={classes.section}>
        <DealsInPlay visible={false} dateRange={defaultDateRangeRef.current} onMetricSelected={setMetricAndResetDateRange} />
        <ExpressDeals dateRange={defaultDateRangeRef.current} onMetricSelected={setMetricAndResetDateRange} />
      </Grid>

      <Grid item className={`tabs-container ${classes.section}`}>
        <TabContext value={tab}>
          <Box className={classes.fullWidth} sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList className="tab-list" indicatorColor="red" value={tab} onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="View Deals" value="deals" />
              <Tab label="View Saved Apps" value="saved-apps" />
            </TabList>
          </Box>
          <TabPanel ref={appListRef} value="deals" className={`tab-panel ${classes.fullWidth}`} classes={{ root: classes.tabPanelRoot }}>
            <ApplicationsList
              dateRange={dateRange}
              metric={metric}
              onMetricSelected={setMetric}
              onDateRangeChange={setDateRange}
            />
          </TabPanel>
          <TabPanel value="saved-apps" className={classes.fullWidth} classes={{ root: classes.tabPanelRoot }}>
            <SavedApplicationsList />
          </TabPanel>
        </TabContext>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
