import { useCallback, useContext, useEffect, useState } from "react";
import { useGetAPI, usePatchAPI } from "./useAPI";
import { RootStoreContext } from "../context/rootStoreContext"

export function useUserSettings() {
  const { callAPI: loadSettingsAPI, loading } = useGetAPI('/api/internal/v1/user/settings');
  const { callAPI: updateSettigsAPI } = usePatchAPI('/api/internal/v1/user/settings');
  const { userSettings, setUserSettings } = useContext(RootStoreContext);

  // This is a bit weird, but since the root store context still leverages mobx,
  // we need to do this
  const [_userSettings, _setUserSettings] = useState();

  const setUserSetting = useCallback(async (settingName, value) => {
    if (typeof value === 'undefined') {
      value = true;
    }
    const response = await updateSettigsAPI({ [settingName]: value });
    if (response.status === 200) {
      setUserSettings(response.data);
      _setUserSettings(response.data);
    } else {
      console.error("Error in setUserSetting:", response);
    }
  }, [updateSettigsAPI, setUserSettings]);

  useEffect(async () => {
    if (!userSettings) {
      const response = await loadSettingsAPI()
      if (response.status === 200) {
        setUserSettings(response.data);
        _setUserSettings(response.data);
      } else {
        console.error("Error in loadSettings:", response);
      }
    }
  }, []);

  return {
    userSettings: userSettings || _userSettings || {},
    loaded: !!(userSettings || _userSettings),
    loading,
    setUserSetting,
  };
}