import React from "react";
import {
  Grid,
  TableCell,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { DateTime } from "luxon";
import { date } from "../../../../shared/format";

const useStyles = makeStyles((_theme) => ({
  name: {
    fontWeight: "bold",
  },
  status: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  cell: {
    paddingTop: "2em",
    paddingBottom: "3em",
  }
}));

const ConflictRow = ({ row }) => {
  const classes = useStyles();
  const resubmit = DateTime.fromISO(row.credit_score_date).plus({ days: 30 });

  return (
    <>
      <TableCell component="th" scope="row" classes={{ root: classes.cell }}>
        <Grid container direction="column">
          <Grid item className={classes.name}>
            Broker: {row.broker_name}
          </Grid>
          <Grid item>
            Tax ID: {row.merchant_ein}
          </Grid>
          <Grid item>
            Offer ID: {row.id}
          </Grid>
        </Grid>
      </TableCell>
      <TableCell classes={{ root: classes.cell }}>
        <Grid container direction="column">
          <Grid item>
            Credit pulled: {date(row.credit_score_date)}
          </Grid>
          <Grid item>
            Resubmit: {resubmit.toLocaleString(DateTime.DATE_SHORT)}
          </Grid>
        </Grid>
      </TableCell>
      <TableCell classes={{ root: classes.cell }}>
        <Grid container direction="column">
          <Grid item>
            AE: {row.account_executive_name}
          </Grid>
          <Grid item className={classes.status}>
            Status: {row.status}
          </Grid>
        </Grid>
      </TableCell>
    </>
  )
}

export default ConflictRow;