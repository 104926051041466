import React from 'react';
import { TreeView, TreeItem } from "@mui/lab";
import { ChevronRight, ExpandMore } from "@mui/icons-material";

export default function DebugView({ diagnostics }) {
  return <TreeView
    aria-label="Textract Diagnostics"
    defaultCollapseIcon={<ExpandMore />}
    defaultExpandIcon={<ChevronRight />}
    sx={{ flexGrow: 1, maxWidth: 400, marginTop: '2em' }}
  >
    <TreeItem nodeId="value-root" label="values">
      {Object.keys(diagnostics.values).map((key, i) =>
        <TreeItem key={`value-${i}`} nodeId={`value-${i}`} label={key}>
          {diagnostics.values[key].map((item, j) =>
            <TreeItem key={`value-${i}-${j}`} nodeId={`value-${i}-${j}`} label={item.length === 0 ? "<blank>" : item} />)}
        </TreeItem>
      )}
    </TreeItem>

    <TreeItem nodeId="position-root" label="positions">
      {Object.keys(diagnostics.positions).map((key, i) =>
        <TreeItem key={`position-${i}`} nodeId={`position-${i}`} label={key}>
          {diagnostics.positions[key].map((item, j) =>
            <TreeItem key={`position-${i}-${j}`} nodeId={`position-${i}-${j}`} label={item.length === 0 ? "<blank>" : item.join(', ')} />)}
        </TreeItem>
      )}
    </TreeItem>
  </TreeView>
}