import React, { useState } from 'react';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import useDebouncedStateEffect from './useDebouncedStateEffect';

const RemoteAutocomplete = ({
  value,
  inputValue,
  onChange,
  onInputChange,
  label,
  placeholder,
  performSearch,
  optionLabelName = 'name',
  disableClearable,
  disabled,
  helperText,
  renderInput = (params) => <TextField {...params} disabled={disabled} helperText={helperText} />,
}) => {
  const [data, setData] = useState([]);
  const setQuery = useDebouncedStateEffect(async (query) => {
    const data = await performSearch(query);
    setData(data);
  }, 500);

  return (<Autocomplete
    value={value}
    disabled={disabled}
    inputValue={inputValue}
    onInputChange={(evt, newInputValue) => {
      setQuery(newInputValue);
      if (onInputChange) onInputChange(evt, newInputValue);
    }}
    onChange={(evt, newValue) => {
      if (typeof newValue === 'string') return;
      if (onChange) onChange(evt, newValue);
    }}
    filterOptions={(x) => x}
    options={data}
    getOptionLabel={(option) => {
      if (typeof option === 'string') {
        return (option === value) ? option : '';
      } else {
        return option[optionLabelName]
      }
    }}
    disableClearable={disableClearable}
    selectOnFocus
    handleHomeEndKeys
    freeSolo
    renderInput={(params) => renderInput({...params, label, placeholder})}
  />);
};

export default RemoteAutocomplete;