import React from "react"
import { Routes, Route, Navigate } from "react-router-dom"

import Layout, { NoAuthLayout } from "../App/Layout"
import RequireAuth from "./RequireAuth"
import RequireNoAuth from "./RequireNoAuth"

import Login from "../Login"
import ForgotPassword from "../ForgotPassword"
import ResetPassword from "../ResetPassword"
import Masquerade from "../App/Partner/Masquerade"
import Dashboard from "../App/Partner/Dashboard/Dashboard"
import OfferDetailsDialog from "../App/Partner/Dashboard/OfferDetailsDialog"
import Scorecard from "../App/Partner/Scorecard"
import NewApplication from "../App/Partner/Applications/NewApplication"
import StaffHome from "../App/Partner/staff/Home"
import PaymentCalculatorPage from "../App/Partner/PaymentCalculatorPage"


export default () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard" />} />

      <Route path="/login" element={
        <RequireNoAuth><Login /></RequireNoAuth>
      } />
      <Route path="/forgotPassword" element={
        <RequireNoAuth><ForgotPassword /></RequireNoAuth>
      } />
      <Route path="/resetPassword" element={
        <RequireNoAuth><ResetPassword /></RequireNoAuth>
      } />

      <Route element={
        <RequireAuth>
          <Layout />
        </RequireAuth>
      }>
        <Route path="/dashboard" element={<Dashboard />}>
          <Route path="app/:offerId" element={<OfferDetailsDialog />} />
        </Route>
        <Route path="/scorecard" element={<Scorecard />} />
        <Route path="/newApplication/renew/:offerId" element={<NewApplication />} />
        <Route path="/newApplication/:appId" element={<NewApplication />} />
      </Route>

      <Route element={
        <RequireAuth staff={true}>
          <Layout />
        </RequireAuth>
      }>
        <Route path="/staff/home" element={<StaffHome />} />
        <Route path="/staff/masquerade" element={<Masquerade />} />
      </Route>

      <Route element={
        <NoAuthLayout />
      }>
        {/* This route is used mainly for testing */}
        <Route path="/paymentCalculator" element={<PaymentCalculatorPage />} />
        <Route path="/paymentCalculator/:offerId" element={<PaymentCalculatorPage />} />
      </Route>
    </Routes>
  )
}