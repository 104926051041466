import { useCallback } from "react";
import { useGetAPI } from "../useAPI";

export function useCheckEINAPI() {
  const { callAPI } = useGetAPI();
  const checkEIN = useCallback(async (ein) => {
    const response = await callAPI({ url: `/api/internal/v1/merchants/${ein}` });
    return response.data;
  }, [callAPI]);

  return {
    checkEIN
  };
}