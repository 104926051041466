import React, { useState } from "react"
import { Box, Button, css, Grid } from "@mui/material"
import StatusHeader from "./StatusHeader"
import OfferSummary from "./OfferSummary"
import StatusDetails from "./StatusDetails"
import OfferConditions from "./OfferConditions"
import OfferDocuments from "./OfferDocuments"
import PaymentCalculator from "../../../../../PaymentCalculator"
import { useNavigate } from "react-router-dom"
import { useCreateAuthenticatedURLAPI } from "../../../../../../shared/useCreateAuthenticatedURLAPI"
import { fetchEnv } from "../../../../../../env"
import InfoModalLink from "../../../../shared/modals/InfoModaLink"
import DisclosureModal from "./DisclosureModal"
import { stateSpecificDisclosures } from "../../../Applications/stateValidations/allStateValidations"

function constructShareableURL(offer_id, signature, exp) {
  const host = fetchEnv('FIREBALL_SERVER_HOST')
  const basePath = fetchEnv('BASE_PATH')
  return `${host}${basePath}/paymentCalculator/${offer_id}?sig=${signature}&exp=${exp}`
}

const InfoLink = () =>
  <InfoModalLink title="Shareable Link">
    <p>
      This link can be used to share this payment calculator with other
      brokers. Anyone using this link will be able to view the payment
      calculator without having to login.
    </p>
    <p>This link is not intended for merchant use.</p>
  </InfoModalLink>

const CopyShareableLinkButton = ({ offerId }) => {
  const [showCopied, setShowCopied] = useState(false)
  const { createAuthenticatedURL } = useCreateAuthenticatedURLAPI(offerId)

  const copyShareableLink = (_event) => {
    createAuthenticatedURL().then(({ data: { signature, exp, offer_id } }) => {
      const url = constructShareableURL(offer_id, signature, exp)
      navigator.clipboard.writeText(url)
      setShowCopied(true)
      setTimeout(() => setShowCopied(false), 2000)
    })
  }

  return (
    <>
      <div style={{ marginRight: 5 }}>
        <InfoLink />
      </div>
      <Button
        className="button-outline"
        variant="contained"
        onClick={copyShareableLink}
        sx={{ position: 'relative' }}
      >
        <span style={{ visibility: showCopied ? 'hidden' : 'visible' }}>Shareable Offer</span>

        {/* Doing it this way to prevent button size change */}
        <span style={{
          display: showCopied ? 'block' : 'none',
          position: 'absolute',
        }}>Copied!</span>
      </Button>
    </>
  )
}

const OfferDetails = ({
  offer,
  showDisclosure,
  setShowDisclosure,
  loadingConditions,
  conditionsData,
  loadingDocuments,
  documentsData,
}) => {
  const navigate = useNavigate();
  const [showMoreOffersHint, setShowMoreOffersHint] = useState(false)

  if (!offer) {
    return <></>
  }

  return (
    <>
      <div style={{ filter: showDisclosure ? 'blur(.25rem)' : 'none' }}>
        <Grid container sx={css`
          padding-bottom: 30px;
          justify-content: center;
        `}>
          <StatusHeader offer={offer.summary} />
        </Grid>

        <OfferSummary loading={false} data={offer} showMoreOffersHint={showMoreOffersHint} />
        <StatusDetails offer={offer} />

        {(!offer.summary.closedStatus && !offer.summary.renewalStatus) && (
          <OfferConditions
            loading={loadingConditions}
            conditions={conditionsData?.conditions} />
        )}

        <OfferDocuments
          offerId={offer.id}
          loading={loadingDocuments}
          documents={documentsData?.documents} />

        {offer.summary.renewalStatus && (
          <Box mt={1}>
            <Button
              className="button-secondary m-t"
              variant="contained"
              onClick={() => navigate(`/newApplication/renew/${offer.id}`)}
            >
              Renew Loan
            </Button>
          </Box>
        )}

        {offer.summary &&
          !offer.summary.closedStatus &&
          !offer.summary.renewalStatus &&
          (!!offer.is_express || !!offer.underwriting_complete_time) && (
            <PaymentCalculator
              usaState={offer.merchant.state_province}
              offerId={offer.id}
              offerModel={offer}
              title={!!offer.is_express ? 'Express Loan Offers' : 'Loan Offers'}
              disclosureEnabled={offer?.showDisclosure}
              onTermsFound={setShowMoreOffersHint}
              headerComponent={<CopyShareableLinkButton offerId={offer.id} />}
            />
          )}
      </div>

      <DisclosureModal open={showDisclosure}
        onClose={() => setShowDisclosure(false)} >
          { stateSpecificDisclosures(offer?.merchant?.state_province) }
      </DisclosureModal>
    </>
  )
}

export default OfferDetails