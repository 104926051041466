import React from "react";
import InfoModalLink from "./InfoModaLink";

const BusinessDocsInformationLinkModal = () =>
  <InfoModalLink title="Business Documents">
    <p>Drag and drop the required documents (minimum three months of bank statements) into the Add Statements field, or click Add Statements and choose the documents from your computer.</p>
    <p>When you are finished, click Submit. The loan application is sent to IOU Financial for review.</p>
  </InfoModalLink>

export default BusinessDocsInformationLinkModal;
