import React from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

export const ConfirmationModal = ({ open, submitting, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-describedby="confirmation-modal"
    >
      <DialogContent>
        <DialogContentText id="confirmation-modal">
          Are you sure you want to submit decision?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={submitting} onClick={() => onClose(true) }>
          {submitting && <CircularProgress size={24} />}
          {!submitting && 'Submit'}
        </Button>
        <Button disabled={submitting} onClick={() => onClose(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

