import React, { useState } from 'react';
import { TextField } from '@mui/material';
import RemoteAutocomplete from '../../../../shared/RemoteAutoComplete';
import { fetchRecommendations, fetchStreetInfo } from '../../shared/smartyAddressAutocomplete';
import { getIn } from "formik";

const AddressAutoComplete = ({
  name,
  label,
  placeholder,
  value,
  onFocus,
  onBlur,
  form,
  setFieldValue,
  fieldPrefix,
}) => {
  const [input, setInput] = useState('');
  return (
    <RemoteAutocomplete
      label={label}
      placeholder={placeholder}
      value={value}
      inputValue={input}
      optionLabelName="description"
      disableClearable={true}
      onChange={async (_, v) => {
        const info = await fetchStreetInfo(v)
        setFieldValue(`${fieldPrefix}street`, info.street);
        setFieldValue(`${fieldPrefix}unit`, info.unit);
        setFieldValue(`${fieldPrefix}city`, info.city);
        setFieldValue(`${fieldPrefix}province`, info.province);
        setFieldValue(`${fieldPrefix}zip`, info.zip);
        setFieldValue(name, info.civic);
        setInput(info.civic);
      }}
      onInputChange={(_, input) => {
        setFieldValue(name, input);
        setInput(input)
        }
      }
      performSearch={async (query) => {
        if (!query?.length) return [];

        const result = await fetchRecommendations({ street: query })
        return result;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          className="formElement"
          variant="outlined"
          required={true}
          error={!!(form != null && getIn(form.touched, name) && getIn(form.errors, name))}
          helperText={form != null ? getIn(form.touched, name) && getIn(form.errors, name) : ""}
          InputLabelProps={{ shrink: true }}
          onFocus={onFocus}
          onBlur={(evt) => {
            if (input.length && input !== value) setFieldValue(name, input);
            if (onBlur) onBlur(evt)
          }}
          />
      )}
      />
  );
};

export default AddressAutoComplete;