import { usePostAPI } from "./useAPI";

export function useCreateAuthenticatedURLAPI(offer_id) {
  const {
    data,
    loading,
    callAPI: createAuthenticatedURL,
  } = usePostAPI(`/api/internal/v1/offers/${offer_id}/verifications`);

  return { data, loading, createAuthenticatedURL };
}