import React from "react";
import InfoModalLink from "./InfoModaLink";

const BusinessInformationLinkModal = () =>
  <InfoModalLink title="Business Information">
    <p>In this section, provide the essential details about the merchant's business. Some of the fields, such as Business DBA Name (the merchant's "doing business as" name) and Business Website, are not required. But to expedite the loan-application process, you should provide as much accurate information as possible.</p>
    <p>Before you proceed to the next section, sure that this business is not included in the prohibited-industries list.</p>
  </InfoModalLink>

export default BusinessInformationLinkModal;
