import { useCallback, useContext, useMemo, useState, useEffect } from "react";
import { fieldsGetter, validateEIN, validateEINMatchSSN, validateSoleGroup } from "../../components/App/Partner/Applications/validationHelper";
import { useCheckSolePropAPI } from "./useCheckSolePropAPI";
import { useCheckEINAPI } from "./useCheckEINAPI";
import { useModalQueue } from "../../components/App/shared/modals/queue";
import { useNewApplicationStore } from "./newApplicationContext";
import { get } from "lodash";
import { fetchEnv } from "../../env";
import { AuthStateContext } from "../../context/context";

class ValidationWrapper {
  constructor(fields, validate) {
    this._fields = fields;
    this._validate = validate;
  }

  changed(values) {
    const keyedValues = this._fields(values);
    const keys = Object.keys(keyedValues);

    if (keys.find((k) => keyedValues[k] === null || typeof keyedValues[k] === 'undefined')) {
      return true;
    }

    if (this.prevValues) {
      if (keys.length !== Object.keys(this.prevValues).length) return true;

      const mismatch = keys.find((key) => keyedValues[key] !== this.prevValues[key])
      if (!mismatch) return false;
    }

    return true;
  }

  async validate(values) {
    if (!this.changed(values)) return;
    const keyedValues = this._fields(values);
    const keys = Object.keys(keyedValues);

    // don't validate unless all keyed values are present
    if (keys.find((k) => keyedValues[k] === null || typeof keyedValues[k] === 'undefined')) {
      this.prevValues = null;
      return;
    }

    // don't re-run validation if values haven't changed
    if (this.prevValues) {
      const mismatch = keys.find((key) => keyedValues[key] !== this.prevValues[key])
      if (!mismatch) return;
    }

    this.prevValues = keyedValues;
    return await this._validate(values);
  }
}

function fieldsForEINMatchSSNCheck(values) {
  const fields = {
    'company.cpr': get(values, 'company.cpr'),
  }

  let pos = 0;
  let key = `user.ownersAttributes[${pos}].cpr`;
  let cpr = get(values, key);
  while (cpr) {
    fields[key] = cpr;
    pos += 1;
    key = `user.ownersAttributes[${pos}].cpr`;
    cpr = get(values, key);
  }
  return fields;
}

async function runValidations(validations, values) {
  for (let index = 0; index < validations.length; index++) {
    const validation = validations[index];
    const error = await validation.validate(values);
    if (error) return { error, index };
  }
  return {};
}

export function useLiveValidations() {
  const [errorResult, setErrorResult] = useState(null);
  const [validating, setValidating] = useState(false);
  const [latestValues, setLatestValues] = useState(null);
  const { setConflictResult,
          resetInitialValues,
          appSource,
  } = useNewApplicationStore();
  const { isMasquerading } = useContext(AuthStateContext);

  const einLookupValuesEnabled = fetchEnv('EIN_LOOKUP_VALUES_ENABLED');
  const { pushModal } = useModalQueue();

  const { checkSoleProp } = useCheckSolePropAPI();
  const { checkEIN } = useCheckEINAPI();

  /*
    Set up live validations to run. Validations are ran in order, and halted on
    first error. Each validation has 2 parts:

      1. A list of fields that are relevant to the validation. If any of these
         fields change, the validation will run.
      2. A function that takes the values and returns an error message if
         validation fails.
   */
  const validations = useMemo(() => [
    new ValidationWrapper(
      fieldsGetter('company.cpr'),
      (values) => validateEIN(checkEIN, values, (application) => {
        if(!appSource && einLookupValuesEnabled && isMasquerading && application?.company?.cpr) {
          resetInitialValues(application);
        }
      }),
    ),

    new ValidationWrapper(
      fieldsForEINMatchSSNCheck,
      validateEINMatchSSN,
    ),

    new ValidationWrapper(
      fieldsGetter('company.typeInc', 'companyAddress.province', 'company.cpr', 'offer.amount'),
      (values) => validateSoleGroup(checkSoleProp, values),
    )
  ], [checkSoleProp, checkEIN, appSource, einLookupValuesEnabled, isMasquerading, resetInitialValues]);

  const runLiveValidation = useCallback(async (values) => {
    if (validating) {
      setLatestValues(values);
      return;
    }

    setValidating(true);
    try {
      if (errorResult) {
        if (!validations[errorResult.index].changed(values)) return;

        setErrorResult(null);
      }

      const result = await runValidations(validations, values);
      if (result.error) {
        setErrorResult(result);
        pushModal({ id: 'ein-error', show: () => setConflictResult({ error: result.error }) });
      } else {
        setErrorResult(null);
      }
    } finally {
      setValidating(false);
    }
  }, [validating, errorResult, validations, setConflictResult, pushModal]);

  useEffect(() => {
    if (validating || !latestValues) return;

    // Re-run validation if values have changed while a previous validation
    // cycle was running
    const values = latestValues;
    setLatestValues(null);
    runLiveValidation(values);
  }, [validating])

  return {
    appError: errorResult?.error,
    runLiveValidation,
  };
}