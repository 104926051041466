import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useGetAPI } from "../../../../../../shared/useAPI";
import AppRow from "./AppRow";
import ConflictRow from "./ConflictRow";
import Search from "./Search";

export default () => {
  const [initializing, setInitializing] = useState(true);
  const [searching, setSearching] = useState(false);
  const [savedApplications, setSavedApplications] = useState([]);
  const [appsNextPage, setAppsNextPage] = useState();
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const { callAPI } = useGetAPI("/api/internal/v1/staff/applications");

  const loadMore = async () => {
    setLoadingNextPage(true)
    setAppsNextPage(null)
    const { data } = await callAPI({ url: appsNextPage })
    setSavedApplications((apps) => [...apps, ... data.data])
    if (data.metadata.next) {
      setAppsNextPage(data.metadata.next_url)
    }
    setLoadingNextPage(false)
  }

  const onLoad = async () => {
    setInitializing(true)
    const { data } = await callAPI()
    setInitializing(false)
    setSavedApplications(data.data)
    if (data.metadata.next) {
      setAppsNextPage(data.metadata.next_url)
    }
  }

  const onSearch = async (query) => {
    if (query?.length) {
      setSearching(true)
      setAppsNextPage(null)
      const { data } = await callAPI({ params: { query } });
      setSavedApplications(data.data);
    } else {
      setSavedApplications([]);
      onLoad();
    }
  }

  useEffect(() => { onLoad() }, []);

  useEffect(() => {
    if (!savedApplications) return;
    const tableRows = [];
    savedApplications.forEach((app) => {
      tableRows.push({
        key: `main-${app.id}`,
        type: 'main',
        row: app
      });
      if (app.error_code === 'check-conflicts') {
        tableRows.push({
          key: `conflict-${app.id}`,
          type: 'conflict',
          row: app.error_details.conflicting_offer,
        });
      }
    });
    setTableRows(tableRows);
  }, [savedApplications]);

  return <>
    {!initializing &&
      <>
        <Grid container flexDirection="column" alignItems="stretch" padding={2}>
          <Grid item>
            <Grid container justifyContent="space-between">
              <Typography variant="h3" className="secondary bold">
                Conflicting Applications
              </Typography>
              <Search onSearch={onSearch} />
            </Grid>
          </Grid>

          <Grid item>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="saved applications table">
                <TableBody>
                  {tableRows.map(({ key, type, row }) => (
                    <TableRow key={key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      {type === 'main' && <AppRow row={row} />}
                      {type === 'conflict' && <ConflictRow row={row} />}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </>
    }

    <Grid container flexDirection="column" alignItems="stretch" padding={2}>
      <Grid item>

        {(initializing || !tableRows || loadingNextPage) &&
          <CircularProgress color="inherit" />}

        {appsNextPage &&
          <Button className="button-primary"
                  fullWidth
                  type="button"
                  disabled={loadingNextPage}
                  onClick={loadMore}>
                  Load More
          </Button>}
      </Grid>
    </Grid>
  </>;
};

