import { useContext, useEffect } from "react";
import { useGetAPI } from "./useAPI";
import { RootStoreContext } from "../context/rootStoreContext";

export function useAccountExecutive() {
  const { accountExecutive, setAccountExecutive } = useContext(RootStoreContext);
  const { loading, callAPI } = useGetAPI('/api/internal/v1/account_executives');

  useEffect(async () => {
    if (!accountExecutive) {
      const response = await callAPI();
      setAccountExecutive(response.data);
    }
  }, []);

  return {
    accountExecutive,
    loading,
  };
}