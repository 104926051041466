import { Box, Button, Grid } from "@mui/material";
import { Field, getIn } from "formik";
import { observer } from "mobx-react";
import React from "react";
import StateAutocomplete from "../../shared/StateAutocomplete";
import AddressAutoComplete from "./AddressAutoComplete";
import { FieldMaskWrapper, FieldWrapper } from "./FieldHelpers";

const GuarantorInformation = observer(
  ({
    values,
    index,
    removeGuarantor,
    handleChange,
    handleFocus,
    handleBlur,
    setFieldValue,
    getFieldMeta,
  }) => {
    const addrPrefix = `user.ownersAttributes[${index}].addressAttributes.`;
    const provinceValue = `${addrPrefix}province`;

    return (
      <>
        <Grid item xs={12}>
          <Box className="dotted" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldWrapper
            id="guarantorFirstName"
            label="First Name"
            changeHandler={handleChange}
            focusHandler={handleFocus}
            blurHandler={handleBlur}
            values={values}
            placeholder="Guarantor's First Name"
            formKeyPrefix={`user.ownersAttributes[${index}].`}
            formKey="firstName"
            autoComplete="guarnatorFirstName"
            required
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FieldWrapper
            id="guarantorLastName"
            label="Last Name"
            changeHandler={handleChange}
            focusHandler={handleFocus}
            blurHandler={handleBlur}
            values={values}
            formKeyPrefix={`user.ownersAttributes[${index}].`}
            placeholder="Guarantor's Last Name"
            formKey="lastName"
            autoComplete="guarnatorLastName"
            required
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Field name={`${addrPrefix}civic`}>
            {({ form }) =>
              <AddressAutoComplete
                name={`${addrPrefix}civic`}
                value={getIn(values, `${addrPrefix}civic`)}
                label="Address Street Number"
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                form={form}
                placeholder="Address Street Number"
                setFieldValue={setFieldValue}
                fieldPrefix={addrPrefix}
                />
            }
          </Field>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FieldWrapper
            id="guarantorStreetName"
            label="Address Street Name"
            changeHandler={handleChange}
            formKeyPrefix={`user.ownersAttributes[${index}].`}
            focusHandler={handleFocus}
            blurHandler={handleBlur}
            values={values}
            placeholder="Address Street Name"
            formKey="addressAttributes.street"
            autoComplete="guarnatorStreetName"
            required
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FieldWrapper
            id="guarantorUnit"
            label="Apartment / Unit Number"
            changeHandler={handleChange}
            focusHandler={handleFocus}
            blurHandler={handleBlur}
            values={values}
            formKeyPrefix={`user.ownersAttributes[${index}].`}
            placeholder="Apartment Unit Number"
            formKey="addressAttributes.unit"
            autoComplete="guarnatorUnit"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FieldWrapper
            id="guarantorCity"
            label="City"
            changeHandler={handleChange}
            focusHandler={handleFocus}
            blurHandler={handleBlur}
            values={values}
            formKeyPrefix={`user.ownersAttributes[${index}].`}
            placeholder="City"
            formKey="addressAttributes.city"
            autoComplete="guarnatorCityName"
            required
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Field name="guarantorProvince">
            {({ form }) => {
              const value = getFieldMeta(provinceValue).value || ""; 
              return (
                <StateAutocomplete
                  form={form}
                  value={value}
                  required={true}
                  fieldName={provinceValue}
                  handleFocus={handleFocus}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                />
              )
            }}
          </Field>
        </Grid>

        <Grid item xs={12} sm={3}>
          <FieldWrapper
            type="tel"
            id="guarantorZip"
            label="ZIP Code"
            changeHandler={handleChange}
            focusHandler={handleFocus}
            blurHandler={handleBlur}
            values={values}
            inputProps={{ maxLength: 5 }}
            placeholder="ZIP Code"
            formKeyPrefix={`user.ownersAttributes[${index}].`}
            formKey="addressAttributes.zip"
            autoComplete="guarnatorZip"
            required
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FieldMaskWrapper
            type="tel"
            id="guarantorCell"
            label="Cell Phone Number"
            changeHandler={handleChange}
            formKeyPrefix={`user.ownersAttributes[${index}].`}
            focusHandler={handleFocus}
            blurHandler={handleBlur}
            values={values}
            placeholder="Cell Phone Number"
            formKey="phoneMobile"
            autoComplete="guarnatorCell"
            inputMask="(999)-999-9999"
            required
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FieldMaskWrapper
            type="tel"
            id="guarantorPhoneNumber"
            label="Home Phone Number"
            changeHandler={handleChange}
            focusHandler={handleFocus}
            blurHandler={handleBlur}
            formKeyPrefix={`user.ownersAttributes[${index}].`}
            values={values}
            placeholder="Home Phone Number"
            formKey="phone"
            autoComplete="guarnatorPhone"
            inputMask="(999)-999-9999"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FieldWrapper
            type="url"
            id="guarantorEmail"
            label="Email Address"
            changeHandler={handleChange}
            formKeyPrefix={`user.ownersAttributes[${index}].`}
            focusHandler={handleFocus}
            blurHandler={handleBlur}
            values={values}
            placeholder="Email Address"
            formKey="email"
            autoComplete="guarnatorEmail"
            required
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FieldMaskWrapper
            type="tel"
            id="guarantorCpr"
            inputMask="999-99-9999"
            placeholder="Social Security Number"
            formKeyPrefix={`user.ownersAttributes[${index}].`}
            label="Social Security Number"
            values={values}
            formKey="cpr"
            changeHandler={handleChange}
            focusHandler={handleFocus}
            blurHandler={handleBlur}
            required
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FieldWrapper
            id="guarnatorDOB"
            label="Date of Birth"
            changeHandler={handleChange}
            focusHandler={handleFocus}
            blurHandler={handleBlur}
            values={values}
            placeholder="MM"
            formKeyPrefix={`user.ownersAttributes[${index}].`}
            type="date"
            formKey="birthdate"
            autoComplete="guarantorDOB"
            required
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FieldWrapper
            type="tel"
            id="guarantorPercentage"
            label="Percentage Ownership"
            changeHandler={handleChange}
            focusHandler={handleFocus}
            blurHandler={handleBlur}
            formKeyPrefix={`user.ownersAttributes[${index}].`}
            values={values}
            placeholder="Percentage Ownership"
            formKey="ownerPercent"
            autoComplete="guarnatorPercentage"
          />
        </Grid>

        {index > 0 && (
          <Grid item xs={12} align="right">
            <Button className="button-danger" onClick={removeGuarantor}>
              Remove
            </Button>
          </Grid>
        )}
      </>
    );
  }
);

export default GuarantorInformation;
