import { useEffect } from "react";
import useAPI from "../useAPI";
import { useNewApplicationStore } from "./newApplicationContext";
import { useSubmitRenewal } from "./useSubmitRenewal";

export function useLoadSavedApplication(appId, offerId) {
  const {
    initialized,
    setLoading,
    resetInitialValues,
    clearInitialValues,
    setIndustryChecked,
    addAlert,
  } = useNewApplicationStore();

  const { callAPI } = useAPI('/api/internal/v1/applications');
  const { fetchRenewalValues } = useSubmitRenewal();

  useEffect(async () => {
    if (!initialized) return;

    if (appId && appId !== "new") {
      setLoading(true);
      try {
        const { data: { values, app_imported } } = await callAPI({ method: 'GET', url: `/api/internal/v1/applications/${appId}` });
        const industryChecked = (values.industry_checked === true);
        delete values.industry_checked;
        resetInitialValues(values, app_imported ? "textract" : "saved");
        setIndustryChecked(industryChecked);
      } finally {
        setLoading(false);
      }
    } else if (offerId) {
      setLoading(true);
      try {
        const renewalValues = await fetchRenewalValues(offerId);
        resetInitialValues(renewalValues, "renewal");
      } catch (err) {
        clearInitialValues();
        if (err?.response?.status === 403) {
          addAlert([{
            id: "renew-forbidden",
            severity: "error",
            message: "Renewal submission can only be performed by the original broker",
          }]);
        } else {
          console.log("Error loading renewable offer:", err)
        }
      } finally {
        setLoading(false);
      }
    } else {
      clearInitialValues();
    }
  }, [appId, initialized]);
}
