import { useCallback, useState, useContext, useMemo } from "react";
import { AuthStateContext } from "../context/context";
import { apiCall } from "./api";

export default function useAPI(method, url, options) {
  const [response, setResponse] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const { signOut } = useContext(AuthStateContext);

  const callAPI = useCallback(async (callOptions) => {
    let curOpts = { ...options } || {};
    if (callOptions) {
      curOpts = { ...curOpts, ...callOptions };
    }

    const _perform = async (url) => {
      try {
        const response = await apiCall(method, url, curOpts);
        setResponse(response);
        return response;

      } catch (err) {
        if (err.response?.status === 401) {
          signOut();
        } else {
          throw err;
        }
      }
    };

    setLoading(true);
    try {
      return await _perform(url);
    } catch (err) {
      console.warn("API error:", url, err);
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [url, options, signOut]);

  return { data: response?.data, loading, error, response, callAPI };
}

export function useGetAPI(url, options) {
  return useAPI('GET', url, options);
}

export function usePostAPI(url, data, options) {
  const opts = useMemo(() => ({ data, ...options }), [data, options]);
  const { callAPI, ...rest } = useAPI('POST', url, opts);
  const callPostAPI = useCallback((data, options) => {
    let opts = {};
    if (data) {
      opts = { data };
    }
    if (options) {
      opts = { ...opts, ...options };
    }
    return callAPI(opts);
  }, [callAPI]);
  return { callAPI: callPostAPI, ...rest };
}

export function usePatchAPI(url, data, options) {
  const opts = useMemo(() => ({ data, ...options }), [data, options]);
  const { callAPI, ...rest } = useAPI('PATCH', url, opts);
  const callPatchAPI = useCallback((data, options) => {
    let opts = {};
    if (data) {
      opts = { data };
    }
    if (options) {
      opts = { ...opts, ...options };
    }
    return callAPI(opts);
  }, [callAPI]);
  return { callAPI: callPatchAPI, ...rest };
}

export function useDeleteAPI(url, options) {
  const { callAPI, ...rest } = useAPI('DELETE', url, options);
  const callDeleteAPI = useCallback((options) => {
    let opts = {};
    if (options) {
      opts = { ...opts, ...options };
    }
    return callAPI(opts);
  }, [callAPI]);
  return { callAPI: callDeleteAPI, ...rest };
}