import { DateTime } from "luxon";
import parsePhoneNumber from "libphonenumber-js";
import { isUndefined } from "lodash";

const defaultOptions = {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
};

export function currency(number, options) {
  if (isUndefined(number) || number === null || isNaN(number)) return number;

  if (options) {
    options = { ...defaultOptions, ...options };
  } else {
    options = defaultOptions;
  }
  return new Intl.NumberFormat('en-US', options).format(number);
}

export function date(ISODateString) {
  return DateTime.fromISO(ISODateString).toLocaleString(DateTime.DATE_SHORT);
}

export function phoneNumber(str) {
  const pn = parsePhoneNumber(`+1${str}`);
  if (pn) {
    return pn.formatNational();
  } else {
    return ""
  }
}

export function percentage(number) {
  if (isUndefined(number) || number === null || isNaN(number)) return number;

  return new Intl.NumberFormat('en-US',
    { maximumFractionDigits: 2, style: 'percent' }
    ).format(number / 100)
}

export function rate(number) {
  if (isUndefined(number) || number === null || isNaN(number)) return number; 

  return new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(number);
}