import { useCallback } from "react";
import useAPI from "../useAPI";
import { useNewApplicationStore } from "./newApplicationContext";

export function canSaveApplication(values) {
  const {
    company: { firstName, cpr },
    offer: { amount }
  } = values;
  return firstName?.length && cpr?.length && amount?.length;
}

export function useSaveApplication(appId) {
  const { callAPI } = useAPI('/api/internal/v1/applications');

  const {
    setLoading,
    lastAppId,
    setLastAppId,
    industryChecked,
    hideAlert,
    addAlert,
    withTextract
  } = useNewApplicationStore();

  const currentAppId = lastAppId || appId || "new";
  const saveApplication = useCallback(async (values, options) => {
    const {
      status = 'saved',
      error_details,
      error_code,
      submission_uuid,
    } = (options || {});

    const {
      company: { cpr, firstName, email, phoneWork },
      offer: { amount },
    } = values;

    const data = {
      status,
      error_code,
      error_details,
      business_name: firstName,
      business_email: email,
      business_phone: phoneWork,
      ein: cpr,
      loan_amount: amount,
      app_imported: withTextract,
      submission_uuid,
      values: { ...values, industry_checked: industryChecked },
    };

    const method = (currentAppId !== "new") ? 'PATCH' : 'POST';
    const url = `/api/internal/v1/applications${(currentAppId !== "new") ? `/${currentAppId}` : ''}`;

    setLoading(true);
    hideAlert("saved-app");
    try {
      const response = await callAPI({ method, url, data });
      setLastAppId(response.data.id);
      addAlert([{
        id: "saved-app",
        severity: "success",
        canClose: true,
        message: "Application saved successfully. You can resume this application from the Dashboard."
      }]);
      return response;

    } finally {
      setLoading(false);
    }
  }, [callAPI, currentAppId, industryChecked]);

  return { saveApplication };
}
