import snakecaseKeys from "snakecase-keys";
import { buildLoanApplicationPayload } from "../../components/App/Partner/Applications/validationHelper";
import { usePostAPI } from "../useAPI";

export function useSubmitApplicationAPI() {
  const { callAPI } = usePostAPI('/api/internal/v1/applications/submit');

  return {
    submitApplication: async (values, additional_params) => {
      const formValues = buildLoanApplicationPayload(values);
      const data = snakecaseKeys(formValues, { deep: true });
      return await callAPI({ ...additional_params, values: data });
    }
  };
}