import React, { useMemo } from "react"
import { Skeleton, Grid, Typography, Box } from "@mui/material"
import { currency, percentage, rate } from "../../../../shared/format";
import { Heading1, Heading2 } from "../../../../styled";
import OfferSection from "./OfferSection";
import InfoModalLink from "../../../../shared/modals/InfoModaLink";

const OfferSummary = ({ loading, data, showMoreOffersHint = false }) => {
  const {
    id,
    requested_loan_amount,
    funded_date,
    percent_down,
    funded_term,
    admin_fee,
    funding_position_option,
    merchant: { legal_name },
    summary: { status, effectiveApprovedAmount, closedStatus },
    commission_rate,
    commission_amt,
    payment,
    total_payback,
    guarantee_fee_rate,
    origination_fee_rate,
  } = data;

  const sellRate = useMemo(() =>
    (guarantee_fee_rate && origination_fee_rate) ?
      (guarantee_fee_rate / 100) + (origination_fee_rate / 100) + 1 : null

    , [guarantee_fee_rate, origination_fee_rate])

  const buyRate = useMemo(() =>
    (guarantee_fee_rate && origination_fee_rate && commission_rate) ?
      (guarantee_fee_rate / 100) + (origination_fee_rate / 100) - (commission_rate / 100) + 1 : null
    , [guarantee_fee_rate, origination_fee_rate, commission_rate])

  return loading ?
    (<SectionSkeleton />)
    : (
      <OfferSection>
        <Title showHint={showMoreOffersHint}
               amount={effectiveApprovedAmount || currency(requested_loan_amount)}
               />
        <Grid container>
          <Grid container item sm={12} md={6}>
            <LabelValuesBox>
              <LabelValue label="Submitted" value={status} />
              <LabelValue label="Loan ID" value={id} />
              <LabelValue label="Requested Loan Amount" value={currency(requested_loan_amount)} />
            </LabelValuesBox>
          </Grid>
          <Grid container item sm={12} md={6} justifyContent="flex-end">
            <LabelValuesBox align="right">
              <LabelValue label="Buy" value={rate(buyRate) || 'N/A'} />
              <LabelValue label="Sell" value={rate(sellRate) || 'N/A'} />
              <LabelValue label={"Commission"} value={currency(parseFloat(commission_amt)) || 'N/A'} />
              <LabelValue label={"Payment"} value={currency(payment) || 'N/A'} />
              <LabelValue label={"Total Payback"} value={currency(total_payback) || 'N/A'} />
            </LabelValuesBox>
          </Grid>
          {(closedStatus) && (
            <Grid container justifyContent="center">
              <Grid item mt={4} width={{ sm: '100%', md: 'unset' }}>
                <Heading2 mb={2}>Funded Details</Heading2>
                <LabelValuesBox>
                  <LabelValue label="Funded Date" value={funded_date || 'N/A'} />
                  <LabelValue label="Percent Down" value={percentage(percent_down) || 'N/A'} />
                  <LabelValue label="Funded Term" value={funded_term || 'N/A'} />
                  <LabelValue label="Admin Fee" value={admin_fee || 'N/A'} />
                  <LabelValue label="Funding Position Option" value={funding_position_option || 'N/A'} />
                </LabelValuesBox>
              </Grid>
            </Grid>
          )}
        </Grid>
      </OfferSection>
    );
}

const Title = ({ amount, showHint }) => {
  return (
    <Grid
      container
      item
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      mb={3}
    >
      <Grid item pr={1}>
        {showHint && <InfoModalLink>
          <span style={{ paddingRight: '5em' }}>Scroll down to see more offers.</span>
        </InfoModalLink>}
      </Grid>
      <Grid item>
        <Heading1>
          Loan Amount: {amount}
        </Heading1>
      </Grid>
    </Grid>
  )
}

const LabelValuesBox = ({ children, align = "left" }) =>
  <Box display="grid"
    columnGap={4}
    rowGap={0}
    width={{
      sm: '100%',
      md: (align === 'right' ? 'auto' : '100%')
    }}
    gridAutoRows="min-content"
    gridTemplateColumns={{
      sm: "[label] auto [value] 1fr",
      md: align === 'right' ? "[label] max-content [value] max-content" : "[label] auto [value] 1fr"
    }}>
    {children}
  </Box>

const LabelValue = ({ label, value }) => <>
  <Box gridColumn="label"><Typography>{label}</Typography></Box>
  <Box gridColumn="value" textAlign={{ sm: "right", md: "left" }}><Typography className="bold">{value}</Typography></Box>
</>

const SectionSkeleton = () => {
  return (
    <Box sx={6}>
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
      <Skeleton />
    </Box>
  );
}

export default OfferSummary