import axios from "./axios.instance";

function isCSRFRequest(method) {
  return method === 'POST' || method === 'PATCH' || method === 'PUT' || method === 'DELETE';
}

function getMetaCSRF() {
  return document.getElementsByName("csrf-token")[0].getAttribute("content");
}

function getSessionCSRF() {
  return sessionStorage.getItem("csrf-token");
}

function setSessionCSRF(token) {
  sessionStorage.setItem("csrf-token", token);
}

export function clearSessionCSRF() {
  sessionStorage.removeItem("csrf-token");
}

function constructURL(url) {
  return url;
}

function constructOptions(options, method) {
  let {
    headers = {}
  } = options;

  if (isCSRFRequest(method)) {
    const metaCsrf = getMetaCSRF();
    const sessionCsrf = getSessionCSRF();
    headers['X-CSRF-Token'] = sessionCsrf || metaCsrf;
  }

  return {
    ...options,
    headers
  };
}

export async function apiCall(method, url, options) {
  const response = await axios({
    method,
    url: constructURL(url),
    ...constructOptions(options || {}, method)
  });

  if (isCSRFRequest(method)) {
    const sessionCsrf = response.headers["x-csrf-token"];
    if (sessionCsrf) {
      setSessionCSRF(sessionCsrf);
    }
  }
  return response;
}
