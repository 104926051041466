import React, { useEffect, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { fetchExpandedRecommendations, fetchStreetInfo } from "../smartyAddressAutocomplete";

const useStyles = makeStyles((theme) => ({
}));

export function AddressRecommendationsModal({ recommendations, open, handleClose, onAddressSelected }) {
  const classes = useStyles();
  const [allRecommendations, setAllRecommendations] = useState(recommendations);
  const [addresses, setAddresses] = useState();
  const [currentAddress, setCurrentAddress] = useState();
  const [recommendationType, setRecommendationType] = useState();
  const [recommendationIndex, setRecommendationIndex] = useState();
  const [updating, setUpdating] = useState();

  const loadNextRecommendations = (all) => {
    const [nextRecos, ...rest] = all;
    setAllRecommendations(rest);

    const {
      recommendations: _recommendations,
      currentAddress: _currentAddress,
      type: _recoType,
      index,
    } = nextRecos;

    setCurrentAddress(_currentAddress);
    setAddresses(_recommendations);
    setRecommendationType(_recoType);
    setRecommendationIndex(index);
  };

  useEffect(() => {
    if (!recommendations?.length) return;
    loadNextRecommendations(recommendations);
  }, [recommendations]);

  const onSelectAddress = async (addr) => {
    if (updating) return;

    if (addr.entries) {
      const expandingReco = await fetchExpandedRecommendations(addr);
      setAddresses(expandingReco);
    } else {
      const streetInfo = await fetchStreetInfo(addr);
      setUpdating(true);
      try {
        await onAddressSelected(recommendationType, streetInfo, recommendationIndex);
      } finally {
        setUpdating(false);
        if (allRecommendations.length) {
          loadNextRecommendations(allRecommendations);
        } else {
          handleClose();
        }
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll='paper'
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {recommendationType === 'company' && "Company Address Recommendations"}
        {recommendationType === 'user' && "Guarantor Address Recommendations"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          We've set {recommendationType === 'company' ? 'the company' : 'a guarantor'} address
          information to the following:<br /><br />

          {currentAddress}<br /><br />

          Is this correct? Here are some other similar possibilities:
        </DialogContentText>
        <List dense={true}>
          {addresses && addresses.map((reco, index) => (
            <ListItem
              key={index}
              button={true}
              onClick={() => onSelectAddress(reco)}
              disabled={updating}>
              <ListItemText primary={reco.description} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        {!!allRecommendations?.length && (
          <Button
            onClick={() => loadNextRecommendations(allRecommendations)}
            disabled={updating}
            autoFocus>
            {updating ? 'Saving...' : 'Skip'}
          </Button>
        )}
        {!allRecommendations?.length && (
          <Button
            onClick={handleClose}
            disabled={updating}
            autoFocus>
            {updating ? 'Saving...' : 'Done'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}