import React from "react"
import { Outlet } from "react-router-dom"
import { Container } from "@mui/material"
import "./styles/App.css"
import AppBarDrawer from "../AppBarDrawer/AppBarDrawer"

const Layout = () => (
  <Container className="App">
    <AppBarDrawer.Auth />
    <Outlet />
  </Container>
)

export const NoAuthLayout = () => (
  <Container className="App">
    <AppBarDrawer.NoAuth />
    <Outlet />
  </Container>
)

export default Layout