import { Typography, Grid, List, ListItem } from "@mui/material";
import React from "react";
import { Heading1 } from "../../../../styled";
import OfferSection from "./OfferSection";

const StatusDetails = ({ offer }) => {
  const { status, declineReasons, declinedStatus, attentionStatus } =
    offer.summary;

  return (
    <OfferSection>
      <Heading1>
        Offer Status: {status}
        {declinedStatus && <DeclineReasons {...{ declineReasons }} />}
        {attentionStatus && <NeedsAttentionReason />}
      </Heading1>
    </OfferSection>
  );
};

const DeclineReasons = ({ declineReasons = [] }) => {
  return (
    <List>
      {declineReasons.map((reason) => (
        <ListItem key={reason} container>
          <Typography>{reason}</Typography>
        </ListItem>
      ))}
    </List>
  );
};

const NeedsAttentionReason = () => {
  return (
    <Grid container>
      <Typography>Requesting business details per note below</Typography>
    </Grid>
  );
};

export default StatusDetails;
