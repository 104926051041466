import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import StylesProvider from '@mui/styles/StylesProvider';
import { AuthProvider } from "./context/context.js";
import { RootStoreProvider } from "./context/rootStoreContext";
import reportWebVitals from "./reportWebVitals";
import { theme } from "./context/themeContext";
import "./index.css";
import { fetchEnv, getEnvironment } from "./env";
import Router from "./components/Router";
import Snackbar from "./components/Snackbar";
import CacheBuster from "./utils/CacheBuster";

CacheBuster();

ReactDOM.render(
  <StylesProvider injectFirst>
    <BrowserRouter basename={fetchEnv('BASE_PATH')}>
      <CookiesProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <AuthProvider>
              <RootStoreProvider environment={getEnvironment()}>
                <Router />
                <Snackbar />
              </RootStoreProvider>
            </AuthProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </CookiesProvider>
    </BrowserRouter>
  </StylesProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
