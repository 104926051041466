import React from "react";
import NYValidations from "./NY";
import CAValidations from "./CA";
import defaultValidations from "./default";

function stateSpecificValidations(state) {
  switch (state) {
    case "NY":
      return NYValidations;
    default:
      return defaultValidations;
  }
}

function stateSpecificDisclosures(state) {
  switch (state) {
    case "CA":
      return CAValidations.disclosure.text;
    case "NY":
      return NYValidations.disclosure.text;
    default:
      return defaultValidations;
  }
}

function findStateMinAmount(state, amount) {
  switch (state) {
    case "CA":
      return amount <= 500000;
    case "NY":
      return amount <= 2500000;
    default:
      return false;
  }
}

const disclosureStates = ["CA", "NY"];

export {
  findStateMinAmount,
  disclosureStates,
  stateSpecificDisclosures,
  stateSpecificValidations,
  CAValidations,
  NYValidations,
  defaultValidations,
};
