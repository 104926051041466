import { useFormikContext } from "formik";
import { useEffect, useState } from "react"
import { useNewApplicationStore } from "../../../../shared/NewApplication";
import { usePostAPI } from "../../../../shared/useAPI";
import { useModalQueue } from "../modals/queue";
import { useAddressRecommendation } from "./useAddressRecommendation";

const attachmentType = "signedApplication"

function fileKey(file) {
  return `${file.name}-${file.lastModified}`
}

function scrubTextractValues(values, companyTypes) {
  const str = values?.offer?.amount || "0.00"
  values.offer.amount = String(parseFloat(str.replace(/,/g, '')))

  const typeInc = values.company.type_inc;
  if (!Object.keys(companyTypes).includes(typeInc)) {
    values.company.type_inc = ""
  }
}

function constructTextractWarnings(warnings = []) {
  return [{
    id: "textract-import",
    severity: "warning",
    message: "Your application has been imported. Please review the information to ensure all fields are correct.",
  }].concat(
    warnings.map((warning, index) => ({
      id: `textract-import-warnings-${index}`,
      severity: "warning",
      message: warning,
    }))
  )
}

export function useTextract() {
  const [isProcessing, setIsProcessing] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [lastFileKey, setLastFileKey] = useState()
  const [debug, setDebug] = useState(false)

  const { callAPI, data } = usePostAPI("/api/internal/v1/textract_docs", null, {})

  const {
    companyTypes,
    addAlert, hideAlert,
    resetInitialValues,
    signedApps, setSignedApps,
  } = useNewApplicationStore();

  const {
    getAddressRecommendations,
    recommendations,
    clearRecommendations,
    updateAddress,
  } = useAddressRecommendation();

  const { nextModal, pushModal } = useModalQueue();
  const { setFieldValue } = useFormikContext()

  // enqueue address recommendations
  useEffect(() => {
    if (!recommendations.length) return;

    pushModal({
      id: 'address-recommendations',
      show: () => setShowModal(true)
    })
  }, [recommendations]);

  const onCloseRecommendationsModal = () => {
    clearRecommendations();
    nextModal();
    setShowModal(false);
  };

  const onFileRejection = (fileRejections) => {
    if (!fileRejections?.length) {
      hideAlert('textract-fileTooLarge');
      return;
    }

    const isFileTooLarge = !!fileRejections.find(
      ({ errors }) =>
        !!errors.find(({ code }) => code === 'file-too-large')
    )

    if (isFileTooLarge) {
      addAlert([{
        id: "textract-fileTooLarge",
        severity: "error",
        message: "The document you have attempted to upload is larger than 5MB.  Please reduce the size of the file, and try uploading again."
      }]);
    }
  }

  const onFileAdded = async (file, fileResult) => {
    if (!file || fileKey(file) === lastFileKey) return

    setLastFileKey(fileKey(file))
    setIsProcessing(true)
    hideAlert('textract-import', 'textract-error', 'conflict-error')

    try {
      const formData = new FormData()
      formData.append("textract_doc[attachments][]", file)

      const response = await callAPI(formData)
      const { result, guarantors } = response.data
        scrubTextractValues(result, companyTypes)
        resetInitialValues(result, 'textract')
        setSignedApps([file])
        setFieldValue(`files[${attachmentType}][0]`, fileResult)

        addAlert(constructTextractWarnings())
        getAddressRecommendations(result)

      } catch (err) {
        console.error("Error importing application", err)
        setSignedApps([])
        setFieldValue(`files.${attachmentType}`, [])

        addAlert([{
          id: "textract-error",
          severity: "error",
          message: "The system was unable to read your application.  Please resubmit the application or submit it manually"
        }]);
      } finally {
        setIsProcessing(false);
      }
  }

  return {
    isProcessing,
    debug, setDebug,
    diagnostics: data?.diagnostics,
    addressRecommendationsModalProps: {
      recommendations,
      open: showModal,
      handleClose: onCloseRecommendationsModal,
      onAddressSelected: updateAddress,
    },
    fileDropzoneProps: {
      attachmentType,
      onFileRejection,
      onFileAdded,
      files: signedApps,
      setFiles: setSignedApps,
      setFieldValue,
    },
  }
}