import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

export const Instructions = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" style={{flexBasis: '100%', width: 0}}>
      <Grid container item>
        <Grid container item gap={2}>
          <Grid item xs={9} md={10} lg={12} style={{ textAlign: "center" }}>
            <Typography variant="body">
              Use the sliders to adjust loan amounts and commission rates, use the checkbox to move forward with an offer.
            </Typography>
          </Grid>
          <Grid item xs={11} md={10} lg={12} style={{ textAlign: "center", fontWeight: "bold"}}>
            <Typography variant="body">
              Please note: if this is a California or New York Loan, the Generate Disclosure button MUST also be selected in order to comply with state disclosure requirements
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Instructions;
