import { Button, Grid, TextField } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Formik } from "formik";
import React from "react";
import { resetPassword } from "../context/context";
import Logo from "../images/iou_financial_logo.png";
import { Title } from "./App/styled";
import { useNavigate, Link } from "react-router-dom";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: "200px",
      padding: "20px 20px 20px 20px",
      backgroundColor: "#fff",
    },
    mainLogo: {
      marginBottom: "10px",
      width: "100%",
    },
    inputField: {
      padding: "20px",
    },
    submitButton: {},
  })
);

const ResetPassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const submit = async (values, { setErrors, setSubmitting, resetForm }) => {
    const { password, confirmPassword } = values;
    const params = new URLSearchParams(document.location.search)
    try {
      await resetPassword(password, confirmPassword, params.get('token'))
      resetForm();
      setSubmitting(false);
      navigate("/login", { state: { notice: "Password has been reset - please login with new password" } });
    } catch (error) {
      navigate("/login", { state: { alert: "Invalid or expired password reset token, please try again" } });
      console.log(error);
    }
  }

  const validation = (values) => {
    const errors = {};
    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length < 8) {
      errors.password = "Password must be at least 8 characters";
    } else if (values.password.length > 128) {
      errors.password = "Password cannot be more than 128 characters";
    } else if (!values.confirmPassword) {
      errors.confirmPassword = "Required";
    } else if (values.confirmPassword !== values.password) {
      errors.password = "Passwords do not match";
      errors.confirmPassword = "Passwords do not match";
    }
    return errors;
  };

  return (
    <Grid container spacing={0} align="center" justifyContent="center">
      <Grid item className={classes.root}>
        <img className={classes.mainLogo} width="100%" src={Logo} alt="IOU Financial Logo" />
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          validate={validation}
          onSubmit={submit}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <Grid>
              <form onSubmit={handleSubmit}>
                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
                  <Grid container item sm={6}>
                    <Title my={2}>Reset Password</Title>
                    <TextField
                      id="password"
                      label="Password"
                      type="password"
                      placeholder="Enter password"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      helperText={touched.password && errors.password}
                      autoComplete="none"
                      variant="outlined"
                      InputLabelProps={{ shrink: true, style: { color: "#000000" } }}
                    />
                  </Grid>
                  <Grid container item sm={6}>
                    <TextField
                      id="confirmPassword"
                      fullWidth
                      label="Confirm Password"
                      type="password"
                      placeholder="Enter password confirmation"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirmPassword}
                      helperText={touched.confirmPassword && errors.confirmPassword}
                      autoComplete="none"
                      variant="outlined"
                      InputLabelProps={{ shrink: true, style: { color: "#000000" } }}
                    />
                  </Grid>

                  <Grid item>
                    <Button
                      className="button-primary"
                      fullWidth
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Reset Password
                    </Button>
                  </Grid>
                  <Grid item>
                    <Link to="/login">Return to login</Link>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
