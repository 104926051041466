import React from "react";
import {
  Chip,
  Grid,
  TableCell,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { date, phoneNumber, currency } from "../../../../shared/format";


const useStyles = makeStyles((theme) => ({
  name: {
    fontWeight: "bold",
  },
  email: {},
  phone: {},
  status: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  createdAt: {},
  updatedAt: {},
  conflictTab: {
    padding: "0 1em",
    position: "absolute",
    bottom: "0",
    transform: "translateY(50%)",
  },
  conflictTableCellMain: {
    borderColor: "rgba(224, 224, 224, 0.5)",
    position: "relative",
    paddingBottom: "2em",
  },
}));

const ConflictTab = () => {
  const classes = useStyles();
  return (
    <Chip classes={{root: classes.conflictTab}} label="Conflict" color="error" />
  )
}

const AppRow = ({ row }) => {
  const classes = useStyles();
  const conflict = (row.error_code === 'check-conflicts');

  return (
    <>
      <TableCell component="th" scope="row" classes={{ root: conflict ? classes.conflictTableCellMain : null }}>
        <Grid container direction="column">
          <Grid item className={classes.name}>
            {row.business_name}
            {row.ein?.length > 0 ? <> ({row.ein})</> : ""}
          </Grid>
          {row.email && (
            <Grid item className={classes.email}>
              <a href={`mailto:${row.business_email}`}>{row.business_email}</a>
            </Grid>
          )}
          {row.business_phone && (
            <Grid item className={classes.phone}>{phoneNumber(row.business_phone)}</Grid>
          )}
          {(row.loan_amount || 0) > 0 && (
            <Grid item className={classes.loan_amount}>{currency(row.loan_amount)}</Grid>
          )}
          <Grid item>Broker: {row.broker_name}</Grid>
        </Grid>
        {conflict && (
          <ConflictTab />
        )}
      </TableCell>
      <TableCell classes={{ root: conflict ? classes.conflictTableCellMain : null }}>
        <Grid container direction="column">
          <Grid item className={classes.createdAt}>Created: {date(row.created_at)}</Grid>
          <Grid item className={classes.updatedAt}>Last Updated On: {date(row.updated_at)}</Grid>
        </Grid>
      </TableCell>
      <TableCell classes={{ root: conflict ? classes.conflictTableCellMain : null }}>
      </TableCell>
    </>
  )
}

export default AppRow;