import React from "react";
import { Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ApplicationsTable from "./ApplicationsTable";
import DashboardHeader from "./DashboardHeader";

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: '1em',
  }
}));

function ApplicationsList({ onDateRangeChange, dateRange, metric, onMetricSelected }) {
  const classes = useStyles();  
  return (
    <Grid container direction="column">
      <Grid item className={classes.header}>
        <DashboardHeader onChange={onDateRangeChange} dateRange={dateRange} metric={metric} onMetricSelected={onMetricSelected} />
      </Grid>
      <Grid item>
        <ApplicationsTable dateRange={dateRange} metric={metric} />
      </Grid>
    </Grid>
  );
}

export default ApplicationsList;