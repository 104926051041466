import React, { createContext, useCallback, useContext, useReducer, useState } from "react";

export const ModalQueueContext = createContext({
  pushModal: () => { },
  nextModal: () => { }
})

export function useModalQueue() {
  return useContext(ModalQueueContext);
}

function reducer(state, { action, ...rest }) {
  switch (action) {
    case 'PUSH':
      const { modal } = rest;
      if (state.currentModal) {
        if (state.currentModal.id === modal.id) return state

        if (state.queue.find(({ id }) => id === modal.id)) {
          return state
        }

        return {
          ...state,
          queue: [...state.queue, modal],
        }
      } else {
        modal.show()
        return {
          ...state,
          currentModal: modal,
        }
      }

    case 'NEXT':
      if (state.queue.length) {
        const modal = state.queue.shift()
        modal.show()
        return {
          ...state,
          queue: [...state.queue],
          currentModal: modal,
        }
      } else {
        return {
          ...state,
          currentModal: null,
        }
      }
  }
}

export const ModalQueueProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    queue: [],
    currentModal: null,
  })

  const nextModal = useCallback(() => {
    dispatch({ action: 'NEXT' });
  }, [])

  const pushModal = useCallback((modal) => {
    dispatch({ action: 'PUSH', modal })
  }, [])

  return (
    <ModalQueueContext.Provider value={{ pushModal, nextModal }}>
      {children}
    </ModalQueueContext.Provider>
  )
}