import React from 'react';

export default {
  application: {
    offer: {
      amount: {
        conditional: (value) => {
          return (( value < 15000) || value > 2500000)
        },
        errorMessage: `Please enter a value between $15,000 and $2,500,0000`
      }
    }
  },
  disclosure: {
    text: ( 
      <>
        <p>
        IMPORTANT!
        In order to comply with New York Commercial Finance Disclosure Law 
        found in Article 8, Sections 801-811 of the New York Financial Services Law, 
        these Offers of Financing are provided to you for discussion purposes ONLY and 
        cannot be communicated in writing to the New York Merchant unless two (2) or 
        more financing options are sent together and at the same time or if the loan amount 
        is greater than $2,500,000.00. If you send a written communication to a 
        New York Merchant that contains a single offer of financing without also sending 
        our system generated Disclosure document (by selecting "Generate Disclosure"), 
        IOU Financial will be required to discontinue doing business with you.  
        Once the Merchant has selected their preferred option, you must ensure that they 
        receive a Disclosure document by selecting "Generate Disclosure" beneath the 
        selected option.
        </p>
      
        <p>I have read and understand the requirements outlined above.</p>
      </>)
  }
}