import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { useGetAPI } from "../../../../shared/useAPI";
import { Typography, Button, Box } from "@mui/material";
import { currency } from "../../shared/format";
import Tooltip from "@mui/material/Tooltip";

export default function ({
  dateRange: [start_date, end_date],
  onMetricSelected,
}) {
  const { data, loading, callAPI } = useGetAPI(
    "/api/internal/v1/dashboard/metrics",
    { params: { start_date, end_date } }
  );

  useEffect(() => {
    if (start_date && end_date) {
      callAPI();
    }
  }, [start_date, end_date]);

  const {
    appsApproved = 0,
    appsClosed = 0,
    appsDeclined = 0,
    appsNeedsAttention = 0,
    appsPending = 0,
    appsRenewalEligible = 0,
    appsSubmitted = 0,
    amtAppsApproved = "0",
    amtAppsClosed = "0",
    amtAppsDeclined = "0",
    amtAppsNeedsAttention = "0",
    amtAppsPending = "0",
    amtAppsRenewalEligible = "0",
    amtAppsSubmitted = "0",
  } = data?.brokerDashboardMetrics || {};

  function selectMetric(name) {
    return () => onMetricSelected(name);
  }

  return (
    <>
      {loading && <CircularProgress color="inherit" />}
      {!loading && (
        <Box mt={5}>
          <Grid container item>
            <Grid
              item
              xs={12}
              sm={4}
              md={2}
              align="center"
              className="bg-primary"
              onClick={selectMetric("pending")}
              data-cy="dashboard-metric"
            >
              <Grid container alignItems="center">
                <Grid item xs={12}>
                  <Typography variant="h5" className="link bold">
                    Submitted In Review
                  </Typography>
                </Grid>
              </Grid>
              <hr />
              <Box>
                <Typography display="inline" className="bold">
                  Units:{" "}
                </Typography>
                <Typography display="inline">{appsSubmitted}</Typography>
              </Box>
              <Box>
                <Typography display="inline" className="bold">
                  Volume:{" "}
                </Typography>
                <Typography display="inline">
                  {currency(amtAppsSubmitted)}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={2}
              align="center"
              className="bg-primary bg-success"
              onClick={selectMetric("approved")}
              data-cy="dashboard-metric"
            >
              <Grid container alignItems="center">
                <Grid item xs={1}>
                  <Tooltip
                    arrow
                    placement="top"
                    title="This number indicates all approved loan applications or most recent credit pulls that were submitted in past 30 days."
                  >
                    <Button className="button-outline button-round button-offset-top">
                      {appsApproved}
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="h5" className="link bold">
                    Approved
                  </Typography>
                </Grid>
              </Grid>
              <hr />
              <Box>
                <Typography display="inline" className="bold">
                  Units:{" "}
                </Typography>
                <Typography display="inline">{appsApproved}</Typography>
              </Box>
              <Box>
                <Typography display="inline" className="bold">
                  Volume:{" "}
                </Typography>
                <Typography display="inline">
                  {currency(amtAppsApproved)}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={2}
              align="center"
              className="bg-primary"
              onClick={selectMetric("closed")}
              data-cy="dashboard-metric"
            >
              <Grid container alignItems="center">
                <Grid item xs={12}>
                  <Typography variant="h5" className="link bold">
                    Closed
                  </Typography>
                </Grid>
              </Grid>
              <hr />
              <Box>
                <Typography display="inline" className="bold">
                  Units:{" "}
                </Typography>
                <Typography display="inline">{appsClosed}</Typography>
              </Box>
              <Box>
                <Typography display="inline" className="bold">
                  Volume:{" "}
                </Typography>
                <Typography display="inline">
                  {currency(amtAppsClosed)}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={2}
              align="center"
              className="bg-primary bg-success"
              onClick={selectMetric("renewal")}
              data-cy="dashboard-metric"
            >
              <Grid container alignItems="center">
                <Grid item xs={1}>
                  <Tooltip
                    arrow
                    placement="top"
                    title="This number indicates, over the last running month, loans that can be renewed."
                  >
                    <Button className="button-outline button-round button-offset-top">
                      {appsRenewalEligible}
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="h5" className="link bold">
                    Eligible for Renewal
                  </Typography>
                </Grid>
              </Grid>
              <hr />
              <Box>
                <Typography display="inline" className="bold">
                  Units:{" "}
                </Typography>
                <Typography display="inline">{appsRenewalEligible}</Typography>
              </Box>
              <Box>
                <Typography display="inline" className="bold">
                  Volume:{" "}
                </Typography>
                <Typography display="inline">
                  {currency(amtAppsRenewalEligible)}
                </Typography>
              </Box>
            </Grid>
           <Grid
              item
              xs={12}
              sm={4}
              md={2}
              align="center"
              className="bg-primary"
              onClick={selectMetric("declined")}
              data-cy="dashboard-metric"
            >
              <Grid container alignItems="center">
                <Grid item xs={12}>
                  <Typography variant="h5" className="link bold">
                    Declined
                  </Typography>
                </Grid>
              </Grid>
              <hr />
              <Box>
                <Typography display="inline" className="bold">
                  Units:{" "}
                </Typography>
                <Typography display="inline">{appsDeclined}</Typography>
              </Box>
              <Box>
                <Typography display="inline" className="bold">
                  Volume:{" "}
                </Typography>
                <Typography display="inline">
                  {currency(amtAppsDeclined)}
                </Typography>
              </Box>
            </Grid>


          </Grid>
        </Box>
      )}
    </>
  );
}
