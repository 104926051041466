import { Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { PaymentCalculatorProvider } from "./context";
import { data } from "./data";
import PaymentFrequency from "./PaymentFrequency";
import { useGetAPI, usePostAPI } from "../../shared/useAPI";
import { ConfirmationModal } from "./ConfirmationModal";
import { DisclosureModal } from "./DisclosureModal";
import { Title } from "../App/styled";
import Instructions from "./Instructions";
import { RootStoreContext } from "../../context/rootStoreContext";
import { useAuth } from "../../context/context";
import useOfferDetails from "../App/Partner/Dashboard/OfferDetailsDialog/useOfferDetails"; 
import OfferModel from "../../models/Offer"


function splitResponse(data) {
  return data.reduce((terms, item) => {
    const frequency = (terms[item.frequency] || []);
    frequency.push(buildTerm(item));
    terms[item.frequency] = frequency.sort((a, b) => (a.termLength > b.termLength) ? 1 : -1)
    return terms;
  }, {});
}

function buildTerm(item) {
  return {
    id: item.id,
    termLength: item.loan_term,
    amount: toFloat(item.requested_amount),
    commission: item.max_commission_rate * 100,
    originationFee: item.origination_fee_rate * 100,
    gFee: toFloat(item.gfee_rate) * 100,
    uwTotal: item.uw_total,
    positionNum: item.position_num,
    frequency: item.frequency,
    uwNetOriginationFee: item.uw_net_origination_fee,
    uwGrossOriginationFee: item.uw_gross_origination_fee,
    paymentCount: item.num_payments,
    isVisible: item.is_visible,
    renewalDiscount: item.renewal_discount,
    netLoanAmount: toFloat(item.net_loan_amount),
    isCurrentSelected: item.is_current_selected,
    buyRate: item.buy_rate,
    sellRate: item.sell_rate
  }; 
}

function toFloat(value) {
  if(!value) return 0.0 
  return parseFloat(value.toString().replace(/[\$,]/g, ''));
}

function buildData(data) {
  return Object.keys(data).map((key) => {
    return ({ 
      type: key,
      terms: data[key]
    });
  })
}

function scrollToCalc() {
  document.getElementById('loanCalculatorAnchor')?.scrollIntoView({
    behavior: 'smooth'
  });
};

const order = [ 'daily', 'weekly', 'bi-weekly', 'monthly'];
// http://localhost:3006/partners/paymentCalculator?offerId=test
const PaymentCalculator = ({offerId, offerModel, usaState, headerComponent, disclosureEnabled, authToken, title, readOnly = false, onTermsFound = () => {}}) => {
  const [submitting, setSubmitting] = useState(false);
  const [listData, setListData] = useState([]);
  const [offer, setOffer] = useState(offerModel);
  const [selectedTerm, setSelectedTerm] = useState();
  const [confirmedTerm, setConfirmedTerm] = useState();
  const [searchParams, _setSearchParams] = useSearchParams();
  const location = useLocation();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showDisclosureModal, setShowDisclosureModal] = useState(false)
  const [disclosureTerm, setDisclosureTerm] = useState()
  const { addMessage } = useContext(RootStoreContext);
  const { isMasquerading } = useAuth();

  offerId ??= searchParams.get('offerId');

  const { callAPI: fetchOffer } = useGetAPI(`/api/internal/v1/offers/${offerId}`)
  const { callAPI } = useGetAPI(`/api/internal/v1/payment_calculator/${offerId}/underwriter_decisions`)
  const { callAPI: callPostAPI } = usePostAPI(`/api/internal/v1/offers/${offerId}/decisions/${selectedTerm?.id}/accept`);
  const { callAPI: callGenerateDisclosureAPI } = usePostAPI(`/api/internal/v1/offers/${offerId}/decisions/${disclosureTerm?.id}/generate_disclosure`);

  const apiOptions = authToken ? {headers: { Authorization: authToken }} : {}

  useEffect(async() => {
    if(!offer) {
      const resp = await fetchOffer(apiOptions)
      resp.data.offer && setOffer(new OfferModel(resp.data.offer))
    }
  }, [])

  useEffect(()=> {
    listData.forEach((e) => e.terms.forEach((t) => t.isCurrentSelected && setConfirmedTerm(t) ))
  }, [listData])

  useEffect(() => {
    if(offerId !== null && offerId !== 'test') {
      callAPI(apiOptions).then((resp) => {
        setListData(buildData(splitResponse(resp.data)));
        onTermsFound(!!resp.data?.length)

        if(location.hash === "#loanCalculatorAnchor") {
          scrollToCalc();
        }
      })
    } else {
      const simulateData = async () => {
        const list = await new Promise((resolve, reject) => {
          setTimeout(() => { resolve(data) }, 0);
        });
        setListData(buildData(splitResponse(list)))
        onTermsFound(true)

        if(location.hash === "#loanCalculatorAnchor") {
          scrollToCalc();
        }
      }
      simulateData();

    }
  }, [])

  const handleConfirmation = async (confirm) => {
    if(!confirm) {
      setShowConfirmation(false)
      return
    }

    setSubmitting(true)
    const response = await callPostAPI({
      "amount": selectedTerm.sliderAmount,
      "commission": selectedTerm.sliderCommission / 100
    });

    if (response.data.error) {
      let body = 'An unexpected error occurred while accepting the offer.'
      if (isMasquerading) {
        body = `Error: ${response.data.error}`
      }
      addMessage({ severity: 'error', body })
    }

    try {
      const resp = await callAPI(apiOptions)
      setListData([])
      setListData(buildData(splitResponse(resp.data)));
    } finally {
      setSubmitting(false)
      setShowConfirmation(false)
    }
  }

  const resetListData = (term) => {
    if(newOrReplace(listData, term)) {
      return listData.map((termLength, _orderIndex) => {
        termLength['terms'] = termLength['terms'].map((t) => {
          if(t['id'] === term.id ){
            return {...t, ...term} // merge the current term data w/ new updates
          } else{
            t.isCurrentSelected = false //reset this value 
            return t
          }
        });
        return termLength
      });
    } else {
      const datum = Object.create(listData).map((termLength, _orderIndex) => {
        if(term.frequency == termLength.type) {
          termLength['terms'].push(term);
        }
        return termLength
      });
      return datum;
    }
  }

  const newOrReplace = (listData, term) => {
    const found = listData.map((termLength, _orderIndex) => 
      termLength['terms'].filter((t) => (t['id'] === term.id )).length
    ).filter((e)=> e.length)
    return found.length;
  } 
  const invalidData = ( (listData || []).map((e) => e.terms.map((t) => (t.isVisible) ? t : false)).flat().filter((e) => e)).length === 0;
  const expressVisible = (offer && offer.summary && !offer.summary.closedStatus && !offer.summary.renewalStatus && (!!offer.is_express || !!offer.underwriting_complete_time))

  return invalidData ? <></> :
    !expressVisible ? <></> :
    (
    <>
      <Grid container justifyContent="space-between" className="loan-calculator heading">
        <Grid item flex="1 1 0">
        </Grid>
        <Grid item flex="0 0 fit-content">
          <Title id="loanCalculatorAnchor" align="center">
            {title?.length ? title : 'Loan Offers'}
          </Title>
        </Grid>
        <Grid container item flex="1 1 0" justifyContent="flex-end">
          {headerComponent}
        </Grid>
        <Instructions/>
      </Grid>
      {
      order.map((order, _orderIndex) => 
        listData.filter((e)=> e.type === order).map((data, index) => (
        <PaymentCalculatorProvider key={index} initialData={data} oId={offerId} offerParam={offer}>
          <PaymentFrequency
            key={data.termLength}
            header={data.termLength}
            selectedTerm={confirmedTerm}
            setSelectedTerm={setSelectedTerm}
            disclosureEnabled={disclosureEnabled}
            readOnly={readOnly}
            minimumAmount={offer.complianceMinimum}
            open={(term)=> {
              setSelectedTerm(Object.create(term));
              setShowConfirmation(!showConfirmation)
            }}
            openDisclosure={(term) => {
              setDisclosureTerm(term)
              setShowDisclosureModal(!showDisclosureModal)
            }}
            usaState={usaState}
            />
        </PaymentCalculatorProvider>))
      )}
      <ConfirmationModal
        open={showConfirmation}
        submitting={submitting}
        onClose={handleConfirmation}
        />

        {disclosureEnabled && 
          <DisclosureModal
            open={showDisclosureModal}
            usaState={usaState}
            onClose={() => setShowDisclosureModal(false)}
            onSubmit={callGenerateDisclosureAPI}
            />
        }
    </>
  );
};

export default PaymentCalculator;
