export default {
  application: {
    offer: {
      amount: {
        conditional: (value) => {
          return (( value < 15000) || value > 1500000)
        },
        errorMessage: `Please enter a value between $15,000 and $1,500,0000`
      }
    },
  }
}