import React, { useCallback, useMemo, useEffect } from "react";
import { Button, List } from "@mui/material";
import { FieldArray } from "formik";
import { includes } from "lodash";
import { useDropzone } from "react-dropzone";
import FileListItem from "./FileListItem";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const FileDropzone = ({
  attachmentType,
  files,
  setFiles,
  setFieldValue,
  showFileIcon,
  message = 'Drag and drop files here',
  dragActiveMessage = 'Drop file here ...',
  buttonText,
  buttonProps = {},
  accept,
  multiple = true,
  maxSize = Infinity,
  onFileRejection,
  onFileAdded,
}) => {
  const onDrop = useCallback((acceptedFiles) => {
    let pos = files.length;
    acceptedFiles.forEach((file) => {
      const rawPaths = files.map((file) => file.path);
      if (includes(rawPaths, file.path)) return;

      const reader = new FileReader();
      reader.onloadend = function () {
        const result = { file: reader.result, fileName: file.name };
        if (multiple) {
          setFieldValue(`files[${attachmentType}][${pos}]`, result)
          setFiles((files) => [...files, file])
          pos += 1
        } else {
          setFieldValue(`files[${attachmentType}][0]`, result)
          setFiles([file])
        }
        onFileAdded?.(file, result)
      };
      reader.readAsDataURL(file);
    });
  }, [files, attachmentType]);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    onDrop,
    accept,
    multiple,
    maxSize,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  useEffect(() => {
    if (onFileRejection) {
      onFileRejection(fileRejections)
    }
  }, [fileRejections])

  return (
    <>
      <List dense>
        <FieldArray name={`files.${attachmentType}`}>
          {({ remove }) =>
            <>
              {files.map((file, index) => (
                <FileListItem
                  key={file.toString() + index}
                  file={file}
                  index={index}
                  showIcon={showFileIcon}
                  removeFile={(i) => {
                    remove(i)
                    setFiles((files) => [
                      ...files.slice(0, i),
                      ...files.slice(i + 1)
                    ])
                  }}
                />
              ))}
            </>
          }
        </FieldArray>
      </List>

      <div className="file-dropzone" {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p className="file-dropzone-label">
          {isDragActive ? dragActiveMessage : message}
        </p>
        <Button
          {...buttonProps}
          variant="contained"
        >
          {buttonText}
        </Button>
      </div>
    </>
  );
};

export default FileDropzone