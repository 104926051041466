import { get, set, isEmpty, cloneDeep } from "lodash";
import { DateTime } from "luxon";
import errorStatusCodes from "../../../../shared/NewApplication/errorStatusCodes";
import { stateSpecificValidations } from "./stateValidations/allStateValidations";
// import faker from "faker";

function validateLength(obj, length) {
  if ((obj || "").toString() === "") return true;
  return obj.toString().length === length;
}

function validateNumeric(obj) {
  if ((obj || "").toString() === "") return true;
  return !!obj.toString().match(/^[0-9.]+$/i);
}

function validateDigitsOnly(obj) {
  if ((obj || "").toString() === "") return true;
  return !!obj.toString().match(/^[0-9]+$/i);
}

function validateZip(values, key, errors) {
  const val = get(values, key, "");
  if (!validateLength(val, 5)) set(errors, key, `Invalid zipcode, zipcode must be 5 digits`);
  if (!validateDigitsOnly(val)) set(errors, key, "ZIP Code should only contain digits");
  return errors;
}

function validateAlphaNumeric(values, key, errors) {
  const val = (get(values, key) || "").toString();
  if (val === "") return true;
  if (!val.toString().match(/^[0-9a-zA-Z-]+$/))
    set(errors, key, "Street can only contain numbers, letters or dashes");
  return errors;
}

// remove all non-alphanumeric and _ from inputMasks
function validateExistance(values, key, errors) {
  const val = get(values, key);
  const cleanVal = (val || "").toString().replace(/[^0-9a-z]/gi, "");
  if (isEmpty(cleanVal)) set(errors, key, "Required");
  return errors;
}

function validateEmail(values, key, errors) {
  const val = get(values, key, null);
  if (val && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val)) {
    set(errors, key, "Invalid email address");
  }
}

function validateCpr(values, key, errors, attrName) {
  const val = get(values, key) || "";
  const scrubbedVal = val.replaceAll(/[^0-9]/g, "");
  if (!validateDigitsOnly(val.replaceAll(/[-_]/g, ""))) {
    set(errors, key, "Should only contain digits");
  } else if (scrubbedVal.length === 0) {
    set(errors, key, "Required");
  } else if (scrubbedVal.length !== 9) {
    set(errors, key, `Invalid ${attrName} number`);
  }
}

function validateAmount(values, key, errors) {
  const val = get(values, key, 0);
  if(!val) return;
  const parsedVal = parseInt(val);
  const state = get(values,'companyAddress.province')
  const rule = get(stateSpecificValidations(state), 'application.offer.amount')
  if(rule.conditional(parsedVal)) set(errors, key, rule.errorMessage);
}

function validateLoanConditions(values, errors) {
  const amount = get(values, 'offer.amount');
  const term = get(values, 'offer.lengthMax');
  if(!amount || !term) return;

  if (term === "24" && parseInt(amount) < 300000) {
    set(errors, 'offer.amount', "Loan amount must be at least $300,000 for a 24 month term");
  }
}

function validatePhoneNumber(values, key, errors) {
  const val = get(values, key, null);
  const rawPhoneNumber = (val || "").replace(/\D/g, "") ?? "";

  if (rawPhoneNumber.length < 10) {
    set(errors, key, "Phone number required");
  }
}

function validatePercentOwnership(values, key, errors) {
  const val = get(values, key, null);
  if((val || "" ).length  == 0) return;
  const digitValid = validateNumeric(val)
  if (!digitValid) set(errors, key, "Ownership value should only be contain digits");
}

function validateGuarantorsOwnership(values, errors) {
  let totalOwnerPercent = 0
  let keys = []

  values.user.ownersAttributes.forEach((_, index) => {
    const key = `user.ownersAttributes[${index}].ownerPercent`
    keys.push(key)

    if (get(errors, key)) return
    const val = get(values, key, null)
    if (!(val || "").length) return

    totalOwnerPercent += parseInt(val)
  })

  if (totalOwnerPercent > 100) {
    keys.forEach((k) =>
      set(errors, k, "Total percent ownership should not exceed 100%")
    )
  }

  if (totalOwnerPercent < 51) {
    keys.forEach((k) =>
      set(errors, k, "Total percent ownership must be at least 51%")
    )
  }
}

function validateBusinessStartDate(values, key, errors) {
  const val = get(values, key, null);
  const [monthStr, yearStr] = (val || "/").split("/");
  const cleanMonth = (monthStr || "").toString().replace(/[^0-9]/gi, "");
  const cleanYear = (yearStr || "").toString().replace(/[^0-9]/gi, "");
  const month = parseInt(cleanMonth) || 0;
  const year = parseInt(cleanYear) || 0;
  const validatedBusinessAge = validateBusinessAge(DateTime.fromISO(`${cleanYear}-${cleanMonth}-01`));

  if (!validatedBusinessAge) set(errors, key, "Start date must be at least 1 year ago");
  if (month > 12 || month === 0) set(errors, key, "Must be a valid month");
  if (cleanMonth.length !== 2 && month !== 0) set(errors, key, "Month must be two digits");
  if (year > new Date().getFullYear() || year < 1900) set(errors, key, "Must be a valid year");
  if (year.toString().length < 4 && year !== 0) set(errors, key, "Must be a valid year");
}

function validateBusinessAge(dateISO) {
  const dt = DateTime.now().toISO();
  const [yearStr, monthStr, _dayStr] = dt.split("-");
  const today = DateTime.fromISO(`${yearStr}-${monthStr}-01`);
  try {
    const diffInYears = today.diff(dateISO, 'years');
    const yearDiff = diffInYears.toObject()['years']; //=> { months: 0 }
    return (yearDiff >= 1)
  } catch(e) {
    console.error(e)
  }
  return false;
  
}

function validateAge(values, key, errors) {
  const val = get(values, key, null);
  var birthDate = DateTime.fromISO(val);
  var today = DateTime.now();
  
  try {
    var diffInYears = today.diff(birthDate, 'years');
    var yearDiff = diffInYears.toObject()['years']; //=> { months: 0 }
    if(yearDiff < 18) {
      set(errors, key, "Business Owner must be at least 18 years or older");
    } else if(yearDiff >= 100) {
      set(errors, key, "Business Owner cannot be older than 100 years");
    }

  } catch(e) {
    console.error(e)
  }
}

// eslint-disable-next-line import/prefer-default-export
export function validationHelper(values) {
  const errors = {};
  // company keys
  ["firstName", "cpr", "email", "phoneWork", "typeInc", "businessStartDate"].forEach((el) =>
    validateExistance(values, `company.${el}`, errors)
  );
  validateEmail(values, "company.email", errors);
  validateCpr(values, "company.cpr", errors, 'EIN');
  validateAmount(values, "offer.amount", errors);
  validateLoanConditions(values, errors);
  validatePhoneNumber(values, "company.phoneWork", errors);
  validateBusinessStartDate(values, "company.businessStartDate", errors);

  // offerkeys
  ["amount", "description", "lengthMax", "pmtFrequency", "purpose"].forEach((el) =>
    validateExistance(values, `offer.${el}`, errors)
  );

  // companyAddress
  ["civic", "street", "city", "province", "zip"].forEach((el) => {
    validateExistance(values, `companyAddress.${el}`, errors);
  });
  validateZip(values, `companyAddress.zip`, errors);

  // guarantors
  values.user.ownersAttributes.forEach((el, index) => {
    validateEmail(values, `user.ownersAttributes[${index}].email`, errors);
    validatePhoneNumber(values, `user.ownersAttributes[${index}].phoneMobile`, errors);
    validatePercentOwnership(values, `user.ownersAttributes[${index}].ownerPercent`, errors);
    
    validateExistance(values, `user.ownersAttributes[${index}].birthdate`, errors);
    validateAge(values, `user.ownersAttributes[${index}].birthdate`, errors);

    ["firstName", "lastName", "email", "birthdate", "cpr"].forEach((el) => {
      validateExistance(values, `user.ownersAttributes[${index}].${el}`, errors);
      if(el === 'cpr') {
        validateCpr(values, `user.ownersAttributes[${index}].cpr`, errors, 'SSN')
      }
    });

    // guarantors
    ["civic", "street", "city", "province", "zip"].forEach((el) => {
      validateExistance(
        values,
        `user.ownersAttributes[${index}].addressAttributes.${el}`,
        errors
      );
      if(el === 'zip') {
        validateZip(values, `user.ownersAttributes[${index}].addressAttributes.zip`, errors);
      }
    });
  });

  validateGuarantorsOwnership(values, errors);

  // files
  ["bankStatements"].forEach((el) => {
    validateExistance(values, `files.${el}`, errors);
  });

  // console.log(errors);
  return isEmpty(errors) ? {} : errors;
}

function generateUid(length) {
  let result = "";
  const characters = "0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return `usr${result}`;
}

export const generateFakeSSN = () => {
  const val = generateUid(11).replace("usr", "");
  return `${val.slice(0, 2)}-${val.slice(3, 6)}-${val.slice(6, 10)}`;
};

const birthdateValue = (month, year) => [year, month, "01"].join("-");

export function buildLoanApplicationPayload(originalValues) {
  const result = {};

  const values = cloneDeep(originalValues);

  result.company = values.company;

  const [month, year] = result.company.businessStartDate.split("/");
  result.company.businessStartYear = year;
  result.company.businessStartMonth = month;
  result.company.birthdate = birthdateValue(month, year);
  if (result.company.industryCategory === null ||
      typeof result.company.industryCategory === "undefined" ||
      !result.company.industryCategory.length) {
    result.company.industryCategory = "undetermined";
  }
  if (result.company.industry === null ||
      typeof result.company.industry === "undefined" ||
      !result.company.industry.length) {
    result.company.industry = "undetermined";
  }

  result.offer = values.offer;
  result.offer.purpose = result.offer.purpose.toString();
  result.offer.pmtFrequency = result.offer.pmtFrequency.toString();
  result.offer.lengthMax = result.offer.lengthMax.toString();

  result.companyAddress = values.companyAddress;
  result.companyAddress.civic = result.companyAddress.civic.toString();
  result.companyAddress.zip = result.companyAddress.zip.toString();

  result.companyAddress.province = result.companyAddress.province.toString();

  result.type  = "save_business_information";
  result.source = "broker_wholesale";
  result.next = "Wait";
  result.brokerId = values.brokerId;
  result.skipOwners = true;
  result.dontSetupOwnersAddresses = true;

  const addressAttributes = [];
  const genIds = values.user.ownersAttributes.map((e, index) => {
    return generateUid(9);
  });

  // build payload for user and owner attributes / address
  genIds.forEach((uid, index) => {
    result.user = result.user || {};
    result.user.ownersAttributes = result.user.ownersAttributes || {};

    // Format civic and zip to be string values
    values.user.ownersAttributes[index].addressAttributes.civic = values.user.ownersAttributes[
      index
    ].addressAttributes.civic.toString();
    values.user.ownersAttributes[index].addressAttributes.zip = values.user.ownersAttributes[
      index
    ].addressAttributes.zip.toString();

    values.user.ownersAttributes[index].addressAttributes.province = values.user.ownersAttributes[
      index
    ].addressAttributes.province.toString();

    // extract address to make new map
    addressAttributes.push(values.user.ownersAttributes[index].addressAttributes);
    // delete values.user.ownersAttributes[index].addressAttributes;

    result.user.ownersAttributes[`${uid}`] = values.user.ownersAttributes[index] || {};
    result.user.ownersAttributes[`${uid}`].permalink = uid;
    if(index == 0) {
      result.user.ownersAttributes[`${uid}`].primary_guarantor = 0;
    }

    result.owner = result.owner || {};
    result.owner.addressAttributes = result.owner.addressAttributes || {};
    result.owner.addressAttributes[`${uid}`] = addressAttributes[index] || {};
    result.owner.addressAttributes[`${uid}`].civic = result.owner.addressAttributes[
      `${uid}`
    ].civic.toString();

    result.owner.addressAttributes[`${uid}`].unit = (
      result.owner.addressAttributes[`${uid}`]?.unit || ""
    ).toString();

    result.owner.addressAttributes[`${uid}`].zip = result.owner.addressAttributes[
      `${uid}`
    ].zip.toString();
    delete result['user']['ownersAttributes'][`${uid}`]['addressAttributes']
  });

  if(genIds.length > 0 ) {
    result.user.primary_guarantor = genIds[0];
  }


  delete result['company']['businessStartDate'];

  result.note = {};
  values.note?.forEach((note, index) => {
    note['payoff'] =  note['payoff'] ? "yes" : "no"
    result.note[index] = note;
  })
  result.files = values.files;

  ///
  // Remove this after testing
  // result.company.firstName = faker.name.firstName();
  // result.company.cpr = generateFakeSSN();
  // result.company.email = faker.internet.email();
  ///
  ///
  // console.log(JSON.stringify(result));

  return result;
}

function normalizeCPR(str) {
  return str.replaceAll(/[^0-9]/g, '');
}

export function fieldsGetter(...names) {
  return (values) => names.reduce((acc, name) => {
    const value = get(values, name);
    if (value) {
      acc[name] = value;
    }
    return acc;
  }, {})
}

export async function validateEIN(checkEINFunc, values, successCb) {
  const ein = normalizeCPR(get(values, 'company.cpr'));
  if (ein.length !== 9) return;

  const { success, error, application } = await checkEINFunc(ein);
  if(success && successCb !== null) {
    if(application !== null) {
      successCb(application);
    }
  }else {
    return error;
  }
}

export async function validateEINMatchSSN(values) {
  const ein = normalizeCPR(get(values, 'company.cpr'));
  if (ein.length !== 9) return;

  const guarantors = get(values, 'user.ownersAttributes');
  if (!guarantors?.length) return;

  const found = guarantors.findIndex(({ cpr }) =>
    cpr && normalizeCPR(cpr) === ein
  );
  if (found >= 0) return errorStatusCodes.EINMatchSSN.error;
}

export async function validateSoleGroup(checkSolePropFunc, values) {
  const typeInc = get(values, 'company.typeInc');
  const province = get(values, 'companyAddress.province');
  const amount = get(values, 'offer.amount');
  const ein = get(values, 'company.cpr');
  if (!typeInc || !province || !ein) return;
  if (typeInc !== 'inc_type_sole_proprietorship') return;

  const { success, error } = await checkSolePropFunc(province, typeInc, amount);
  if (!success) return error;
}
