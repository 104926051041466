import React, { createContext, useMemo, useState, useCallback, useContext, useEffect } from "react";
import cloneDeep from "lodash/cloneDeep";
import { initialValuesEmpty } from "../../components/App/Partner/Applications/loanApplicationInitialValues";
import { useApplicationLookups } from "./useApplicationLookups";
import { normalizeValues } from "./utils";
import snakecaseKeys from "snakecase-keys";

export const PROCESS_STATE_READY = 'ready';
export const PROCESS_STATE_PROCESSING = 'processing';
export const PROCESS_STATE_COMPLETE = 'complete';

export const NewApplicationContext = createContext(undefined);

export function useNewApplicationStore() {
  return useContext(NewApplicationContext);
}

async function initFiles(fileValues, setFiles) {
  if (!fileValues?.length) return;

  await Promise.all(
    fileValues.map((file) =>
      fetch(file.file)
        .then((resp) => resp.blob())
        .then((blob) => {
          const start = file.file.indexOf(':') + 1;
          const end = file.file.indexOf(';');
          const type = file.file.substring(start, end);
          return new File([blob], file.file_name, { type, lastModified: new Date() });
        })
    )
  ).then(setFiles);
}

export const NewApplicationProvider = ({ children }) => {
  const [lastAppId, setLastAppId] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [processState, setProcessState] = useState(PROCESS_STATE_READY);
  const [industryChecked, setIndustryChecked] = useState(false);
  const toggleIndustryChecked = useCallback(() => setIndustryChecked((checked) => !checked));
  const [bankStatements, setBankStatements] = useState([]);
  const [otherFiles, setOtherFiles] = useState([]);
  const [signedApps, setSignedApps] = useState([])
  const [initialValues, setInitialValues] = useState(initialValuesEmpty);
  const [conflictResult, setConflictResult] = useState(null);
  const [alerts, setAlerts] = useState([]);
  const [lastAlert, setLastAlert] = useState(null);
  const [appSource, setAppSource] = useState(null);

  const {
    companyTypes,
    loanReasons,
    loanTerms,
    paymentFrequencies,
    loaded: applicationLookupsLoaded,
  } = useApplicationLookups();

  const addAlert = useCallback((newAlerts) => {
    newAlerts.forEach((newAlert) => {
      const index = alerts.findIndex(({ id }) => newAlert.id === id);
      if (index < 0) {
        setAlerts((alerts) => [...alerts, newAlert])
      } else {
        setAlerts(
          (alerts) => alerts.splice(alerts.indexOf(newAlert), 1));
      }
    });
  }, [alerts, setAlerts, setLastAlert]);

  const hideAlert = useCallback((...alertIds) => {
    let newAlerts = alerts;
    alertIds.forEach((alertId) => {
      const index = newAlerts.findIndex(({ id }) => alertId === id);
      newAlerts = index >= 0 ? [...newAlerts.slice(0, index), ...newAlerts.slice(index + 1)] : newAlerts;
    });
    setAlerts(newAlerts);
    setLastAlert(null);
  }, [alerts, setAlerts, setLastAlert]);

  function reset() {
    setLoading(false);
    setProcessState(PROCESS_STATE_READY);
    setIndustryChecked(false);
    setBankStatements([]);
    setOtherFiles([]);
    setSignedApps([])
    setConflictResult(null);
    setAlerts([]);
    setLastAlert(null);
    setAppSource(null);
  }

  const clearInitialValues = useCallback(() => {
    reset();
    setInitialValues(cloneDeep(initialValuesEmpty))
  }, [setInitialValues]);

  const resetInitialValues = useCallback((values, source) => {
    reset();

    values = cloneDeep(values)

    // normalize value keys to snake case 'like_this'
    values = snakecaseKeys(values, { deep: true });

    // normalize undefined values
    normalizeValues(values);

    if (source) {
      setAppSource(source);
    }

    setInitialValues(values);

    initFiles(values.files.bank_statements, setBankStatements);
    initFiles(values.files.other, setOtherFiles);
    initFiles(values.files.signed_application, setSignedApps);
  }, [setInitialValues]);

  useEffect(() => {
    if (applicationLookupsLoaded) {
      setInitialized(true)
    }
  }, [applicationLookupsLoaded])

  return (
    <NewApplicationContext.Provider value={{
      initialized,
      lastAppId, setLastAppId,
      loading, setLoading,

      processing: processState === PROCESS_STATE_PROCESSING,
      finishProcessing: processState === PROCESS_STATE_COMPLETE,
      setProcessState,

      industryChecked, setIndustryChecked, toggleIndustryChecked,
      bankStatements, setBankStatements,
      otherFiles, setOtherFiles,
      signedApps, setSignedApps,
      initialValues, setInitialValues,
      conflictResult, setConflictResult,
      appSource,
      withTextract: appSource === 'textract',
      lastAlert, alerts, hideAlert, addAlert,

      companyTypes,
      loanReasons,
      loanTerms,
      paymentFrequencies,

      resetInitialValues, clearInitialValues,
    }}>
      {children}
    </NewApplicationContext.Provider>
  );
};
