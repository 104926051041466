import { Box, Grid, Button, Slider, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
} from "@mui/icons-material";
import React, {  useContext, useRef, useEffect } from "react";
import NumberFormat from "react-number-format";
import { PaymentCalculatorContext } from "./context";
import TermCard from "./TermCard";

const ReadOnlyTextField = (props) => <TextField {...props} inputProps={{ readOnly:true }} />;

const PaymentFrequency = (props) => {
  const {
    offerId,
    title,
    terms,
    sliderAmount,
    setSliderAmount,
    sliderCommission,
    setSliderCommission,
    maxSliderAmount,
    maxSliderCommission,
  } = useContext(PaymentCalculatorContext);

  function valuetext(value) {
    return value;
  }

  function valueLabelFormat(value) {
    return value;
  }

  const ref = useRef();
  let grabPos = { top: 0, left: 0, x: 0, y: 0 };

  function grabScroller(scroller){

    let isGrabbing = false;
    
    function mouseMoveHandler(e){
      if(isGrabbing) {
        const dx = e.clientX - grabPos.x;
        scroller.current.scrollLeft = grabPos.left - dx;
      }
    }

    function mouseUpHandler(){
      isGrabbing = false;
    }

    function mouseDownHandler(e){
      grabPos = {
        left: scroller.current.scrollLeft,
        x: e.clientX,
    };
      isGrabbing = true;
    }
    scroller.current.addEventListener('blur', mouseUpHandler);
    scroller.current.addEventListener('mousedown', mouseDownHandler);
    scroller.current.addEventListener('mousemove', mouseMoveHandler);
    scroller.current.addEventListener('mouseup', mouseUpHandler);
  };

  useEffect(() =>{
    grabScroller(ref);
    document.querySelectorAll('.loan-calculator-scroll-container').forEach((e)=> {
      e.style.justifyContent = terms.length > 5 ? "left" : "center";
  })
  },[ref])
  
  const handleAmountChange = (_e, value) => {
    setSliderAmount(value);
  };
  const handleComissionChange = (_e, value) => {
    setSliderCommission(value);
  };

  return (
    <>
      <Grid className="loan-calculator-header" item align="center">
        {title}
      </Grid>
      <Grid className="loan-calculator-scroll-container" ref={ref} container>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          className="loan-calculator-card-container"
          alignItems="center"
        >
          
          {terms.map((i, index) => (
            <TermCard
              key={index}
              term={i}
              title={title}
              isSelected={props?.selectedTerm?.id === i.id}
              isDirty={props.selectedTerm?.sliderAmount !== i.amount || props.selectedTerm?.sliderCommission  !== i.commission}
              readOnly={props.readOnly}
              disclosureEnabled={props.disclosureEnabled}
              open={props.open}
              openDisclosure={props.openDisclosure}
              usaState={props.usaState}
            />
          ))}

        </Grid>
      </Grid>

      { terms.length > 0 && <>
      <Box mb={2}>
        <Grid
          className="loan-slider-container"
          container
          item
          xs={12}
          md={12}
          spacing={4}
          alignItems="center"
        >
          <Grid item xs={12} md={2} className="bold">
            Loan Amount
          </Grid>
          <Grid item xs={6} md={6}>
            <Slider
              value={sliderAmount}
              valueLabelFormat={valueLabelFormat}
              getAriaValueText={valuetext}
              aria-labelledby="discrete-slider-restrict"
              step={1000.0}
              onChange={handleAmountChange}
              min={props.minimumAmount}
              max={maxSliderAmount}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <NumberFormat
              thousandSeparator
              decimalScale={0}
              prefix="$"
              onValueChange={(values) => {
                const { value } = values;
                const floatVal = Math.floor(Number.parseFloat(value));
                setSliderAmount(floatVal);
              }}
              customInput={TextField}
              id="amount"
              name="amount"
              className="formElement loan-calculator-input"
              type="tel"
              value={sliderAmount}
              autoComplete="loanAmount"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box mb={2}>
        <Grid
          className="loan-slider-container"
          container
          item
          xs={12}
          md={12}
          spacing={4}
          alignItems="center"
        >
          <Grid item xs={12} md={2} className="bold">
            Commission
          </Grid>
          <Grid item xs={6} md={6}>
            <Slider
              value={sliderCommission}
              valueLabelFormat={valueLabelFormat}
              getAriaValueText={valuetext}
              aria-labelledby="discrete-slider-restrict"
              step={0.25}
              onChange={handleComissionChange}
              min={0}
              max={maxSliderCommission}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <NumberFormat
              thousandSeparator
              suffix="%"
              onValueChange={(values) => {
                const { value } = values;
                const floatVal = Number.parseFloat(value);
                setSliderCommission(floatVal);
              }}
              customInput={ReadOnlyTextField}
              id="commission"
              name="commission"
              className="formElement loan-calculator-input"
              type="tel"
              value={sliderCommission}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </Box>
      </> }
    </> 
  );
};

export default PaymentFrequency;
