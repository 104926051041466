import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import OfferDetails from "./OfferDetails";
import InfoModal, { defaultStyles } from "../../../shared/modals/InfoModal";
import { deepmerge } from '@mui/utils';
import useOfferDetails from './useOfferDetails';

const useStyles = makeStyles((theme) => deepmerge(
  defaultStyles(theme),
  {
    paper: {
      maxWidth: "90vw",
      width: "90vw",
    }
  }
))

const OfferDetailsDialog = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { offerId } = useParams();

  const offerDetails = useOfferDetails(offerId)

  const handleClose = () => {
    navigate("/dashboard");
  };  

  return (
    <InfoModal
      open={true}
      onClose={handleClose}
      title={offerDetails.offer?.merchant?.legal_name || "Loading..."}
      classes={classes}
    >
      <OfferDetails {...offerDetails} />
    </InfoModal>
  );
};

export default OfferDetailsDialog;
