import { Button, Grid, TextField } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Formik } from "formik";
import React from "react";
import { sendResetPasswordInstructions } from "../context/context";
import Logo from "../images/iou_financial_logo.png";
import { Title } from "./App/styled";
import { useNavigate, Link } from "react-router-dom";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: "200px",
      padding: "20px 20px 20px 20px",
      backgroundColor: "#fff",
    },
    mainLogo: {
      marginBottom: "10px",
      width: "100%",
    },
    inputField: {
      padding: "20px",
    },
    submitButton: {},
    loginLink: {
      fontSize: '10px',
      marginTop: '40px',
    }
  })
);

const ForgotPassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const submit = async (values, { setErrors, setSubmitting, resetForm }) => {
    const { email } = values;
    try {
      await sendResetPasswordInstructions(email)
      resetForm();
      setSubmitting(false);
      navigate("/login", { state: { notice: "Reset password instructions have been sent to your email address" } });
    } catch (error) {
      setErrors({
        email: "Invalid email address",
      });
      console.log(error);
    }
  }

  const validation = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }
    return errors;
  };

  return (
    <Grid container spacing={0} align="center" justifyContent="center">
      <Grid item className={classes.root}>
        <img className={classes.mainLogo} width="100%" src={Logo} alt="IOU Financial Logo" />
        <Formik
          initialValues={{
            email: "",
          }}
          validate={validation}
          onSubmit={submit}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <Grid>
              <form onSubmit={handleSubmit}>
                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
                  <Grid container item sm={6}>
                    <Title my={2}>Forgot Password</Title>
                    <TextField
                      id="email"
                      label="Email"
                      type="email"
                      placeholder="Enter email address"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      helperText={touched.email && errors.email}
                      autoComplete="current-email"
                      variant="outlined"
                      InputLabelProps={{ shrink: true, style: { color: "#000000" } }}
                    />
                  </Grid>

                  <Grid item>
                    <Button
                      className="button-primary"
                      fullWidth
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Send me reset password instructions
                    </Button>
                  </Grid>
                  <Grid item>
                    <Link to="/login">Return to login</Link>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
