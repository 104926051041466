import React, { useState } from "react"
import { Formik } from "formik";
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import AttachmentDropzone from "../../../../Applications/AttachmentDropzone";
import { usePostAPI } from "../../../../../../../shared/useAPI";

const documentTypeOptions = [
  { value: "bankStatements", label: "Bank Statements" },
  { value: "driversLicense", label: "Driver License" },
  { value: "other", label: "Other Documents" },
  { value: "voidCheck", label: "Void Check" },
]

const initialValues = {
  files: documentTypeOptions.reduce((acc, { value }) => {
    acc[value] = []
    return acc
  })
}

const UploadView = ({ offerId, onCancel, onUpload }) => {
  const [attachmentType, setAttachmentType] = useState('bankStatements')
  const [files, setFiles] = useState([])
  const { callAPI } = usePostAPI(`/api/internal/v1/offers/${offerId}/documents`)

  const onSubmit = async (values, { resetForm }) => {
    body = {
      document: {
        document_type: attachmentType,
        files: values.files[attachmentType],
      }
    }

    const { data } = await callAPI(body)
    onUpload(data)
    setFiles([])
    setAttachmentType('bankStatements')
    resetForm(initialValues)
  }

  return (
    <Grid container>
      <Grid container justifyContent="space-between">
        <Typography
          variant="h2"
          sx={{
            marginTop: 1,
            marginBottom: 1,
          }}
        >Documents</Typography>
        <Grid item>
          <DocumentTypeSelect
            disabled={!!files.length}
            value={attachmentType}
            onChange={(_val, optVal) => setAttachmentType(optVal.value)}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 2 }}>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ setFieldValue, isSubmitting, handleSubmit }) => (
            <>
              <AttachmentDropzone
                setFieldValue={setFieldValue}
                files={files}
                setFiles={setFiles}
                buttonText="Add Documents"
                attachmentType={attachmentType}
              />

              <Grid container alignItems="center" justifyContent="flex-end" sx={{ mt: 2 }}>
                <Button
                  className="button-outline"
                  variant="contained"
                  disabled={isSubmitting}
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Grid item sx={{ ml: 2 }}>
                  <Button
                    className="button-primary"
                    variant="contained"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </Grid>
    </Grid>
  )
}

const DocumentTypeSelect = ({ disabled, value, onChange }) => {
  return (
    <Autocomplete
      sx={{ width: 300 }}
      options={documentTypeOptions}
      autoHighlight
      getOptionLabel={(option) => option.label}
      value={documentTypeOptions.find((opt) => opt.value === value)}
      onChange={onChange}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Document Type"
        />
      )}
    />
  );
}

export default UploadView