import { TextField } from "@mui/material";

import Autocomplete from '@mui/material/Autocomplete';
import { getIn } from "formik";
import React, { useMemo } from "react";
import isEmpty from "lodash";
import { observer } from "mobx-react";

const BLANK_LABEL = "– None –";

const LoanAppAutoComplete = observer(
  ({
    form,
    label,
    value,
    required,
    fieldName,
    handleBlur,
    setInputValue,
    setFieldValue,
    optionValues,
    placeholder,
    disableClearable,
    filterOptions,
  }) => {
    const options = useMemo(() => [
      {label: BLANK_LABEL, value: ""},
      ...Object.keys(optionValues).map((val) => optionValues[val])
    ], [optionValues])

    return (
      <Autocomplete
        disableClearable={disableClearable}
        disablePortal
        autoSelect
        isOptionEqualToValue={(option, value) => option.value === value?.value}
        onBlur={handleBlur}
        onChange={(_, value) => setFieldValue(fieldName, value?.value || "")}
        onInputChange={(_, newInputValue) => {
          if (setInputValue) setInputValue(newInputValue);
          form.setFieldTouched(fieldName, false);
        }}
        name={fieldName}
        value={options.find((opt) => opt.value === value) ?? options[0]}
        getOptionLabel={(option) => option?.label || ""}
        options={options}
        filterOptions={filterOptions}
        renderInput={(params) => {
          if (params.inputProps.value === BLANK_LABEL) params.inputProps.value = "";
          return (
            <TextField
              {...params}
              label={label}
              required={required}
              onBlur={() => {
                form.setFieldTouched(fieldName, isEmpty(value));
              }}
              placeholder={placeholder}
              error={!!(getIn(form.touched, fieldName) && getIn(form.errors, fieldName))}
              helperText={getIn(form.touched, fieldName) && getIn(form.errors, fieldName)}
              InputLabelProps={{shrink: true}}
            />
          )
        }}
      />
    );}
);

export default LoanAppAutoComplete;
