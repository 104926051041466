import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import LoanApplicationForm from "./LoanApplicationForm";
import { NewApplicationProvider } from "../../../../shared/NewApplication";
import { ModalQueueProvider } from "../../shared/modals/queue";
import { Title } from "../../styled";

const useStyles = makeStyles((theme) => ({
  newLoanContainer: {
    marginTop: "20px",
  },
}));

const NewApplication = () => {
  const classes = useStyles();

  return (
    <ModalQueueProvider>
      <NewApplicationProvider>
        <div className={classes.newLoanContainer}>
          <Title gutterBottom>New Loan Application</Title>
          <LoanApplicationForm />
        </div>
      </NewApplicationProvider>
    </ModalQueueProvider>
  );
};
export default NewApplication;
