import axios from 'axios';
import { fetchEnv } from '../../../env';

async function streetLookup(params) {
  const key = fetchEnv('SMARTY_EMBEDDED_KEY');
  return axios({
    method: 'get',
    url: 'https://us-street.api.smartystreets.com/street-address',
    params: {
      key,
      ...params,
    }
  });
}

async function lookup(params) {
  const key = fetchEnv('SMARTY_EMBEDDED_KEY');
  return axios({
    method: 'get',
    url: 'https://us-autocomplete-pro.api.smartystreets.com/lookup',
    params: {
      key,
      source: 'all',
      ...params
    }
  });
}

export async function fetchRecommendations(addrValues) {
  if (!addrValues?.street) return;

  const params = constructRecommendationParams(addrValues);
  const { data: { suggestions } } = await lookup(params);
  return formatRecommendations(suggestions, params.search);
}

export async function fetchExpandedRecommendations(recommendationRow) {
  const { street_line, secondary, entries, city, state, zipcode } = recommendationRow;
  const params = {
    search: recommendationRow.search,
    selected: [
      street_line,
      secondary,
      `(${entries})`,
      city,
      state,
      zipcode
    ].filter((x) => x).join(' ')
  };

  const { data: { suggestions } } = await lookup(params);
  return formatRecommendations(suggestions).map(({ entries, ...sugg }) => sugg);
}

export async function fetchStreetInfo(reco) {
  const {
    street_line,
    secondary,
    city,
    state,
    zipcode
  } = reco;

  const { data: [rawResult] } = await streetLookup({
    street: street_line,
    secondary,
    city,
    state,
    zipcode,
    candidates: 1,
    match: 'enhanced',
  });

  return formatStreetInfoResult(rawResult);
}

function formatStreetInfoResult(raw) {
  const {
    delivery_line_1,
    components: {
      primary_number,
      city_name,
      state_abbreviation,
      zipcode: zip,
      secondary_designator,
      secondary_number,
    }
  } = raw;

  const unit = [
    secondary_designator, secondary_number
  ].filter((x) => x).join(' ')

  let street = delivery_line_1.
    replace(new RegExp(`^${primary_number} `), '');

  if (unit) {
    street = street.replace(new RegExp(` ${unit}$`), '')
  }

  return {
    civic: primary_number,
    street,
    unit,
    city: city_name,
    province: state_abbreviation,
    zip,
    country_id: "1",
  };
}

function constructRecommendationParams(addrValues) {
  const { civic, street, unit, city, province, zip } = addrValues;
  const params = {
    search: [civic, street, unit].filter((s) => s).join(' '),
  }
  if (zip?.length === 5) {
    params.prefer_zip_codes = zip;

  } else if (city?.length && province?.length === 2) {
    params.prefer_cities = `${city},${province}`;

  } else if (province?.length === 2) {
    params.prefer_states = province;
  }
  return params;
}

function formatRecommendations(suggestions, search_param) {
  return suggestions.map((suggestion) => {
    const { street_line, secondary, city, state, zipcode, entries } = suggestion;
    return {
      ...suggestion,
      search: search_param,
      description: [
        street_line,
        secondary,
        (secondary && entries > 1 ? `(${entries} entries)` : null),
        [city, state].join(', '),
        zipcode
      ].filter((v) => v).join(' '),
    };
  });
}
