import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  TextField,
  Typography,
  css,
} from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';
import Grid from "@mui/material/Grid";
import camelcaseKeys from "camelcase-keys";
import { Field, FieldArray, Formik, getIn } from "formik";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import NumberFormat from "react-number-format";
import InformationLinkModal from "../../shared/modals/informationLinkModal.js";
import BusinessInformationLinkModal from "../../shared/modals/businessInformationLinkModal.js";
import LoanInformationLinkModal from "../../shared/modals/loanInformationLinkModal.js";
import OtherLoanInformationLinkModal from "../../shared/modals/otherLoanInformationLinkModal.js";
import GuarantorInformationLinkModal from "../../shared/modals/guarantorInformationLinkModal.js";
import BusinessDocsInformationLinkModal from "../../shared/modals/businessDocsInformationLinkModal.js";
import AccountExecutiveInfo from "../../shared/AccountExecutiveInfo/AccountExecutiveInfo";
import AttachmentDropzone from "./AttachmentDropzone";
import TextractDropzone from "../../shared/TextractDropzone";
import { FieldMaskWrapper, FieldWrapper } from "./FieldHelpers";
import GuarantorInformation from "./GuarantorInformation";
import Loan from "./Loan";
import { initialValuesTouched } from "./loanApplicationInitialValues";
import { validationHelper } from "./validationHelper";
import LoanAppAutoComplete from "../../shared/IouAutocomplete";
import ConflictNoticeModal from "../../shared/modals/ConflictNoticeModal";
import ProcessingApplicationModal from "../../shared/modals/ProcessingApplicationModal.js";
import FormSubmitButton from "./FormSubmitButton";
import { useNavigate, useParams } from "react-router-dom";
import {
  optionsArray,
  useNewApplicationStore,
  canSaveApplication,
  useAppSubmission,
  useLoadSavedApplication,
} from "../../../../shared/NewApplication";
import AddressAutoComplete from "./AddressAutoComplete";
import { useAuth } from "../../../../context/context";
import StateAutocomplete from "../../shared/StateAutocomplete.js";
import EINTextField from "./EINTextField.js";
import { useModalQueue } from "../../shared/modals/queue.js";
import { useLiveValidations } from "../../../../shared/NewApplication/useLiveValidations.js";
import { Heading1, Heading2 } from "../../styled/index.js";

const useStyles = makeStyles((theme) => ({
  root: {},
  requiredMessage: {
    alignSelf: "flex-end",
    color: theme.palette.error.main,
  },
  certifyBizMessage: {
    alignSelf: "flex-end",
  },
  saveBtn: {
    marginRight: "20px !important",
  },
  previousBtn: {
    marginRight: "20px",
    paddingRight: "55px",
  },
  disabledButton: {
    backgroundColor: theme.palette.primary || "red",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  alert: { width: "100%", marginTop: 10 },
  dialogContent: { textAlign: "center" },
  dialogActions: { justifyContent: "center" },
}));

const scrollIntoView = (event) => {
  const topOffset = 51
  const bottomOffset = 100
  const windowHeight = window.innerHeight
  const { top, bottom } = event.target.getBoundingClientRect()

  if (bottom > windowHeight - bottomOffset) {
    event.target.scrollIntoView({ behavior: 'smooth', block: 'center' })
  } else if (top < topOffset) {
    event.target.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}

const LoanApplicationForm = observer(() => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { appId, offerId } = useParams();

  const {
    initialized,

    bankStatements, setBankStatements,
    otherFiles, setOtherFiles,

    industryChecked,
    toggleIndustryChecked,

    initialValues,
    clearInitialValues,

    loading,
    processing,
    finishProcessing,

    conflictResult, setConflictResult,
    appSource,
    alerts,
    hideAlert,
    lastAlert,

    companyTypes,
    loanReasons,
    loanTerms,
    paymentFrequencies,
  } = useNewApplicationStore();
  const { nextModal } = useModalQueue();

  useLoadSavedApplication(appId, offerId);

  useEffect(() => {
    if (lastAlert) {
      window.scrollTo(0, 0);
    }
  }, [lastAlert])

  const { submitApp, saveApplication } = useAppSubmission(appId);
  const { appError, runLiveValidation } = useLiveValidations();

  const { user: { on_warning, app_import_enabled } } = useAuth();

  if (!initialized) {
    return <></>
  }

  return <>
    <Formik
      validateOnMount
      enableReinitialize
      initialTouched={appSource != null && camelcaseKeys(initialValuesTouched, {deep: true})}
      initialValues={camelcaseKeys(initialValues, { deep: true })}
      validate={
        async (values) => {
          runLiveValidation(values);
          return(validationHelper(values));
        }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setTimeout(async () => {
          try {
            const success = await submitApp(values);
            if (success) {
              resetForm();
              window.scrollTo(0, 0);
            }
          } finally {
            setSubmitting(false);
          }
        }, 400);
      }}
      onChange={() => { }}
    >
      {({ isValid, values, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
        return (
          <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={9}>
                <Grid container spacing={3}>

                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      >
                      <Heading1>Business Information</Heading1>
                      <Box ml={1} className="flex"><BusinessInformationLinkModal /></Box>
                    </Grid>

                    {!!alerts.length && (
                      <Grid item xs={12}>
                        {alerts.reverse().map(({ id, severity, message, canClose }) => (
                          <Alert
                            key={id}
                            severity={severity}
                            className={classes.alert}
                            onClose={canClose ? () => hideAlert(id) : null}
                            >
                            {message}
                          </Alert>
                        ))}
                      </Grid>
                    )}

                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      className={classes.requiredMessage}
                      >
                      <Typography variant="caption">* Indicates required field</Typography>
                    </Grid>

                    <Box my={2}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        wrap="nowrap"
                        >
                        <FormControl required className="checkbox" sx={css`flex-shrink: 0;`}>
                          <Checkbox
                            type="checkbox"
                            name="industryCheckbox"
                            checked={industryChecked}
                            onChange={() => {
                              toggleIndustryChecked();
                              setFieldValue();
                            }}
                          />
                        </FormControl>
                        <Typography
                          variant="body2"
                          component="p"
                          sx={css`
                            color: red;
                            margin-left: 0.5em;
                            margin-right: 0.5em;
                          `}
                          >
                          I certify that this business is not within IOU Financial’s prohibited
                          industries list.&nbsp;
                        </Typography>
                        <InformationLinkModal />
                      </Grid>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <EINTextField {...{
                                    values,
                                    handleChange,
                                    handleFocus: scrollIntoView,
                                    handleBlur,
                                    appError,
                                  }} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FieldWrapper
                      id="companyFirstName"
                      label="Business Name"
                      changeHandler={handleChange}
                      focusHandler={scrollIntoView}
                      blurHandler={handleBlur}
                      values={values}
                      placeholder="Legal Business Name"
                      formKey="company.firstName"
                      autoComplete="businessName"
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FieldWrapper
                      id="companyLastName"
                      label="Business DBA Name"
                      changeHandler={handleChange}
                      focusHandler={scrollIntoView}
                      blurHandler={handleBlur}
                      values={values}
                      placeholder="Business DBA Name"
                      formKey="company.lastName"
                      autoComplete="businessDBAName"
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Field name="company.typeInc">
                      {({ form }) => (
                        <LoanAppAutoComplete
                          form={form}
                          label="Company Type"
                          value={values.company.typeInc}
                          required={true}
                          fieldName="company.typeInc"
                          placeholder="Select Company Type"
                          handleFocus={scrollIntoView}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          disableClearable={true}
                          optionValues={companyTypes}
                        />
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <FieldMaskWrapper
                      type="tel"
                      id="businessStartDate"
                      label="Business Start Date"
                      changeHandler={handleChange}
                      focusHandler={scrollIntoView}
                      blurHandler={handleBlur}
                      values={values}
                      formKey="company.businessStartDate"
                      autoComplete="businessStartDate"
                      required
                      placeholder="MM/YYYY"
                      inputMask="99/9999"
                    />
                  </Grid>

                  <Grid item xs={12} sm={5}>
                    <FieldWrapper
                      type="url"
                      id="companyUrl"
                      label="Business Website"
                      changeHandler={handleChange}
                      focusHandler={scrollIntoView}
                      blurHandler={handleBlur}
                      values={values}
                      placeholder="https://"
                      formKey="company.url"
                      autoComplete="businessUrl"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FieldMaskWrapper
                      type="tel"
                      id="companyPhoneNumber"
                      label="Business Phone Number"
                      changeHandler={handleChange}
                      focusHandler={scrollIntoView}
                      blurHandler={handleBlur}
                      values={values}
                      placeholder="Advertised Business Phone Number"
                      formKey="company.phoneWork"
                      autoComplete="businessPhoneNumber"
                      required
                      inputMask="(999)-999-9999"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FieldWrapper
                      type="email"
                      id="companyEmail"
                      label="Business Email"
                      changeHandler={handleChange}
                      focusHandler={scrollIntoView}
                      blurHandler={handleBlur}
                      values={values}
                      placeholder="Business Email"
                      formKey="company.email"
                      autoComplete="businessEmail"
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Field name="companyAddress.civic">
                      {({ form }) =>
                        <AddressAutoComplete
                          name="companyAddress.civic"
                          value={getIn(values, 'companyAddress.civic')}
                          label="Business Street Number"
                          onChange={handleChange}
                          onFocus={scrollIntoView}
                          onBlur={handleBlur}
                          placeholder="Business Street Number"
                          form={form}
                          setFieldValue={setFieldValue}
                          fieldPrefix="companyAddress."
                          />
                      }
                    </Field>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FieldWrapper
                      id="companyStreetName"
                      label="Business Street Name"
                      changeHandler={handleChange}
                      focusHandler={scrollIntoView}
                      blurHandler={handleBlur}
                      values={values}
                      placeholder="Business Street Name"
                      formKey="companyAddress.street"
                      autoComplete="businessStreetName"
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FieldWrapper
                      id="companyUnit"
                      label="Building, Suite, Floor"
                      changeHandler={handleChange}
                      focusHandler={scrollIntoView}
                      blurHandler={handleBlur}
                      values={values}
                      placeholder="Building, Suite, Floor"
                      formKey="companyAddress.unit"
                      autoComplete=""
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FieldWrapper
                      id="companyCity"
                      label="City"
                      changeHandler={handleChange}
                      focusHandler={scrollIntoView}
                      blurHandler={handleBlur}
                      values={values}
                      placeholder="City"
                      formKey="companyAddress.city"
                      autoComplete="businessCity"
                      required
                    />
                  </Grid>

                  <Grid item xs={6} sm={4}>
                    <Field name="companyAddress.province">
                      {({ form }) => (
                        <StateAutocomplete
                          form={form}
                          value={values.companyAddress.province}
                          required={true}
                          fieldName="companyAddress.province"
                          handleFocus={scrollIntoView}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                        />
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs={6} sm={4}>
                    <FieldWrapper
                      type="tel"
                      id="companyZip"
                      label="ZIP Code"
                      changeHandler={handleChange}
                      focusHandler={scrollIntoView}
                      blurHandler={handleBlur}
                      values={values}
                      placeholder="ZIP Code"
                      inputProps={{ maxLength: 5 }}
                      formKey="companyAddress.zip"
                      autoComplete="businessZip"
                      required
                    />
                  </Grid>

                  <Grid item xs={12} />

                  <Grid container item xs={12} sm={12}>
                    <Heading1>Loan Information</Heading1>
                    <Box ml={1} className="flex"><LoanInformationLinkModal /></Box>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <Field name="offer.amount">
                      {({ form }) => (
                        <FormControl className="formElement">
                          <NumberFormat
                            required
                            thousandSeparator
                            decimalSeparator={false}
                            prefix="$"
                            onValueChange={(values) => {
                              const { value } = values;
                              const floatVal = Number.parseFloat(value).toFixed(2);
                              setFieldValue(
                                "offer.amount",
                                value.match(/\./) ? floatVal : value,
                                false
                              );
                            }}
                            customInput={TextField}
                            id="loanAmount"
                            name="offer.amount"
                            className="formElement"
                            label="Loan Amount"
                            placeholder="$10k to $500k"
                            type="tel"
                            // onChange={handleChange}
                            onFocus={scrollIntoView}
                            onBlur={handleBlur}
                            value={values.offer.amount}
                            error={
                              !!(
                                getIn(form.touched, "offer.amount") &&
                                getIn(form.errors, "offer.amount")
                              )
                            }
                            helperText={
                              getIn(form.touched, "offer.amount") &&
                              getIn(form.errors, "offer.amount")
                            }
                            autoComplete="loanAmount"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                          />
                        </FormControl>
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <Field name="offer.purpose">
                      {({ form }) => (
                        <LoanAppAutoComplete
                          form={form}
                          label="Reason for Loan"
                          value={values.offer.purpose}
                          required={true}
                          fieldName="offer.purpose"
                          placeholder="Select Reason"
                          handleFocus={scrollIntoView}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          disableClearable={true}
                          optionValues={loanReasons}
                        />
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <Field name="offer.pmtFrequency">
                      {({ form }) => (
                        <LoanAppAutoComplete
                          form={form}
                          label="Payment Frequency"
                          value={values.offer.pmtFrequency}
                          required={true}
                          fieldName="offer.pmtFrequency"
                          placeholder="Select Frequency"
                          handleFocus={scrollIntoView}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          disableClearable={true}
                          optionValues={paymentFrequencies}
                        />
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <Field name="offer.lengthMax">
                      {({ form }) => (
                        <LoanAppAutoComplete
                          form={form}
                          label="Loan Term"
                          value={values.offer.lengthMax}
                          required={true}
                          fieldName="offer.lengthMax"
                          placeholder="Select Term"
                          handleFocus={scrollIntoView}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          disableClearable={true}
                          optionValues={loanTerms}
                        />
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs={12}>
                    <FieldWrapper
                      multiline
                      minRows={7}
                      id="loanDescription"
                      label="Additional Details"
                      changeHandler={handleChange}
                      focusHandler={scrollIntoView}
                      blurHandler={handleBlur}
                      values={values}
                      placeholder="Description"
                      formKey="offer.description"
                      autoComplete="offerDescription"
                      required
                    />
                  </Grid>

                  <Grid container item xs={12}>
                    <Heading1>Other Loan Balances</Heading1>
                    <Box ml={1} className="flex"><OtherLoanInformationLinkModal /></Box>
                  </Grid>

                  <Grid item xs={12}>
                    <FieldArray name="note">
                      {({ form, remove, push }) => (
                        <>
                          {values?.note?.length > 0 &&
                            values.note.map((lender, index) => (
                              <Loan
                              key={index}
                              handleChange={handleChange}
                              handleFocus={scrollIntoView}
                              handleBlur={handleBlur}
                              getFieldMeta={form.getFieldMeta}
                              setFieldValue={setFieldValue}
                              index={index}
                              values={values}
                              value={lender}
                              handleRemove={() => remove(index)}
                              />
                            ))}
                          <Grid item xs={12}>
                            <Button
                              className="button-outline"
                              variant="contained"
                              onClick={() => push({payoff: false})}
                            >
                              + Add Loan
                            </Button>
                          </Grid>
                        </>
                      )}
                    </FieldArray>
                  </Grid>

                  <Grid item xs={12} />

                  <Grid container item xs={12} sm={12}>
                    <Heading1>Guarantor Information</Heading1>
                    <Box ml={1} className="flex"><GuarantorInformationLinkModal /></Box>
                  </Grid>

                  <FieldArray name="user.ownersAttributes">
                    {({ form, push, remove }) => (
                      <>
                        {values.user?.ownersAttributes?.length &&
                          values.user.ownersAttributes.map((owner, index) => (
                            <GuarantorInformation
                              key={index}
                              handleChange={handleChange}
                              handleFocus={scrollIntoView}
                              handleBlur={handleBlur}
                              getFieldMeta={form.getFieldMeta}
                              setFieldValue={setFieldValue}
                              index={index}
                              removeGuarantor={() => remove(index)}
                              value={owner}
                              values={values}
                            />
                          ))}
                        <Grid item xs={12}>
                          <Button
                            className="button-outline"
                            variant="contained"
                            onClick={() => push({})}
                          >
                            + Add Guarantor
                          </Button>
                        </Grid>
                      </>
                    )}
                  </FieldArray>

                  <Grid item xs={12} />

                  <Grid container item xs={12} sm={12}>
                    <Heading1>Business Documents</Heading1>
                    <Box ml={1} className="flex"><BusinessDocsInformationLinkModal /></Box>
                  </Grid>

                  <Grid item xs={12} sm={7}>
                    Please upload the required documentation (example: bank statements) into the drag and drop areas.
                  </Grid>

                  <Grid container item xs={12} sm={6}>
                    <Grid item xs={12}>
                      <Heading2>Bank Statements</Heading2>
                      <InputLabel shrink required>
                        Bank statements are required for submission.
                      </InputLabel>
                      <Box mt={1}>
                        <AttachmentDropzone
                          setFieldValue={setFieldValue}
                          files={bankStatements}
                          setFiles={setBankStatements}
                          buttonText="Add Documents"
                          attachmentType="bankStatements"
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} sm={6}>
                    <Grid item xs={12}>
                      <Heading2>Other Documents</Heading2>
                      <InputLabel shrink>
                        Include files that will assist the evaluation.
                      </InputLabel>
                      <Box mt={1}>
                        <AttachmentDropzone
                          setFieldValue={setFieldValue}
                          files={otherFiles}
                          setFiles={setOtherFiles}
                          buttonText="Add Documents"
                          attachmentType="other"
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} className="sticky-footer" direction="row-reverse" pb={3}>
                    {!on_warning && (
                      <FormSubmitButton id="submit-button"
                        isValid={isValid}
                        industryChecked={industryChecked}
                        handleSubmit={handleSubmit} />
                    )}
                    <Button
                      className={`button-outline ${classes.saveBtn}`}
                      variant="contained"
                      disabled={!canSaveApplication(values)}
                      onClick={() => saveApplication(values)}
                    >
                      Save Changes
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box width="100%">
                  <AccountExecutiveInfo />
                </Box>
                {app_import_enabled && (
                  <Box width="100%">
                    <TextractDropzone />
                  </Box>
                )}
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>

    {conflictResult &&
      <ConflictNoticeModal
        open={true}
        onClose={() => {
          setConflictResult(null)
          nextModal()
        }}
        conflict={conflictResult}
      />}

    {processing &&
      <ProcessingApplicationModal />}

    <Backdrop
      className={classes.backdrop}
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading || finishProcessing}
    >
      {finishProcessing ? (
        <Dialog
          open
          onClose={() => { }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            classes={{ root: classes.dialogContent }}
          >
            Application Uploaded
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              classes={{ root: classes.dialogContent }}
            >
              Your application was saved and is processing
            </DialogContentText>
          </DialogContent>
          <DialogActions classes={{ root: classes.dialogActions }}>
            <Button
              onClick={() => {
                if (appId && appId !== 'new') {
                  navigate('/newApplication/new');
                } else {
                  clearInitialValues();
                }
              }}
            >
              Enter New Application
            </Button>
            <Button onClick={() => navigate('/dashboard')} autoFocus>
              View Dashboard
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <CircularProgress color="inherit" />
      )}
    </Backdrop>
  </>;
});

export default LoanApplicationForm;
