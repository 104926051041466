import React, { useEffect } from "react";
import { useGetAPI } from "../../../../shared/useAPI";
import InfoModalLink from "./InfoModaLink";

const InformationLinkModal = () => {
  const { data, callAPI } = useGetAPI('/api/internal/v1/restricted_industries');

  useEffect(() => { callAPI() }, []);

  return (
    <InfoModalLink title="IOU Financial’s Prohibited Industry List">
      <h5 className="bold">
        IOU Financial will not lend to the following industries under any circumstances:
      </h5>
      <ul>
        {data?.prohibited?.map((nm, index) => <li key={`prohibited-${index}`}>{nm}</li>)}
      </ul>
      <h5 className="bold">
        IOU Financial will lend to the following industries on a case-by-case basis:
      </h5>
      <ul>
        {data?.case_by_case?.map((nm, index) => <li key={`casebycase-${index}`}>{nm}</li>)}
      </ul>
    </InfoModalLink>
  );
};

export default InformationLinkModal;
