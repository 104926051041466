import React from "react";
import { useAuth } from "../../context/context";
import { useLocation, Navigate } from "react-router-dom"

function RequireAuth({ staff, children }) {
  let { initializing, loggedIn, user } = useAuth();
  let location = useLocation();

  if (initializing) return <></>

  if (!loggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (staff) {
    if (!user.is_iou_employee) {
      return <Navigate to="/" state={{ from: location }} replace />;
    }
  } else {
    if (user.is_iou_employee) {
      return <Navigate to="/staff/home" state={{ from: location }} replace />;
    }
  }

  return children;
}

export default RequireAuth;
