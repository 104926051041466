import faker from "@faker-js/faker";

// const docChecklist = {
//   doc_checklist: {
//     0: {
//       bank_statements: "on",
//       research_document: "on",
//       signed_application: "on",
//       street_view: "on",
//     },
//     1: {
//       credit_authorization: "on",
//       debit_authorization_agreement: "on",
//       drivers_license: "on",
//       ein_letter: "on",
//       judgement_lien_info: "on",
//       lease_agreement_or_mtg: "on",
//     },
//     2: {
//       lease_agreement_or_mtg: "on",
//       loan_purpose: "on",
//       marketing_agreement: "on",
//       merchant_statements: "on",
//       month_to_date: "on",
//     },
//   },
// };

export const original = {
  company: {
    first_name: "T29.7",
    last_name: "T29.7",
    cpr: "57-4138134",
    email: "asf2@4a.com",
    phone_work: "(333) 323-4234 ext. 23412",
    url: "addda.com",
    industry_category: "administrative ",
    industry: "appraisers",
    type_inc: "inc_type_corporation",
    business_start_month: "6",
    business_start_year: "1939",
  },
  user: {
    owners_attributes: {
      usr546231879: {
        permalink: "usr546231879",
        first_name: "Dmon",
        last_name: "YYYYG",
        phone: "(312) 325-2442",
        phone_mobile: "(122) 243-4343",
        email: "asf2@zys.com",
        birthdate: "12/18/1971",
        cpr: "241-53-1556",
        owner_percent: "100",
        primary_guarantor: "0",
      },
    },
    primary_guarantor: "usr546231879",
  },
  owner: {
    address_attributes: {
      usr546231879: {
        civic: "30032",
        street: "Bran9",
        unit: "10",
        city: "Bg",
        country_id: "1",
        province: "GA",
        zip: "30092",
      },
    },
  },
  type: "save_business_information",
  source: "broker_wholesale",
  next: "Wait",
  broker_id: "w464-171-77025",
  skip_owners: true,
  dont_setup_owners_addresses: true,
  offer: {
    description: "Loan Purpose",
    length_max: "6",
    amount: "10000",
    purpose: "Expansion",
    pmt_frequency: "Daily",
  },
  company_address: {
    civic: "1000",
    street: "Abbbadataba",
    unit: "10",
    city: "Atlanta",
    province: "GA",
    zip: "30222",
  },
  note: [{}, {}, {}],
};

const formatDateField = (date) => {
  const res = date.split("/");
  return `${res[2]}-${res[0]}-${res[1]}`;
};
// owners_attributes, owner.address_attributes is HASH not array
// birthdate must be transformed into format // "12/18/1971" does not conform to the required format, "yyyy-MM-dd".
function generateUid(length) {
  let result = "";
  const characters = "0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return `usr${result}`;
}
function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
export const generateFakeSSN = () => {
  const val = generateUid(11).replace("usr", "");
  return `${val.slice(0, 3)}-${val.slice(3, 5)}-${val.slice(6, 10)}`;
};

export const initialValues = {
  company: {
    first_name: faker.company.companyName(),
    last_name: faker.name.lastName(),
    cpr: "99-" + getRandomInt(9000000, 9999999),
    email: faker.internet.email(),
    phone_work: "(333) 323-4234",
    url: "mywebsite.com",
    industry_category: "administrative ",
    industry: "appraisers",
    type_inc: "inc_type_corporation",
    business_start_month: "6",
    business_start_year: "1990",
    business_start_date: "06/2002",
  },
  user: {
    owners_attributes: [
      {
        permalink: "usr546231879",
        first_name: "Homer",
        last_name: "Simpson",
        phone: "(312) 325-2442",
        phone_mobile: "(122) 243-4343",
        email: "hsimpson@h00001.com",
        birthdate: formatDateField("12/18/1971"),
        // cpr: "241-53-2001",
        // cpr: "123-43-2423",
        cpr: generateFakeSSN(9),
        owner_percent: "100",
        primary_guarantor: "0",
        address_attributes: {
          civic: "742",
          street: "Evergreen Terrace",
          unit: "1",
          city: "Springfield",
          country_id: "1",
          province: "IL",
          zip: "62629",
        },
      },
      // {
      //   permalink: "usr546231878",
      //   first_name: "Marge",
      //   last_name: "Simpson",
      //   phone: "(312) 325-2442",
      //   phone_mobile: "(122) 243-4343",
      //   email: "msimpson@h00001.com",
      //   birthdate: formatDateField("12/18/1971"),
      //   // cpr: "241-53-2002",
      //   cpr: generateFakeSSN(9),
      //   owner_percent: "0",
      //   primary_guarantor: "0",
      //   address_attributes: {
      //     civic: "742",
      //     street: "Evergreen Terrace",
      //     unit: "1",
      //     city: "Springfield",
      //     country_id: "1",
      //     province: "IL",
      //     zip: "62629",
      //   },
      // },
    ],
    primary_guarantor: "usr546231879",
  },
  type: "save_business_information",
  source: "broker_wholesale",
  next: "Wait",
  broker_id: "w464-171-77025",
  skip_owners: true,
  dont_setup_owners_addresses: true,
  offer: {
    description: "Rebuilding",
    length_max: "6",
    amount: "45000",
    purpose: "Expansion",
    pmt_frequency: "Daily",
  },
  company_address: {
    civic: "1000",
    street: "Hammond Drive",
    unit: "200",
    city: "Atlanta",
    province: "GA",
    zip: "30222",
  },
  note: [],
  files: {
    bank_statements: [],
    other: [],
    void_check: [],
    drivers_license: [],
    signed_application: [],
  },
};

export const initialValuesEmpty = {
  company: {
    first_name: "",
    last_name: "",
    cpr: "",
    email: "",
    phone_work: "",
    url: "",
    industry_category: "",
    industry: "",
    type_inc: "",
    business_start_month: "",
    business_start_year: "",
    business_start_date: "",
  },
  user: {
    owners_attributes: [
      {
        permalink: "",
        first_name: "",
        last_name: "",
        phone: "",
        phone_mobile: "",
        email: "",
        birthdate: "",
        cpr: "",
        owner_percent: "",
        primary_guarantor: "0",
        address_attributes: {
          civic: "",
          street: "",
          unit: "",
          city: "",
          country_id: "1",
          province: "",
          zip: "",
        },
      },
    ],
    primary_guarantor: "",
  },
  // owner: {
  //   address_attributes: [],
  // },
  type: "save_business_information",
  source: "broker_wholesale",
  next: "Wait",
  broker_id: "w464-171-77025",
  skip_owners: true,
  dont_setup_owners_addresses: true,
  offer: {
    description: "",
    length_max: "",
    amount: "",
    purpose: "",
    pmt_frequency: "",
  },
  company_address: {
    civic: "",
    street: "",
    unit: "",
    city: "",
    province: "",
    zip: "",
  },
  note: [],
  files: {
    bank_statements: [],
    other: [],
    void_check: [],
    drivers_license: [],
    signed_application: [],
  },
};

export const initialValuesTouched = {
  company: {
    first_name: true,
    last_name: true,
    cpr: true,
    email: true,
    phone_work: true,
    url: true,
    industry_category: true,
    industry: true,
    type_inc: true,
    business_start_month: true,
    business_start_year: true,
    business_start_date: true,
  },
  user: {
    owners_attributes: [
      {
        permalink: true,
        first_name: true,
        last_name: true,
        phone: true,
        phone_mobile: true, 
        email: true,
        birthdate: true,
        cpr: true,
        owner_percent:true, 
        primary_guarantor: true,
        address_attributes: {
          civic: true,
          street: true,
          unit: true,
          city: true,
          country_id: true,
          province: true,
          zip: true,
        },
      },
      {
        permalink: true,
        first_name: true,
        last_name: true,
        phone: true,
        phone_mobile: true, 
        email: true,
        birthdate: true,
        cpr: true,
        owner_percent:true, 
        primary_guarantor: true,
        address_attributes: {
          civic: true,
          street: true,
          unit: true,
          city: true,
          country_id: true,
          province: true,
          zip: true,
        },
      },
    ],
    primary_guarantor: "",
  },
  // owner: {
  //   address_attributes: [],
  // },
  offer: {
    description: true,
    length_max: true,
    amount:  true,
    purpose: true,
    pmt_frequency: true,
  },
  company_address: {
    civic: true,
    street: true,
    unit: true,
    city: true,
    province: true,
    zip: true,
  },
  note: [],
  files: {
    bank_statements: [],
    other: [],
    void_check: [],
    drivers_license: [],
    signed_application: [],
  },
};
