import React from "react";
import { css } from "@emotion/react";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  Box,
  Typography,
  Paper
} from "@mui/material";
import { currency, date, phoneNumber } from "../../shared/format";
import { useLoansAPI } from "../../../../shared/useLoansAPI";
import { useNavigate } from "react-router-dom";
import { useMagicLink } from "../../../../shared/useMagicLink";
import { fetchEnv } from "../../../../env";
import loanCalcIcon from "../../../../../../../app/assets/images/loan-calc-icon.png";
import { max } from "lodash";


const offerDetailsEnabled = fetchEnv('OFFER_DETAILS');

export default ({ dateRange, metric }) => {
  const navigate = useNavigate();
  const { loans, loading } = useLoansAPI({ dateRange, metric });
  const { redirectTo } = useMagicLink();
  return (
    <>
      {loading &&
        <CircularProgress color="inherit" />}
      {!loading && !!loans?.length &&
        <TableContainer>
          <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              css={css`
                border-collapse: separate;
                border-spacing: 0 15px;
              `}>
            <TableBody>
              {loans.map((row) => (
                <TableRow
                  css={css`
                    background-color: #f2f3f4;
                  `}
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.merchantName} <br />
                    <a href={`mailto:${row.merchantEmail}`}>{row.merchantEmail}</a><br />
                    {phoneNumber(row.merchantPhone)}
                  </TableCell>
                  <TableCell>
                    {currency(row.originalAmount)} / {row.effectiveApprovedAmount}
                    {row.renewalStatus && " Renewable"}
                  </TableCell>
                  <TableCell>
                    <strong>{row.status}</strong><br />
                    Submitted: {date(row.applicationDate)}<br />
                    {row.isSubmittedByWorker && `By: ${row.submittedBy}`}
                  </TableCell>
                  <TableCell className="no-wrap">
                    {row.sourceCode && (
                      <>
                      <Button
                        className="button-secondary"
                        onClick={() => {
                          if (offerDetailsEnabled) {
                            navigate(`/Dashboard/app/${row.id}`)
                          } else {
                            redirectTo('loan', [row.sourceCode, row.id].join('/'))
                          }
                        }}
                        variant="contained"
                      >
                        View Application
                      </Button>

                      {row.status === "APPROVED" && row.isUnderwritingComplete && 
                        <Button
                        className="button-no-outline"
                        onClick={() => {
                          if (offerDetailsEnabled) {
                            navigate(`/Dashboard/app/${row.id}#loanCalculatorAnchor`)
                          } else {
                            redirectTo('loan', [row.sourceCode, row.id].join('/'))
                          }
                        }}
                        variant="contained"
                      >
                        <img className="loan-calc-icon" src={loanCalcIcon} />
                      </Button>
                      }
                      </>
                    )}
                    {" "}
                    {row.renewalStatus && (
                      <Box mt={1}>
                        <Button
                          className="button-secondary m-t"
                          variant="contained"
                          onClick={() => navigate(`/newApplication/renew/${row.id}`)}
                        >
                          Renew Loan
                        </Button>
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>
                    {" "}
                      { row.isExpress &&  <>
                        <Paper elevation={0} 
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontWeight: 'bold',
                          marginTop: '1em',
                          marginBottom: '1em',
                          maxWidth: '100px',
                          backgroundColor: 'rgba(102, 178, 255, 0.15)',
                          padding: '0.5em',
                          cursor: 'pointer',
                        }}
                        >
                          <Typography fontWeight='bold' variant="body2" color="rgba(65, 148, 202, 1)">Express</Typography>
                        </Paper>
                        </>
                      }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>}
    </>
  );
};