import { MenuItem, MenuList, Popper, Paper } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import makeStyles from '@mui/styles/makeStyles';
import AccountCircle from "@mui/icons-material/AccountCircle";
import { observer } from "mobx-react";
import React, { useContext } from "react";
import { AuthStateContext } from "../../context/context";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  activeLink: {
    backgroundColor: "#C1C1C1",
  },
  uniformLink: {
    backgroundColor: "#fff",
  },
  title: {
    textDecoration: "none",
    color: "#fff",
  },
}));

const ProfileMenuButton = observer(() => {
  useStyles();
  const { signOut, isMasquerading, demasquerade } = useContext(AuthStateContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => setAnchorEl(null);

  return (
    <span>
      <IconButton
        aria-label="account of current user"
        data-cy="dashboard-user-profile-button"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenuOpen}
        size="large">
        <AccountCircle />
      </IconButton>
      <Popper open={open} anchorEl={anchorEl} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList>
                  {isMasquerading && (
                    <MenuItem onClick={demasquerade}>
                      Unmask
                    </MenuItem>
                  )}
                  <MenuItem data-cy="sign-out" onClick={signOut}>
                    Sign Out
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </span>
  );
});

export default ProfileMenuButton;
