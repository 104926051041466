import { useCallback } from "react";
import { useGetAPI } from "./useAPI";

export function useMagicLink() {
  const { callAPI } = useGetAPI();

  const redirectTo = useCallback(async (linkType, pathParams) => {
    const resp = await callAPI({ url: `/api/internal/v1/magic_links/${linkType}/${pathParams}`});
    document.location.href = resp.data.magic_link;
  });

  return {
    redirectTo,
  };
}