import { useGetAPI } from "./useAPI";

export function useVerifyAuthenticatedURLAPI(offer_id, signature, exp) {
  const {
    data,
    loading,
    callAPI: verifyAuthenticatedURL,
  } = useGetAPI(`/api/internal/v1/offers/${offer_id}/verifications/${signature}?exp=${exp}`)

  return { data, loading, verifyAuthenticatedURL }
}