import { useEffect } from "react";
import { OfferSummaryModel } from "../models/Offer";
import { useGetAPI } from "./useAPI";

export function useLoansAPI({ metric, dateRange: [start_date, end_date] }) {
  const { data, loading, callAPI } = useGetAPI(
    "/api/internal/v1/dashboard/loans",
    { params: { start_date, end_date, metric } }
  );

  useEffect(() => {
    if (start_date && end_date && metric) {
      callAPI();
    }
  }, [start_date, end_date, metric]);

  return {
    loading,
    loans: data?.brokerDashboardLoans?.map((loan) => new OfferSummaryModel(loan)),
  };
}