import { Checkbox, TextField, IconButton, FormControlLabel } from "@mui/material/";
import Grid from "@mui/material/Grid";
import makeStyles from '@mui/styles/makeStyles';
import { observer } from "mobx-react";
import React from "react";
import { Close } from "@mui/icons-material/";
import { FieldMaskWrapper, FieldWrapper } from "./FieldHelpers";

const useStyles = makeStyles(() => ({
  formControl: {
    // margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
  otherLenders: {
    marginBottom: "10px",
  },
}));

const Loan = observer(
  ({ 
    values,
    index,
    handleRemove,
    handleChange,
    handleFocus,
    handleBlur,
    value,
    setFieldValue,
    getFieldMeta,
  }) => {
  const classes = useStyles();

  return <>
    <Grid container spacing={3} className={classes.otherLenders}>
      <Grid item xs={12} sm={3}>
        <FieldWrapper
          id="lenderName"
          label="Name"
          changeHandler={handleChange}
          focusHandler={handleFocus}
          blurHandler={handleBlur}
          values={values}
          placeholder="Lender Name"
          formKeyPrefix={`note[${index}].`}
          formKey="lender"
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <FieldWrapper
          id={`lenderBalance-${index}`}
          label="Balance"
          changeHandler={handleChange}
          focusHandler={handleFocus}
          blurHandler={handleBlur}
          values={values}
          placeholder="Lender Balance"
          formKeyPrefix={`note[${index}].`}
          formKey="balance"
        />

      </Grid>

      <Grid item xs={7} sm={3}>
        <FieldWrapper
          id={`lenderFrequency-${index}`}
          label="Frequency"
          changeHandler={handleChange}
          focusHandler={handleFocus}
          blurHandler={handleBlur}
          values={values}
          placeholder="Lender Frequency"
          formKeyPrefix={`note[${index}].`}
          formKey="frequency"
        />
      </Grid>

      <Grid item xs={3} sm={2}>
        <FormControlLabel
          control={
            <Checkbox
              id={`lenderPayoff-${index}`}
              name={`note[${index}].payoff`}
              onChange={handleChange}
              color="primary"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          }
          label="Payoff"
        />
      </Grid>

      <Grid item xs={2} sm={1}>
        <IconButton
          id={`lender-remove-${index}`}
          className="button-round-sm button-danger"
          variant="contained"
          onClick={handleRemove}
          size="large">
          <Close />
        </IconButton>
      </Grid>
    </Grid>
  </>;
});

export default Loan;
