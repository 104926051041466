import { Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useAuth } from "../../../../context/context";
import { BrokersAutocomplete } from "./BrokersAutocomplete";

const useStyles = makeStyles((theme) => ({
}));

export default Masquerade = () => {
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { masqueradeAs } = useAuth()

  const masquerade = async (_, broker) => {
    setIsSubmitting(true)
    try {
      return await masqueradeAs(broker)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Grid container direction="column">
      <Grid item className={classes.section}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h1">
              Masquerade
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <BrokersAutocomplete onChange={masquerade} disabled={isSubmitting} />
      </Grid>
    </Grid>
  );
};