import { Grid } from "@mui/material/";
import AppBar from "@mui/material/AppBar";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../logo.png";

const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarTitle: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '20px',
    marginRight: '20px',
  },
  logo: {
    width: "200px",
    marginTop: "4px",
    marginLeft: "3px",
  },
  menuButton: {
    // marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  divider: {
    margin: "0 8px",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  activeLink: {
    backgroundColor: "#C1C1C1",
  },
  globalMenuDesktop: {
    "@media (max-width:986px)": {
      display: "none",
    },
  },
  globalMenuButton: {
    display: "none",
    "@media (max-width:986px)": {
      display: "block",
    },
  },
  betaAlert: {
    marginBottom: 20
  }
}));

export default function BaseDrawer({leftControl, titleControl, rightControl}) {
  const classes = useStyles();
  const [open] = React.useState(false);

  const Logo = () => (
    // Import result is the URL of your image
    <img className={classes.logo} src={logo} alt="Logo" />
  );

  return (
    <AppBar
      color="inherit"
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Grid container justifyContent="space-between">
        <Grid item>
          <Grid container justifyContent="flex-start">
            <Grid item className={classes.globalMenuButton}>
              {leftControl}
            </Grid>
            <Link to="/" className={classes.title}>
              <Logo />
            </Link>
          </Grid>
        </Grid>
        <Grid item className={classes.appBarTitle}>
          {titleControl}
        </Grid>
        <Grid item align="right" className={classes.globalMenuDesktop}>
          {rightControl}
        </Grid>
      </Grid>
    </AppBar>
  );
}
