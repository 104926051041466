import React, { useMemo } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

function getModalStyle() {
  const top = 10;

  return {
    top: `${top}vh`,
    left: `0`,
  };
}

export const defaultStyles = (theme) => ({
  paper: {
    position: "absolute",
    overflowY: "hidden",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 0, 3),
    marginLeft: "auto",
    marginRight: "auto",
  },
})

const useStyles = makeStyles(defaultStyles);

const DisclosureModal = ({ open, onClose, children, classes = null, dialogProps = {} }) => {
  if (!classes) {
    classes = useStyles();
  }

  const modalStyle = useMemo(getModalStyle, []);
  return (
    <Dialog
      classes={classes}
      style={modalStyle}
      open={open}
      onClose={(_, reason) => { if (reason !== 'backdropClick') onClose() }}
      disableEscapeKeyDown={true}
      {...dialogProps}
    >
      <DialogContent sx={{ paddingX: 4 }}>
        {React.isValidElement(children) ? children : <div />} {/* Ensure children is a valid React node */}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>I Agree</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DisclosureModal;