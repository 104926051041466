import React, { createContext, useEffect, useState } from "react";
import _ from 'lodash';

export const recalculate = (offer, terms, sliderAmount, sliderCommission, netToggle=false) => {
  if(offer.is_express) {
    return recalculateExpress(offer, terms, sliderAmount, sliderCommission);
  } else {
    return recalculateStandard(terms, sliderAmount, sliderCommission);
  }
}

const recalculateExpress = (offer, terms, sliderAmount, sliderCommission) => {
  terms.forEach((term) => {
    term.sliderAmount = sliderAmount < term.amount ? sliderAmount : term.amount;
    term.sliderCommission = sliderCommission < term.commission ?  sliderCommission : term.commission;
    term.brokerCommission = (((term.sliderCommission / 100) * term.sliderAmount) || 0);
    term.buyRate = term.buyRate;
    term.sellRate = term.buyRate + (term.sliderCommission / 100);
    term.totalPayback = term.sellRate * term.sliderAmount;
    term.totalDailyPayment = term.totalPayback / term.paymentCount;
    term.renewalDiscount = term.renewalDiscount
    if(term.netLoanAmount) {
      term.netLoanAmountTotal = term.sliderAmount - (term.amount - term.netLoanAmount)
      if(term.netLoanAmountTotal < 0 ) {
        term.netLoanAmountTotal = 0.0
      }
    } else {
      term.netLoanAmountTotal = term.netLoanAmount
    }

    term.netLoanAmountSlider =
      term.sliderAmount * (1 - (term.uwNetOriginationFee ?? 0)); 
    term.netBuyRate = term.buyRate
    term.netSellRate = term.sellRate
    term.totalNetPayback = term.sellRate * term.netLoaAmountSlider;
    term.totalNetDailyPayment = term.netLoanAmountSlider / term.paymentCount;
  });
  return(terms);  
}

const recalculateStandard = (terms, sliderAmount, sliderCommission) => {
  terms.forEach((term) => {
    term.sliderAmount = sliderAmount < term.amount ? sliderAmount : term.amount;
    term.sliderCommission = sliderCommission < term.commission ?  sliderCommission : term.commission;
    term.brokerCommission = (((term.sliderCommission / 100) * term.sliderAmount) || 0);
    term.uwGfee = (term.gFee / 100) * term.sliderAmount;

    term.buyRate = 1 + ((term.gFee + (term.originationFee - (term.uwNetOriginationFee * 100) - term.commission )) / 100)
    term.sellRate = term.buyRate + (term.sliderCommission / 100);
    term.totalPayback = term.sellRate * term.sliderAmount;
    term.totalDailyPayment = term.totalPayback / term.paymentCount;
    term.renewalDiscount = term.renewalDiscount
    if(term.netLoanAmount) {
      term.netLoanAmountTotal = term.sliderAmount - (term.amount - term.netLoanAmount)
      if(term.netLoanAmountTotal < 0 ) {
        term.netLoanAmountTotal = 0.0
      }
    } else {
      term.netLoanAmountTotal = term.netLoanAmount
    }

    term.interestExpense = term.uwGfee;
    term.netLoanAmountSlider =
      term.sliderAmount * (1 - (term.uwNetOriginationFee ?? 0)); 
    term.netBuyRate = term.buyRate
    term.netSellRate = term.sellRate
    term.totalNetPayback = term.sellRate * term.netLoaAmountSlider;
    term.totalNetDailyPayment = term.netLoanAmountSlider / term.paymentCount;
  });
  return(terms);
}

function computedOriginationFee(term) {
  return originationFeeRatio(term.uwGrossOriginationFee, term.commission) + (term.sliderCommission / 100);
}

function originationFeeRatio(originationFee, commission) {
  return originationFee/100 - commission/100;
}

function findMin(attribute, termsArray) { // 6,9,12,15...
   return Math.min(...termsArray.map((term) => term[attribute]));
 }

 export function findMax(attribute, termsArray) { // 6,9,12,15...
   return Math.max(...termsArray.map((term) => term[attribute]));
 }

 function findTitle(type) {
   if(type === 'daily') {
    return 'Daily'
  } else if(type === 'monthly') {
    return 'Monthly'
  } else if(type === 'weekly') {
    return 'Weekly'
  } else if(type === 'bi-weekly') {
    return 'Bi-Weekly'
  } else {
    throw new Error('Type is not available');
  }
 }

 function findIndexByField(terms, field, obj) {
    return(terms.reduce(function(result, value, key) {
      if(value[field] === obj[field]) result.push(value)
      return result;
    }, []));
 }

 function buildMaxPositionedTerms(terms) {
  const result = [];
  terms.forEach((obj) => {
    const processed = findIndexByField(result, 'termLength', obj);
    if(!!processed.length) return;
    
    const indexes = findIndexByField(terms, 'termLength', obj);
    const maxPosition = findMax('positionNum', indexes.filter((e) => e.isVisible));
    if(isFinite(maxPosition))
      result.push(terms[_.keys(_.pickBy(terms, {
          termLength: obj.termLength, 
          positionNum: maxPosition }
        ))]);
  });
  return result;
 }

 function visibleTerms(terms) {
    return terms.filter((e) => e.isVisible)
 }

export const PaymentCalculatorContext = createContext(undefined);
export const PaymentCalculatorProvider = ({oId, offerParam, initialData: {type, terms: initalTerms}, children}) => {
  const [offerId, _setOfferId] = useState(oId);
  const [offer, _setOffer] = useState(offerParam);
  const [title, _setTitle] = useState(findTitle(type));

  const visibleTermsMap = visibleTerms(initalTerms) || [];
  const [maxSliderAmount, _setMaxAmount]  = useState(findMax('amount', visibleTermsMap));
  const [minSliderAmount, _setMinAmount]  = useState(findMin('amount',visibleTermsMap));
  const [maxSliderCommission, _setMaxCommission]  = useState(findMax('commission', visibleTermsMap));
  const [minSliderCommission, _setMinCommission]  = useState(findMin('commission', visibleTermsMap));

  const [sliderAmount, setSliderAmount] = useState(maxSliderAmount); // this is the amountSlider
  const [sliderCommission, setSliderCommission] = useState(maxSliderCommission); // this is the commissionSlider

  const [terms, setTerms] = useState(recalculate(offer, visibleTerms(initalTerms), sliderAmount, sliderCommission));

  useEffect(() => {
    setTerms(recalculate(offer, terms, sliderAmount, sliderCommission));
  }, [sliderCommission, sliderAmount])

  return(<PaymentCalculatorContext.Provider value={{
    offerId, offer, title,
    maxSliderAmount, minSliderAmount,
    maxSliderCommission, minSliderCommission,
    sliderAmount, setSliderAmount,
    sliderCommission, setSliderCommission,
    terms, setTerms,
  }}>{children}</PaymentCalculatorContext.Provider>);
}