import { useCallback } from "react";
import { useDeleteAPI } from "../useAPI";

export function useDeleteSavedApplication() {
  const { callAPI, ...rest } = useDeleteAPI();

  const deleteSavedApplication = useCallback(async (appId) => {
    return await callAPI({ url: `/api/internal/v1/applications/${appId}` });
  }, [callAPI]);

  return { deleteSavedApplication, ...rest };
}
