import React, { useEffect, useState } from "react";
import { format as _timeAgo } from "timeago.js";
import {
  Box,
  Button,
  Grid,
  Link,
  Paper,
  Skeleton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import Upload from "@mui/icons-material/Upload";
import UploadView from "./UploadView";
import OfferSection from "../OfferSection";
import { Heading1 } from "../../../../../styled";

const OfferDocuments = ({ offerId, loading, documents }) => {
  const [docList, setDocList] = useState(documents || [])
  const [uploadMode, setUploadMode] = useState(false)

  useEffect(() => {
    setDocList(documents || [])
  }, [documents])

  const onUpload = ({documents: newDocuments}) => {
    setDocList((prev) => [ ...newDocuments, ...prev])
    setUploadMode(false)
  }

  return loading ? (<SectionSkeleton />) : (
    <OfferSection>
      {!uploadMode && (
        <Grid container>
          <Grid container justifyContent="space-between">
            <Heading1
              sx={{
                marginTop: 1,
                marginBottom: 1,
              }}>
              Documents
            </Heading1>
            <Button
              className="button-outline"
              variant="contained"
              onClick={() => { setUploadMode(true) }}
            >
              Upload <Upload />
            </Button>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="bold secondary">Uploaded</TableCell>
                  <TableCell className="bold secondary">File name</TableCell>
                  <TableCell className="bold secondary">By</TableCell>
                  <TableCell className="bold secondary">Category</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!!docList.length && docList.map(({ id, created_on, filename, uploaded_by, checklist }) => (
                  <TableRow key={id}>
                    <TableCell>{created_on}</TableCell>
                    <TableCell sx={{ overflowWrap: 'anywhere' }}>
                      <Link target="_blank" href={`/offers/${offerId}/documents/${id}`}>{filename}</Link>
                    </TableCell>
                    <TableCell>{uploaded_by}</TableCell>
                    <TableCell>{checklist.join(', ')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}

      {uploadMode &&
        <UploadView
          offerId={offerId}
          onCancel={() => { setUploadMode(false) }}
          onUpload={onUpload}
        />}
    </OfferSection>
  )
}

const SectionSkeleton = () => {
  return (
    <Box>
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
      <Skeleton />
    </Box>
  );
}

export default OfferDocuments;