import React from 'react';
import { Typography, Paper } from '@mui/material';

const Title = ({children, ...props}) => <Typography variant="h1" {...props}>{children}</Typography>
const Heading1 = ({children, ...props}) => <Typography variant="h2" {...props}>{children}</Typography>
const Heading2 = ({children, ...props}) => <Typography variant="h3" {...props}>{children}</Typography>
const BorderedBox = ({children, ...props}) => <Paper elevation={0} className="border-custom" {...props}>{children}</Paper>

export {
  Title,
  Heading1,
  Heading2,
  BorderedBox,
}