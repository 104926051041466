import { Box, CircularProgress, Link } from "@mui/material";
import React from "react";
import { AddressRecommendationsModal } from "./AddressRecommendationsModal";
import { SampleDocumentsModalLink } from "./SampleDocumentsModalLink";
import quicksubmitLogo from "../../../../../../../app/assets/images/iou-quicksubmit-logo.png";
import FileDropzone from "../../../../shared/FileDropzone";
import { useTextract } from "./useTextract";
import { fetchEnv } from "../../../../env";
import DebugView from "./DebugView";

const MAX_FILE_SIZE = 5 * 1_048_576 // 5MB

const accept = {
  'application/pdf': ['.pdf'],
  'image/*': [],
}

const TextractDropzone = () => {
  const {
    isProcessing,
    addressRecommendationsModalProps,
    fileDropzoneProps,
    diagnostics,
    debug, setDebug,
  } = useTextract()

  return (
    <>
      <Box sx={{ mt: 3 }} component="div" className="quicksubmit-container">
        {isProcessing ?
          <CircularProgress color="inherit" /> :
          <>
            <Box sx={{ mt: 1 }}>
              <img className="quicksubmit-logo" src={quicksubmitLogo} />
            </Box>

            <FileDropzone
              dragActiveMessage=""
              message="Drag and drop your e-sign application here"
              buttonProps={{
                className: 'button-secondary',
              }}
              showFileIcon={false}
              maxSize={MAX_FILE_SIZE}
              buttonText="UPLOAD FILE"
              accept={accept}
              {...fileDropzoneProps}
            />

            <SampleDocumentsModalLink />

            <AddressRecommendationsModal {...addressRecommendationsModalProps} />
          </>
        }
      </Box>

      {fetchEnv('ENV') === 'development' &&
        !!diagnostics && (
          <>
            {debug && <DebugView diagnostics={diagnostics} />}
            {!debug && <Link variant="caption" onClick={() => setDebug(true)}>debug</Link>}
          </>
        )}
    </>
  )
}

export default TextractDropzone;
