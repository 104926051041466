import React, { useContext } from "react";
import Alert from '@mui/material/Alert';
import { Link, useNavigate } from "react-router-dom";
// import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { AuthStateContext } from "../../../context/context";
import GlobalMenuButton from "../GlobalMenuButton";
import ProfileMenuButton from "../ProfileMenuButton";
import { useUserSettings } from "../../../shared/useUserSettings";
import BaseDrawer, { useStyles } from "./BaseDrawer";

function RightControl({ loggedIn, is_iou_employee }) {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <>
      <span>
        {is_iou_employee && (
          <>
            <Link to="/staff/masquerade">Masquerade</Link>
            <span className={classes.divider}>|</span>
            <a href="/admin">Admin</a>
          </>
        )}
        {!is_iou_employee && (
          <>
            <Link to="/dashboard">Dashboard</Link>
            <span className={classes.divider}>|</span>
            <Link to="/scorecard">Scorecard</Link>
            <span className={classes.divider}>|</span>
            <Link to="/newApplication/new" data-cy="new-app" onClick={() => {
              navigate("/newApplication/new", {replace: true});
              window.location.reload();
            }}>New Application</Link>
          </>
        )}
      </span>
      {loggedIn && (
        <>
          <ProfileMenuButton />
          {/* <NotificationsActiveIcon color="disabled" /> */}
        </>
      )}
    </>
  )
}

export default function AuthedDrawer() {
  const classes = useStyles();
  const { loggedIn, user: { is_iou_employee, name: userName }, isMasquerading } = useContext(AuthStateContext);
  const {
    userSettings: { beta_message_read },
    loaded: userSettingsLoaded,
    setUserSetting,
  } = useUserSettings();

  return (
    <div>
      {userSettingsLoaded && !beta_message_read && (
        <Alert className={classes.betaAlert} severity="info" onClose={() => setUserSetting('beta_message_read')}>
          The IOU Broker portal is currently in BETA
        </Alert>
      )}

      <BaseDrawer
        leftControl={<GlobalMenuButton />}
        titleControl={isMasquerading && userName}
        rightControl={<RightControl loggedIn={loggedIn} is_iou_employee={is_iou_employee} />}
      />
    </div>
  );
}
