import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import { useAuth } from "../../../../../context/context";
import SavedApplicationsList from "./SavedApplicationsList";
import { BorderedBox } from "../../../styled";

const Home = () => {
  const { user } = useAuth();

  return (
    <Grid container direction="column" rowGap={2}>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h1">
            </Typography>
            <Box mt={1}>
              <Typography variant="h2" className="secondary bold italic">
                Welcome, {user.name}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <BorderedBox>
          <SavedApplicationsList />
        </BorderedBox>
      </Grid>
    </Grid>
  );
};

export default Home;
