import React, { useState } from "react"
import InfoModal from "./InfoModal";
import { DialogContentText, Grid, Link } from "@mui/material";
import Info from "@mui/icons-material/Info";

const InfoModalLink = ({ title, children }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Link
        className="button-no-outline"
        component="button"
        variant="body2"
        type="button"
        onClick={() => {
          setOpen(true);
        }}
      >
        <Info className="valign-middle" />
      </Link>
      <Grid>
        <InfoModal
          title={title}
          open={open}
          onClose={() => { setOpen(false) }}
          dialogProps={{ "aria-describedby": "alert-dialog-description" }}>
          <DialogContentText id="alert-dialog-description">
            {children}
          </DialogContentText>
        </InfoModal>
      </Grid>
    </>
  )
}

export default InfoModalLink;