
import React, { useState } from "react";
import { UsaStates } from "usa-states";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

export const DisclosureModal = ({ open, usaState, onClose, onSubmit }) => {
  const [submitting, setSubmitting] = useState(false);
  const usaStates = new UsaStates();

  const _onSubmit = async () => {
    setSubmitting(true)
    try {
      await onSubmit()
    } finally {
      setSubmitting(false)
      onClose()
    }
  }

  const findStateName = (stateAbb) => {
    const found = usaStates.states.filter((e) => (e['abbreviation'] === stateAbb))
    return (found.length) ? found[0]['name'] : stateAbb
  }
  const stateName = findStateName(usaState);

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-describedby="disclosure-modal"
    >
      <DialogContent>
        <DialogContentText id="disclosure-modal">
          {`Is this your final selection? ${stateName} Disclosures will be sent to you as well as your
          merchant at the email address that you provided. Per ${stateName} Commercial Financing law,
          you are responsible for making sure your Merchant receives these Disclosures.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={_onSubmit} disabled={submitting}>
          {submitting ? "Submitting..." : "Submit"}
        </Button>
        <Button onClick={onClose} disabled={submitting}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
