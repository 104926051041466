import { PROCESS_STATE_COMPLETE, PROCESS_STATE_PROCESSING, PROCESS_STATE_READY, useNewApplicationStore } from "./newApplicationContext";
import { useSubmitApplicationAPI } from "./useSubmitApplicationAPI";
import { useSaveApplication } from "./useSaveApplication";
import errorStatusCodes from "./errorStatusCodes";
import ahoy from "ahoy.js";

function determineErrorCode(stage, httpStatus) {
  if (httpStatus === 504) return 'GatewayTimeout';
}

function processError(errorData, httpStatus) {
  const { stage = 'request', error, submission_uuid } = errorData || {};
  const {
    message,
    code = determineErrorCode(stage, httpStatus),
    details = stage === 'request' ? errorData : undefined,
  } = error || {};

  const saveAppAttrs = {
    status: `${stage}_error`,
    error_details: details,
    error_code: code,
    submission_uuid,
  };

  let errorMsg = null;
  if (code) {
    if (stage === 'validation') {
      errorMsg = message;
    } else {
      errorMsg = errorStatusCodes[code]?.error;
    }
  }

  errorMsg = errorMsg || errorStatusCodes.default.error;
  const conflictResult = { error: errorMsg, saved: true };

  ahoy.track("Error Presented", { error: errorMsg, message, code, stage, error_details: details });
  return { saveAppAttrs, conflictResult };
}

export function useAppSubmission(appId) {
  const {
    setConflictResult,
    setLastAppId,
    hideAlert,
    withTextract,
    setProcessState,
  } = useNewApplicationStore();

  const { submitApplication } = useSubmitApplicationAPI();
  const { saveApplication } = useSaveApplication(appId);

  const submitApp = async function (values) {
    hideAlert("saved-app");
    setProcessState(PROCESS_STATE_PROCESSING);

    let submitSuccess = false;
    try {
      await submitApplication(
        values,
        {
          app_id: appId === "new" ? null : appId,
          app_imported: withTextract,
        }
      );

      submitSuccess = true;
      setProcessState(PROCESS_STATE_COMPLETE);
      setLastAppId(null);

    } catch (err) {
      const { message, response = {} } = err;
      const { data, status } = response;

      ahoy.track("Submission Error", { error: message, data, status });

      const { saveAppAttrs, conflictResult } = processError(data, status);

      await saveApplication(values, saveAppAttrs);

      setProcessState(PROCESS_STATE_READY);
      setConflictResult(conflictResult);
    }

    return submitSuccess;
  }

  return { submitApp, saveApplication };
}