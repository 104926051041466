import React from "react";
import { format as _timeAgo } from "timeago.js";
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useLoansAPI } from "../../../../shared/useLoansAPI";
import { date } from "../../shared/format";
import { BorderedBox, Heading1 } from "../../styled";

const useStyles = makeStyles((theme) => ({
  viewAllLink: {
    textAlign: "right",
    width: "100%",
    margin: "20px 10px",
  },
  headingMargin: {
    marginTop: 20,
    marginBottom: 30,
  }
}));

function timeAgo(v) {
  return _timeAgo(v, 'en_US')
    .replace(/^in /, '')
    .replace('just now', 'today');
}

const DealPanel = ({ metric, dateRange, limit, title, viewAllLabel, onMetricSelected }) => {
  const classes = useStyles();
  const { loading, loans } = useLoansAPI({ dateRange, metric });
  return (
    <Grid container>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>{title}</strong>
              </TableCell>
              <TableCell className="bold secondary">Loan ID</TableCell>
              <TableCell className="bold secondary">Date Entered</TableCell>
              <TableCell className="bold secondary">Expires In</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loans?.slice(0, limit)?.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.merchantName}</TableCell>
                <TableCell>{row.id}</TableCell>
                <TableCell>{date(row.applicationDate)}</TableCell>
                <TableCell className="bold danger">{timeAgo(row.creditExpirationDate)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!!loans?.length && (
        <a className={classes.viewAllLink} onClick={() => onMetricSelected(metric)}>{viewAllLabel}</a>
      )}
    </Grid>
  )
}

const DealsInPlay = ({visible, dateRange, onMetricSelected }) => {
  if(visible === false) return null;

  const classes = useStyles();
  return (
    <Grid container mt={5}>
      <Grid item>
        <Heading1 className={classes.headingMargin}>Deals In Play</Heading1>
      </Grid>
      <Grid item container spacing={8}>
        <Grid item xs={12} md={6}>
          <BorderedBox>
            <DealPanel
              metric="attention"
              dateRange={dateRange}
              limit={3}
              title="Needs Attention"
              viewAllLabel="View All Deals Needing Attention"
              onMetricSelected={onMetricSelected}
              />
          </BorderedBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <BorderedBox>
            <DealPanel
              metric="approved"
              dateRange={dateRange}
              limit={3}
              title="Approved Offers"
              viewAllLabel="View All Approved Offers"
              onMetricSelected={onMetricSelected}
              />
          </BorderedBox>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DealsInPlay;