import React, { useContext } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { RootStoreContext } from '../context/rootStoreContext';

const SnackbarImpl = () => {
  const { messages, setMessages } = useContext(RootStoreContext);

  const handleCloseAll = (_, reason) => {
    if (reason === 'clickaway') return;
    setMessages([])
  }
  const handleClose = (id) => setMessages((messages) => messages.filter((message) => message.id !== id))

  return (
    <Snackbar open={messages.length > 0} autoHideDuration={6000} onClose={handleCloseAll}>
      <div>
        {messages.map((message) => (
          <Alert key={message.id} onClose={() => handleClose(message.id)} severity={message.severity || 'success'} sx={{ width: '100%', marginTop: 1 }}>
            {message.body}
          </Alert>
        ))}
      </div>
    </Snackbar>
  )
}
export default SnackbarImpl;