import React, { useMemo } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Close from "@mui/icons-material/Close";

function getModalStyle() {
  const top = 10;

  return {
    top: `${top}vh`,
    left: `0`,
  };
}

export const defaultStyles = (theme) => ({
  paper: {
    position: "absolute",
    overflowY: "hidden",
    maxHeight: "80vh",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 0, 3),
    marginLeft: "auto",
    marginRight: "auto",
  },
})

const useStyles = makeStyles(defaultStyles);

const BootstrapDialogTitle = (props) => {
  const { children, onClose } = props;

  return (
    <DialogTitle id="alert-dialog-title" component="h1">
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          className="modal-close-btn button-outline button-round"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          size="large">
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const InfoModal = ({ open, onClose, title, children, classes = null, dialogActions = false, dialogProps = {} }) => {
  if (!classes) {
    classes = useStyles();
  }

  const modalStyle = useMemo(getModalStyle, []);

  return (
    <Dialog
      classes={classes}
      style={modalStyle}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      {...dialogProps}
    >
      <BootstrapDialogTitle onClose={onClose}>
        {title}
      </BootstrapDialogTitle>

      <DialogContent sx={{ paddingX: 4 }}>
        {children}
      </DialogContent>

      {dialogActions ?? (
        <DialogActions>
          <Button onClick={() => { }}>NA</Button>
          <Button onClick={() => { }}>NA</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default InfoModal;