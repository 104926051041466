export const data = [
  {
    "id": 4167063,
    "offer_id": 265853,
    "loan_term": 6,
    "num_payments": 13,
    "gfee_rate": 8/100,
    "uw_amount_requested": 850000,
    "origination_fee_rate": 8/100,
    "max_commission_rate": 6/100,
    "comm": 513000,
    "net_amount": 850000,
    "frequency": "bi-weekly",
    "position_num": 1,
    "is_visible": true,
    "uw_total": 939315.27
  },
  {
    "id": 4167063,
    "offer_id": 265853,
    "loan_term": 6,
    "num_payments": 13,
    "gfee_rate": 8/100,
    "uw_amount_requested": 850000,
    "origination_fee_rate": 8/100,
    "max_commission_rate": 6/100,
    "comm": 51000,
    "net_amount": 850000,
    "frequency": "bi-weekly",
    "position_num": 1,
    "is_visible": true,
    "uw_total": 939315.27
  },
  {
    "id": 4167066,
    "offer_id": 265853,
    "loan_term": 9,
    "num_payments": 20,
    "gfee_rate": 13/100,
    "uw_amount_requested": 850000,
    "origination_fee_rate": 8/100,
    "max_commission_rate": 6/100,
    "comm": 51000,
    "net_amount": 850000,
    "position_num": 1,
    "is_visible": true,
    "frequency": "bi-weekly",
    "uw_total": 949268.85
  },
  {
    "id": 4161411,
    "offer_id": 265853,
    "loan_term": 12,
    "num_payments": 26,
    "gfee_rate": 16/100,
    "uw_amount_requested": 850000,
    "origination_fee_rate": 12/100,
    "max_commission_rate": 10/100,
    "comm": 85000,
    "position_num": 1,
    "is_visible": true,
    "net_amount": 850000,
    "frequency": "bi-weekly",
    "uw_total": 997763.22
  },
  {
    "id": 4161762,
    "offer_id": 265853,
    "loan_term": 15,
    "num_payments": 32,
    "gfee_rate": 28/100,
    "uw_amount_requested": 825000,
    "origination_fee_rate": 12/100,
    "max_commission_rate": 10/100,
    "comm": 82500,
    "position_num": 1,
    "is_visible": true,
    "net_amount": 825000,
    "frequency": "bi-weekly",
    "uw_total": 978334.77
  },
  {
    "id": 4161765,
    "offer_id": 265853,
    "loan_term": 18,
    "num_payments": 39,
    "gfee_rate": 34/100,
    "uw_amount_requested": 800000,
    "origination_fee_rate": 12/100,
    "max_commission_rate": 10/100,
    "comm": 80000,
    "position_num": 1,
    "is_visible": true,
    "net_amount": 800000,
    "frequency": "bi-weekly",
    "uw_total": 960251.73
  },
  {
    "id": 4167058,
    "offer_id": 265853,
    "loan_term": 6,
    "num_payments": 124,
    "gfee_rate": 6/100,
    "uw_amount_requested": 850000,
    "origination_fee_rate": 8/100,
    "max_commission_rate": 6/100,
    "comm": 51000,
    "position_num": 1,
    "is_visible": true,
    "net_amount": 850000,
    "frequency": "daily",
    "uw_total": 939311.15
  },
  {
    "id": 4167064,
    "offer_id": 265853,
    "loan_term": 9,
    "num_payments": 187,
    "gfee_rate": 11/100,
    "uw_amount_requested": 850000,
    "origination_fee_rate": 8/100,
    "max_commission_rate": 6/100,
    "comm": 51000,
    "position_num": 1,
    "is_visible": true,
    "net_amount": 850000,
    "frequency": "daily",
    "uw_total": 949952.57
  },
  {
    "id": 4161409,
    "offer_id": 265853,
    "loan_term": 12,
    "num_payments": 248,
    "gfee_rate": 16/100,
    "uw_amount_requested": 850000,
    "origination_fee_rate": 12/100,
    "max_commission_rate": 10/100,
    "comm": 85000,
    "position_num": 1,
    "is_visible": true,
    "net_amount": 850000,
    "frequency": "daily",
    "uw_total": 996702.93
  },
  {
    "id": 4161759,
    "offer_id": 265853,
    "loan_term": 15,
    "num_payments": 311,
    "gfee_rate": 26/100,
    "uw_amount_requested": 825000,
    "origination_fee_rate": 12/100,
    "max_commission_rate": 10/100,
    "comm": 82500,
    "position_num": 1,
    "is_visible": true,
    "net_amount": 825000,
    "frequency": "daily",
    "uw_total": 978629.61
  },
  {
    "id": 4161763,
    "offer_id": 265853,
    "loan_term": 18,
    "num_payments": 375,
    "gfee_rate": 32/100,
    "uw_amount_requested": 800000,
    "origination_fee_rate": 12/100,
    "max_commission_rate": 10/100,
    "comm": 80000,
    "position_num": 1,
    "is_visible": true,
    "net_amount": 800000,
    "frequency": "daily",
    "uw_total": 960044.12
  },
  {
    "id": 4167060,
    "offer_id": 265853,
    "loan_term": 6,
    "num_payments": 26,
    "gfee_rate": 7/100,
    "uw_amount_requested": 850000,
    "origination_fee_rate": 8/100,
    "max_commission_rate": 6/100,
    "comm": 51000,
    "position_num": 2,
    "is_visible": true,
    "net_amount": 850000,
    "frequency": "weekly",
    "uw_total": 939558.48
  },
  {
    "id": 4167065,
    "offer_id": 265853,
    "loan_term": 9,
    "num_payments": 39,
    "gfee_rate": 12/100,
    "uw_amount_requested": 850000,
    "origination_fee_rate": 8/100,
    "max_commission_rate": 6/100,
    "comm": 51000,
    "position_num": 1,
    "is_visible": true,
    "net_amount": 850000,
    "frequency": "weekly",
    "uw_total": 950343.36
  },
  {
    "id": 4161410,
    "offer_id": 265853,
    "loan_term": 12,
    "num_payments": 52,
    "gfee_rate": 16/100,
    "uw_amount_requested": 850000,
    "origination_fee_rate": 12/100,
    "max_commission_rate": 10/100,
    "comm": 85000,
    "position_num": 2,
    "is_visible": true,
    "net_amount": 850000,
    "frequency": "weekly",
    "uw_total": 997170.96
  },
  {
    "id": 4161761,
    "offer_id": 265853,
    "loan_term": 15,
    "num_payments": 65,
    "gfee_rate": 27/100,
    "uw_amount_requested": 825000,
    "origination_fee_rate": 12/100,
    "max_commission_rate": 10/100,
    "comm": 82500,
    "position_num": 1,
    "is_visible": true,
    "net_amount": 825000,
    "frequency": "weekly",
    "uw_total": 978600.75
  },
  {
    "id": 4161764,
    "offer_id": 265853,
    "loan_term": 18,
    "num_payments": 78,
    "gfee_rate": 33/100,
    "uw_amount_requested": 800000,
    "origination_fee_rate": 12/100,
    "max_commission_rate": 10/100,
    "comm": 80000,
    "position_num": 1,
    "is_visible": true,
    "net_amount": 800000,
    "frequency": "weekly",
    "uw_total": 959695.78
  }
 ]