import React, { useEffect, useState } from "react";
import { InputLabel, Grid, Select, TextField, MenuItem } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { DateTime } from "luxon";

const STATUS_OPTIONS = [
  ["All", "submitted"],
  ["Express", "express"],
  ["Submitted In Review", "pending"],
  ["Approved", "approved"],
  ["Closed", "closed"],
  ["Eligible for Renewal", "renewal"],
  ["Declined", "declined"]
];

const useStyles = makeStyles((theme) => ({
  statusFilter: {
    paddingRight: 10,
    marginRight: 20,
  },
  dateField: {
    minWidth: "auto",
    width: "auto",
    marginLeft: 10,
  }
}));

export default function ({ dateRange, onChange, metric, onMetricSelected }) {
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (dateRange) {
      setStartDate(dateRange[0]);
      setEndDate(dateRange[1]);
    }
  }, [dateRange]);

  useEffect(() => {
    if (startDate?.match(/^\d{4}-\d?\d-\d?\d$/) && endDate?.match(/^\d{4}-\d?\d-\d?\d$/)) {
      onChange([startDate, endDate])
    }
  }, [startDate, endDate])

  return (
    <Grid container justifyContent="flex-start" alignItems="center">
      <Select
        className={classes.statusFilter}
        value={metric}
        label="Metric"
        onChange={({ target: { value } }) => onMetricSelected(value)}
        variant="standard"
        renderValue={(val) => {
          const label = STATUS_OPTIONS.find((v) => v[1] === val)[0];
          return (<>Filter by Status: {label}</>);
        }}
      >
        {STATUS_OPTIONS && STATUS_OPTIONS.map((opt) => (
          <MenuItem key={opt[1]} value={opt[1]}>{opt[0]}</MenuItem>
        ))}
      </Select>

      <InputLabel htmlFor="start-date">Application Date Range:</InputLabel>

      <TextField
        id="start-date"
        className={classes.dateField}
        label="Start Date"
        onChange={({ target: { value } }) => setStartDate(value)}
        value={startDate  || DateTime.now().startOf('month').toISODate()}
        placeholder="Start Date"
        variant="outlined"
        type="date"
        required
      />

      <TextField
        className={classes.dateField}
        label="End Date"
        onChange={({ target: { value } }) => setEndDate(value)}
        value={endDate || DateTime.now().toISODate()}
        placeholder="End Date"
        variant="outlined"
        type="date"
        required
      />
    </Grid>
  );
}