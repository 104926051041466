import React, { useContext, useState, useEffect, createContext } from "react";
import { AuthStateContext } from "./context";
export const RootStoreContext = createContext(undefined);

export const RootStoreProvider = ({ environment, children }) => {
  const { loggedIn } = useContext(AuthStateContext);
  const [userSettings, setUserSettings] = useState(null);
  const [accountExecutive, setAccountExecutive] = useState(null);
  const [messages, setMessages] = useState([]);
  const messageCounterRef = React.useRef(1);

  useEffect(() => {
    if (!loggedIn) {
      setUserSettings(null);
      setAccountExecutive(null);
    }
  }, [loggedIn]);

  const addMessage = (message) => {
    if (!message.id) {
      message.id = messageCounterRef.current;
      messageCounterRef.current += 1;
    }
    setMessages((messages) => [...messages, message])
  }

  return (
    <RootStoreContext.Provider value={{
      userSettings, setUserSettings,
      accountExecutive, setAccountExecutive,
      isDev: environment.ENV === 'development',
      messages, setMessages, addMessage,
    }}>
      {children}
    </RootStoreContext.Provider>
  );
};
