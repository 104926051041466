import axios from "axios";
import { fetchEnv } from "../env";

const instance = axios.create({
  baseURL: fetchEnv("FIREBALL_SERVER_HOST"),
  headers: {
    "Cache-Control": "no-cache",
    "Content-type": "application/json",
    "Accept": "*/*",
    "X-Requested-With": "XMLHttpRequest",
  },
});

export default instance;