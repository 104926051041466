import React from 'react';
import { useGetAPI } from '../../../../shared/useAPI';
import RemoteAutocomplete from '../../../../shared/RemoteAutoComplete';
import { compact } from 'lodash';

function transformBroker(broker) {
  const { dba_name, source_code, id } = broker
  const meta = compact(
    [dba_name, source_code, id].
      map((str) => str && String(str).trim()?.length ? String(str).trim() : null)
  ).join(' - ')

  return {
    ...broker,
    title: `${broker.name} (${meta})`,
  }
}

export function BrokersAutocomplete({ onChange, disabled }) {
  const { callAPI } = useGetAPI('/api/internal/v1/staff/brokers');
  return (
    <RemoteAutocomplete
      label="Select Broker"
      placeholder="Select Broker"
      onChange={onChange}
      disabled={disabled}
      optionLabelName="title"
      helperText="Search by name, DBA name, source code or reseller ID"
      performSearch={async (query) => {
        const response = await callAPI({ params: { query } });
        return response.data.map(transformBroker);
      }}
    />
  );
}