import { useCallback } from "react";
import { useGetAPI } from "../useAPI";

export function useSubmitRenewal() {
  const { callAPI } = useGetAPI();

  const fetchRenewalValues = useCallback(async (offerId) => {
    const {
      data: {
        offer: {
          merchant,
          additional_details,
          requested_loan_amount,
          reason,
          payment_frequency,
          term,
          guarantors,
        }
      }
    } = await callAPI({ url: `/api/internal/v1/offers/${offerId}` });

    const startDate = new Date(merchant.start_date);
    const startMonth = startDate.getMonth() + 1;
    const startYear = startDate.getFullYear();
    const startDateStr = startMonth < 10 ? `0${startMonth}/${startYear}` : `${startMonth}/${startYear}`;

    return {
      company: {
        first_name: merchant.legal_name,
        last_name: merchant.dba_name,
        cpr: merchant.ein,
        email: merchant.email,
        phone_work: merchant.phone_number,
        url: merchant.website,
        type_inc: merchant.company_type,
        business_start_month: String(startMonth),
        business_start_year: String(startYear),
        business_start_date: startDateStr,
        industry: merchant.industry,
        industry_category: merchant.industry_category,
        other_industry: merchant.other_industry,
      },
      company_address: {
        civic: merchant.street_number,
        street: merchant.street_name,
        unit: merchant.street_unit,
        city: merchant.city,
        province: merchant.state_province,
        zip: merchant.zip_code,
      },
      offer: {
        description: additional_details,
        length_max: String(term),
        amount: requested_loan_amount,
        purpose: reason,
        pmt_frequency: payment_frequency,
      },
      user: {
        owners_attributes: guarantors.map((guarantor) => ({
          first_name: guarantor.first_name,
          last_name: guarantor.last_name,
          phone: guarantor.home_phone,
          phone_mobile: guarantor.cell_phone,
          email: guarantor.email,
          cpr: guarantor.ssn,
          birthdate: guarantor.birth_date,
          owner_percent: guarantor.percentage_ownership,
          primary_guarantor: guarantor.is_primary_guarantor ? "1" : "0",
          address_attributes: {
            civic: guarantor.street_number,
            street: guarantor.street_name,
            unit: guarantor.street_unit,
            city: guarantor.city,
            country_id: "1",
            province: guarantor.state_province,
            zip: guarantor.zip_code,
          },
        }))
      }
    }

  }, [callAPI])

  return { fetchRenewalValues };
}