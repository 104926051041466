import { TextField } from "@mui/material";
import { useField } from "formik";
import React, { memo, useEffect, useState } from "react";
import { usePropagateRef } from "./usePropagateRef";
import InputMask from "react-input-mask";

const PerformantMaskedTextField = memo((props) => {
  const [field, meta, helpers] = useField(props.name);
  const [fieldValue, setFieldValue] = useState(field.value);
  const [changed, setChanged] = useState(false);
  const { loading, onFocus, ...otherProps } = props;

  usePropagateRef({
    setFieldValue,
    name: props.name,
    value: field.value,
  });

  useEffect(() => {
    if (field.value !== fieldValue) {
      setFieldValue(field.value);
    }
  }, [field.value]);

  const onChange = (evt) => {
    setFieldValue(evt.target.value);
    setChanged(true);
  };

  const onBlur = (evt) => {
    requestAnimationFrame(() => {
      if (!meta.touched) helpers.setTouched(true);
      if (!changed) return;
      field.onChange({
        target: {
          name: props.name,
          value: evt.target.value || ""
        },
      });
      setChanged(false);
    });
  };
  const performanceProps = {
        ...field,
        value: loading ? "Loading..." : fieldValue,
        onChange,
        onBlur,
      };
  return (
    <>
        <InputMask
          mask={props.inputmask}
          value={fieldValue}
          onFocus={onFocus}
          onChange={onChange}
          onBlur={onBlur}
        >
          <TextField
            {...otherProps}
            error = {!!meta.error && meta.touched}
            helperText={meta.touched && meta.error}
            {...performanceProps}
          />
        </InputMask>
    </>
  );
});

export default PerformantMaskedTextField;
