import { Field } from "formik";
import { get } from "lodash";
import React from "react";
import PerformantTextField from "../../../../shared/PerformantTextField";
import PerformantMaskedTextField from "../../../../shared/PerformantMaskedTextField";

export const FieldWrapper = ({ formKey, ...props }) => (
  <Field name={formKey}>
    {() => <TextFieldWrapper formKey={formKey} {...props} />}
  </Field>
);

export const TextFieldWrapper = ({
  id,
  label,
  placeholder,
  changeHandler,
  blurHandler,
  focusHandler,
  required,
  formKey,
  values,
  autoComplete,
  inputProps,
  type,
  formKeyPrefix = "",
  multiline,
  minRows
}) => {
  const newFormKey = formKeyPrefix + formKey;
  return (
    <PerformantTextField
      id={id}
      className="formElement"
      label={label}
      placeholder={placeholder}
      type={type || "text"}
      onChange={changeHandler}
      onFocus={focusHandler}
      onBlur={blurHandler}
      value={get(values, newFormKey, "")}
      autoComplete={autoComplete}
      name={newFormKey}
      inputProps={inputProps}
      variant="outlined"
      required={required}
      multiline={multiline}
      InputLabelProps={{ shrink: true }}
      minRows={minRows}
    />
  );
};

export const FieldMaskWrapper = ({
  id,
  label,
  placeholder,
  changeHandler,
  blurHandler,
  focusHandler,
  required,
  formKey,
  values,
  inputMask,
  type,
  formKeyPrefix = "",
  InputProps,
}) => {
  const newFormKey = formKeyPrefix + formKey;
  return (
            <PerformantMaskedTextField
              inputmask={inputMask}
              values={values}
              onChange={changeHandler}
              onFocus={focusHandler}
              onBlur={blurHandler}
              required={required}
              id={id}
              name={newFormKey}
              placeholder={placeholder}
              className="formElement"
              label={label}
              type={type || "text"}
              fullWidth
              value={get(values, newFormKey, "")}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              InputProps={InputProps}
            />
  );
};
