import React from "react"
import { css, Grid, Typography } from "@mui/material"

function getCondensedStatus(status) {
  switch (status) {
    case 'DECLINED':
      return ['Decision', 'Declined', 'bg-danger']
    case 'NEEDS ATTENTION':
      return ['Decision', 'Needs Attention', 'bg-warning']
    case 'APPROVED':
      return ['Decision', 'Approved', 'bg-success']
    case 'CLOSED':
      return ['Funded', '','bg-attention', 'bg-success' ]
    case 'ELIGIBLE FOR RENEWAL':
      return ["Eligible for Renewal", '', 'bg-attention']
    case 'SUBMITTED IN REVIEW':
    default:
      return ["Submitted", 'In Review', 'bg-success']
  }
}

const StatusBox = ({ active, title, subtitle, color}) => {
  return (
    <Grid
      item
      className={`bg-primary ${active ? color : 'bg-disabled'}`}
      sx={css`
        align-self: stretch;
        min-width: 7em;
        width: 10em;
      `}
    >
      <Typography align="center" variant="h5" className="bold">{title}</Typography>
      <Typography align="center">{subtitle}</Typography>
    </Grid>
  )
}

const Separator = () => <Grid
  item
  sx={css`
    margin: 0 0.5em;
    min-width: 1em;
    border-bottom: 2px dashed #eee;
    width: 4em;
  `}
/>

function renderStatusBox(title, curTitle, subtitle, color) {
  const active = curTitle === title
  return <StatusBox title={title} active={active} subtitle={active ? subtitle : ''} color={color}  />
}

const StatusHeader = ({ offer: { status } }) => {
  const [curTitle, subtitle, color] = getCondensedStatus(status)

  return <Grid
    container
    wrap="nowrap"
    flexDirection="row"
    sx={css`
      align-items: center;
      justify-content: center;
      margin-top: 1em;
    `}
  >
    {renderStatusBox("Submitted", curTitle, subtitle, color)}
    <Separator />
    {renderStatusBox("Decision", curTitle, subtitle, color)}
    <Separator />
    {renderStatusBox("Funded", curTitle, subtitle, color)}
    <Separator />
    {renderStatusBox("Eligible for Renewal", curTitle, subtitle, color)}
  </Grid>
}

export default StatusHeader