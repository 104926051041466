import { Box } from "@mui/material"
import React from "react"
import { BorderedBox } from "../../../../styled"

export default ({ children }) =>
  <Box mb={3}>
    <BorderedBox>
      <Box sx={{ px: 7, pt: 2, pb: 2 }} my={4}>
        {children}
      </Box>
    </BorderedBox>
  </Box>