import { createTheme } from '@mui/material/styles';

const borderColor = '#939393';


// eslint-disable-next-line import/prefer-default-export
export const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#4094CA",
    },
    error: {
      main: "#B80E0E",
    },
  },

  components: {
    MuiAutocomplete: {
      styleOverrides: {
        popupIndicator: {
          marginRight: '6px',
        },
        input: {
          paddingRight: '60px !important',
        },
        inputRoot: {
          padding: '0 !important',
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          color: "#B80E0E",
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: borderColor,
          fontSize: '16px',
          padding: '0.5rem 1rem',
        },
        arrow: {
          '::before': {
            backgroundColor: borderColor,
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '&:before': {
            borderBottom: 'none !important',
          },
          '&:after': {
            borderBottom: 'none !important',
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          padding: '0 5px',
          color: '#000000',
          maxWidth: 'inherit !important',
        }
      }
    }
  }
});
