import React from "react";
import LoanAppAutoComplete from "./IouAutocomplete";
import { buildUsStateOptions } from "../../../shared/NewApplication/utils"
import { createFilterOptions } from '@mui/material/Autocomplete';

const optionValues = buildUsStateOptions();

const filterOptions = createFilterOptions({
  matchFrom: 'start',
});

export default function StateAutocomplete({ form, fieldName, value, required, handleFocus, handleBlur, setFieldValue }) {
  return (
    <LoanAppAutoComplete
      form={form}
      label="State"
      required={required}
      value={value}
      fieldName={fieldName}
      placeholder="Select State"
      handleFocus={handleFocus}
      handleBlur={handleBlur}
      setFieldValue={setFieldValue}
      optionValues={optionValues}
      filterOptions={filterOptions}
    />
  )
}