import React, { useState, useRef } from "react";
import { Button, Popover, Typography } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  disabledButton: {
    backgroundColor: theme.palette.primary || "red",
  },
  errorsPopover: {
    textAlign: 'center',
    maxWidth: '15em',
    padding: "20px",
  }
}));

export default ({ id, isValid, industryChecked, handleSubmit }) => {
  const classes = useStyles();
  const [showPopover, setShowPopover] = useState(false);
  const submitButtonRef = useRef();

  const buttonDisabled = !isValid || !industryChecked;
  const togglePopover = () => setShowPopover((val) => !val);

  return (
    <>
      <div style={{ position: 'relative' }}>
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: buttonDisabled ? 'block' : 'none',
        }}
          onClick={togglePopover}>
        </div>
        <Button
          ref={submitButtonRef}
          className={`button-primary ${classes.nextBtn}`}
          id={id}
          variant="contained"
          disabled={buttonDisabled}
          classes={{ disabled: classes.disabledButton }}
          onClick={buttonDisabled ? null : handleSubmit}
        >
          Submit
        </Button>
      </div>
      <Popover
        open={showPopover}
        onClose={() => setShowPopover(false)}
        anchorEl={submitButtonRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Typography className={classes.errorsPopover}>
          {!isValid &&
            <>
              Not all fields have been completed properly.
              Make sure all required fields are filled before submitting.
            </>
          }
          {isValid && !industryChecked &&
            <>
              Please certify the business at the top of the form
              before submitting your application.
            </>
          }
        </Typography>
      </Popover>
    </>
  )
}