import React from "react";
import "../App/styles/components/loan-calculator.css";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import { currency } from "../App/shared/format";
import secondOfferIcon from "../../../../../app/assets/images/second-offer-icon.png";
import DisclosureButton from "./DisclosureButton";
import { findStateMinAmount } from "../App/Partner/Applications/stateValidations/allStateValidations";


const LabelText = ({ label, value }) => {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="caption">{label}</Typography>

      <Typography variant="caption">{value}</Typography>
    </Grid>
  );
};

const TermCard = ({ usaState, title, term, isSelected, isDirty, open, openDisclosure, readOnly, disclosureEnabled=false, }) => {
  const enableSelect = (isSelected && isDirty) || !isSelected

  const buildAction = () => {
    if(!readOnly && enableSelect) {
      return(<CheckBoxOutlineBlankIcon onClick={()=>open(term)} fontSize="small" style={{ backgroundColor: "white"}} />)
    }
    return<>{isSelected && <CheckBoxIcon fontSize="small" color="success" />}</>
  }

  return (
    <Card className="loan-calculator-card">
     
      <CardHeader
        avatar={
          <>
          {term.positionNum === 2 && (
            <img src={secondOfferIcon} className="second-badge" />
          )}
          </>
        }
        action={ buildAction() }
        className="loan-calculator-loan-amount-header"
        title={`${term.termLength} Months`}
      />
      
      <CardContent>
        <Grid container justifyContent="center">
          <Grid item xs={12} >
            <Typography variant="h6" className="loan-calculator-loan-amount">
              Loan Amount
            </Typography>
            <Typography variant="body2" className="loan-calculator-loan-amount">
              {currency(term.sliderAmount)}
            </Typography>
          </Grid>
          <Grid container xs={12} justifyContent="center">
            <Grid item xs={12}>
              <Typography variant="h6" className="net-loan-calculator-loan-amount">
                Net Loan Amount
              </Typography>
            </Grid>
            <Typography variant="body2" className="net-loan-calculator-loan-amount">
              {currency(term.netLoanAmountSlider || 0)}
            </Typography>
          </Grid>
        </Grid>

        <LabelText label="Buy Rate:" value={term.buyRate.toFixed(3)} />
        {/* <LabelText label="NET Buy Rate:" value={term.netBuyRate.toFixed(3)} /> */}

        <LabelText label="Sell Rate:" value={term.sellRate.toFixed(3)} />
        {/* <LabelText label="NET Sell Rate:" value={term.netSellRate.toFixed(3)} /> */}

        <LabelText label="Commission" value={currency(term.brokerCommission)} />
        <LabelText
          label="Commission Rate:"
          value={parseFloat(term.sliderCommission?.toString() ?? 0).toFixed(2) + "%"}
        />
        <LabelText label="Payments" value={term.paymentCount} />
        <LabelText
          label="Total Payback"
          value={currency(term.totalPayback || 0)}
        />
        <LabelText
          label={`${title} Payment`}
          value={currency(term.totalDailyPayment || 0, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        />

        { term.netLoanAmount ?
        <LabelText
          label="Net Proceeds"
          value={currency(term.netLoanAmountTotal || 0, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
          /> : null  }
        { (term.renewalDiscount) ? 
        <LabelText
          label="Renewal Discount"
          value={term.renewalDiscount}
        /> : null
        }
        <Grid style={{ display:'flex', justifyContent:'center' }} >
        <DisclosureButton term={term}
                          show={disclosureEnabled && findStateMinAmount(usaState, term.sliderAmount)}
                          disabled={enableSelect}
                          openDisclosure={ openDisclosure }/>

        </Grid>
      </CardContent>
    </Card>
  );
};

export default TermCard;
