import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

export const DeleteConfirmationModal = ({ applicationName, open, onClose }) => {
  const classes = useStyles();
  return (
    <Dialog
      classes={classes}
      open={open}
      onClose={onClose}
      aria-describedby="delete-confirmation-modal"
    >
      <DialogContent>
        <DialogContentText id="delete-confirmation-modal">
          Are you sure you want to delete "{applicationName}"?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(true)}>Delete</Button>
        <Button onClick={() => onClose(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};