import { UsaStates } from "usa-states";
import { cloneDeep } from "lodash";
import { initialValuesEmpty } from "../../components/App/Partner/Applications/loanApplicationInitialValues";
 
export const buildOptionsFromArray = (list) => {
  const result = {};
  list.forEach((element) => {
    result[element] = {
      label: element,
      value: element,
    };
  });
  return result;
};

export const buildUsStateOptions = () => {
  const result = {};
  const usStates = new UsaStates();
  usStates.generateStates().forEach((state) => {
    result[state.abbreviation] = {
      label: state.name,
      value: state.abbreviation,
    };
  });
  return result;
};

export function optionsArray(options) {
  return Object.keys(options).map((val) => options[val]);
}

function ensureDefaults(values, defaults) {
  Object.keys(defaults).forEach((key) => {
    if (values[key] === undefined || values[key] === null) {
      values[key] = defaults[key]
    }
  })
}

/**
 * Ensure that all values have defaults set if they are not present 
 */
export function normalizeValues(values) {
  const empty = cloneDeep(initialValuesEmpty)
  ensureDefaults(values, empty)
  ensureDefaults(values.company, empty.company)
  values.user.owners_attributes.forEach((owner) => {
    ensureDefaults(owner, empty.user.owners_attributes[0])
    ensureDefaults(owner.address_attributes,
      empty.user.owners_attributes[0].address_attributes)
  })
  ensureDefaults(values.offer, empty.offer)
  ensureDefaults(values.company_address, empty.company_address)
  ensureDefaults(values.files, empty.files)
}
