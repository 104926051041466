import React from 'react';
import { Button } from '@mui/material';

const DisclosureButton = ({ term, show, disabled = true, openDisclosure }) => {
  return (show && <Button
    visibility={show ? 'visible' : 'hidden'}
    onClick={() => {
      openDisclosure(term);
    }}
    disabled={disabled}
    sx={(theme) => ({
      fontWeight: 'bold',
      marginTop: '1em',
      padding: '2px 10px',
      color: theme.palette.secondary.main,
      backgroundColor: 'rgba(102, 178, 255, 0.15)',
    })}
    >Generate Disclosure</Button>)
}

export default DisclosureButton;