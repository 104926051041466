import { Link, useNavigate } from "react-router-dom";
import { MenuItem, MenuList, Popper, Paper } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import makeStyles from '@mui/styles/makeStyles';
import { Menu } from "@mui/icons-material/";
import { observer } from "mobx-react";
import React, { useContext } from "react";
import { AuthStateContext } from "../../context/context";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    textDecoration: "none",
    color: "#fff",
  },
}));

const GlobalMenuButton = observer(() => {
  useStyles();
  const { signOut, user: { is_iou_employee }, isMasquerading, demasquerade } = useContext(AuthStateContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => setAnchorEl(null);

  const handleSignOut = signOut;

  const menuItems = [];
  if (is_iou_employee) {
    menuItems.push({ to: "/staff/masquerade", label: "Masquerade" });
  }
  if (isMasquerading) {
    menuItems.push({ onClick: demasquerade, label: "Unmask" })
  }
  if (!is_iou_employee) {
    menuItems.push({ to: "/dashboard", label: "Dashboard" });
    menuItems.push({ to: "/scorecard", label: "Scorecard" });
    menuItems.push({ to: "/newApplication/new" , label: "New Application", onClick: () => window.location.reload()});
  }

  return (
    <span>
      <IconButton
        aria-label="account of current user"
        data-cy="global-menu-button"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenuOpen}
        size="large">
        <Menu />
      </IconButton>
      <Popper open={open} anchorEl={anchorEl} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList>
                  {menuItems.map(({ label, to, onClick }) => (
                    <MenuItem key={label} onClick={onClick ? onClick : undefined}>
                      {to && (
                        <Link to={to} onClick={handleMenuClose}>
                          {label}
                        </Link>
                      )}
                      {!to && label}
                    </MenuItem>
                  ))}
                  <MenuItem data-cy="sign-out" onClick={handleSignOut}>
                    Sign Out
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </span>
  );
});

export default GlobalMenuButton;
