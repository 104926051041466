import { fetchEnv } from "../env";

const CHECK_INTERVAL = 5 * 60 * 1000; // 5 minutes

const BASE_PATH = fetchEnv('BASE_PATH');

function getScriptContentHash(doc) {
  const scriptEls = doc.getElementsByTagName("SCRIPT");
  const len = scriptEls.length
  for(let i=0; i < len; i++) {
    const m = scriptEls[i].src.match(/\/application-(.*?)\.js/);
    if (m) return m[1];
  }
}

async function checkForUpdate() {
  const response = await fetch(BASE_PATH);
  if (response.status == 200) {
    const htmlString = await response.text();
    const html = document.createElement('HTML');
    html.innerHTML = htmlString;
    const newContentHash = getScriptContentHash(html);

    if (newContentHash && newContentHash != originalContentHash) {
      console.warn("App update detect:", originalContentHash, '!=', newContentHash);
      document.location.reload();
      return;
    }
  }
  setTimeout(checkForUpdate, CHECK_INTERVAL);
}

const originalContentHash = getScriptContentHash(document);

export default function() {
  if (!originalContentHash) return;
  setTimeout(checkForUpdate, CHECK_INTERVAL);
  document.addEventListener('visibilitychange', () => {
    if (!document.hidden) checkForUpdate();
  });
}