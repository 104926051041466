import React, { useEffect, useState } from "react"
import { Alert } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import { useVerifyAuthenticatedURLAPI } from "../../../../shared/useVerifyAuthenticatedURLAPI";
import PaymentCalculator from "../../../PaymentCalculator"

export default function PaymentCalculatorPage() {
  let { offerId } = useParams()
  const [initialized, setInitialized] = useState(false)
  const [showInvalidLink, setShowInvalidLink] = useState(false)
  const [authToken, setAuthToken] = useState(false)
  const [merchantName, setMerchantName] = useState(null)
  const [searchParams, _setSearchParams] = useSearchParams()

  const sig = searchParams.get('sig')
  const exp = searchParams.get('exp')
  offerId ??= searchParams.get('offerId');

  const { verifyAuthenticatedURL } = useVerifyAuthenticatedURLAPI(offerId, sig, exp)

  useEffect(() => {
    if (!offerId || offerId === 'test') {
      setInitialized(true)
      setAuthToken('test')
      return
    }

    verifyAuthenticatedURL().
      then(({ data: { offer: { merchantName }}}) => {
        const params = new URLSearchParams({ sig, exp, value: offerId })
        setAuthToken(params.toString())
        setMerchantName(merchantName)
      }).
      catch(() => setShowInvalidLink(true)).
      finally(() => setInitialized(true))
  }, [])

  return (
    <>
      {!initialized && <div>Loading...</div>}
      {showInvalidLink && (
        <Alert severity="error">
          The link you are trying to access is invalid or has expired.
        </Alert>
      )}
      {!!authToken &&
        <PaymentCalculator
          readOnly={true}
          disclosureEnabled={false}
          title={merchantName}
          {...{ offerId, authToken }} />}
    </>
  )
}