import React from "react";
import FileDropzone from "../../../../shared/FileDropzone";

const accept = {
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc', '.docx'],
  'application/vnd.ms-excel': ['.xls', '.xlsx'],
  'application/vnd.ms-powerpoint': ['.ppt', '.pptx'],
  'application/vnd.oasis.opendocument.text': ['.odt'],
  'application/rtf': ['.rtf'],
  'application/x-iwork-pages-sffpages': ['.pages'],
  'text/csv': ['.csv', '.tsv'],
  'text/plain': ['.txt'],
  'image/*': [],
  'video/*': [],
  'audio/*': [],
}

const AttachmentDropzone = ({ files, setFiles, setFieldValue, buttonText, attachmentType }) => {
  return (
    <FileDropzone
      dragActiveMessage="Drop the files here ..."
      message="Drop business documents here, or click to select files."
      buttonProps={{
        className: 'button-outline'
      }}
      {...{
        attachmentType,
        files,
        setFiles,
        setFieldValue,
        buttonText,
        accept,
      }}
    />
  )
}

export default AttachmentDropzone;
