import { useEffect, useState } from "react";
import { useGetAPI } from "../useAPI";

function buildOptionsFromArray(arr) {
  const res = {}
  arr.forEach((el) => res[el.value] = el)
  return res
}

function defaultOption(arr) {
  return arr.find((v) => v.default)
}

export function useApplicationLookups() {
  const [companyTypes, setCompanyTypes] = useState()
  const [loanReasons, setLoanReasons] = useState()
  const [loanTerms, setLoanTerms] = useState()
  const [paymentFrequencies, setPaymentFrequencies] = useState()
  const [loaded, setLoaded] = useState()

  const { callAPI: companyTypesAPI } = useGetAPI("/api/internal/v1/company_types")
  const { callAPI: loanReasonsAPI } = useGetAPI("/api/internal/v1/loan_reasons")
  const { callAPI: loanTermsAPI } = useGetAPI("/api/internal/v1/loan_terms")
  const { callAPI: pmtFrequencyAPI } = useGetAPI("/api/internal/v1/payment_frequencies")

  useEffect(() => {
    Promise.all([
      companyTypesAPI().
        then(({ data }) => setCompanyTypes(buildOptionsFromArray(data))),

      loanReasonsAPI().
        then(({ data }) => setLoanReasons(buildOptionsFromArray(data))),

      loanTermsAPI().
        then(({ data }) => setLoanTerms(buildOptionsFromArray(data))),

      pmtFrequencyAPI().
        then(({ data }) => setPaymentFrequencies(buildOptionsFromArray(data))),

    ]).finally(() => setLoaded(true))
  }, [])

  return {
    companyTypes,
    loanReasons,
    loanTerms,
    paymentFrequencies,
    loaded,
  }
}