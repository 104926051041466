import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from "react";
import { useAccountExecutive } from "../../../../shared/useAccountExecutive";
import { AuthStateContext } from "../../../../context/context";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexGrow: 1,
    marginTop: 80,
    margin: 20,
  },
  heading: {
    textAlign: "left",
  },
  avatar: {
    backgroundColor: "#1F2C4C",
    height: 45,
    width: 45,
    marginBottom: 10,
    marginRight: 10,
  },
  contentContainer: {
    border: "1px solid red",
    backgroundColor: "orange",
  },
  content: {
    padding: "0px 10px 0px 10px",
  },
}));

const AccountExecutiveInfo = () => {
  const classes = useStyles();
  const { user } = useContext(AuthStateContext);
  const { loading, accountExecutive } = useAccountExecutive();

  if (user?.is_iou_employee)
    return (<></>);

  if (loading || !accountExecutive)
    return (
      <Card variant="outlined">
        <CardContent className={classes.content}>
          <Grid container direction="row" alignItems="center" justifyContent="center">
            <CircularProgress color="inherit" />
          </Grid>
        </CardContent>
      </Card>
    );

  const {
    photo_url,
    first_name,
    last_name,
    email,
    phone_number,
    title,
  } = accountExecutive;

  const initials = [first_name, last_name].
    filter((v) => v?.length).
    map((v) => v[0].
    toUpperCase())
    .join('')

  return (
    <Card className="border-custom">
      <CardHeader
        className={classes.heading}
        title={title}
        titleTypographyProps={{ variant: "h6" }}
      />
      <CardContent className={classes.content}>
        <Grid container alignItems="center">
          <Grid item>
            <Avatar aria-label="avatar" className={classes.avatar} src={photo_url}>
              {initials}
            </Avatar>
          </Grid>

          <Grid item>
            <Typography color="textSecondary" gutterBottom>
              {first_name} {last_name}
            </Typography>

            <Grid item>
          <Grid item>
            <Typography className={classes.pos} color="textSecondary">
              {phone_number}
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body2" component="p">
              {email}
            </Typography>
           </Grid>
          </Grid>
          </Grid>

        

        </Grid>
      </CardContent>
      <CardActions />
    </Card>
  );
}

export default AccountExecutiveInfo;
