import AuthedDrawer from "./Auth";
import UnauthedDrawer from "./Unauth";

const AppBarDrawer = {
  Auth: AuthedDrawer,
  NoAuth: UnauthedDrawer,
}

export const Auth = AuthedDrawer;
export const NoAuth = UnauthedDrawer;

export default AppBarDrawer;