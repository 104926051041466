import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useAccountExecutive } from "../../../../shared/useAccountExecutive";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const ConflictNoticeModal = ({ open, onClose, conflict: { error, saved } }) => {
  const classes = useStyles();
  const { accountExecutive } = useAccountExecutive();

  const savedMessage = saved  ? 'Your application has not been submitted, but has been saved.'
                              : 'Your application has not been submitted.';
 
  return (
    <Dialog
      classes={classes}
      open={open}
      onClose={onClose}
      aria-describedby="conflict-notice-modal"
    >
      <DialogContent>
        <DialogContentText id="conflict-notice-modal">
          {error}<br /><br />
          {savedMessage}<br /><br />
          {accountExecutive && (({ first_name, last_name, email }) => (
            <span>Please contact your Account Executive, {first_name} {last_name} at <a href={`mailto:${email}`}>{email}</a> for more information.</span>
          ))(accountExecutive)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConflictNoticeModal;
