import React from "react";
import InfoModalLink from "./InfoModaLink";

const LoanInformationLinkModal = () =>
  <InfoModalLink title="Loan Information">
    <p>The loan amount can be any value between 10,000 and 500000 U.S. dollars. Be sure to write a specific description of the purpose for this loan.</p>
    <p>If the merchant has other loans, include those details in this section.</p>
  </InfoModalLink>

export default LoanInformationLinkModal;
