import { useCallback } from "react";
import { usePostAPI } from "../useAPI";

export function useCheckSolePropAPI() {
  const { callAPI } = usePostAPI('/api/internal/v1/application_validations/sole_prop_check');
  const checkSoleProp = useCallback(async (state, typeInc, amount) => {
    const response = await callAPI({ state: state, typeInc: typeInc, amount: amount });
    return response.data;
  }, [callAPI]);

  return {
    checkSoleProp
  };
}